import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const MessageTextSquareIcon = forwardRef(
    ({ title, titleId, borderRadius, ...props }: IconProps, ref?: LegacyRef<SVGSVGElement>) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width={props.width}
            height={props.height}
            role="img"
            aria-hidden="true"
            className={styles.icon({
                ...props,
                borderRadius,
            })}
            fill="none"
            ref={ref}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M5.173 1.333h5.655c.536 0 .98 0 1.34.03.375.03.72.096 1.043.261.502.256.91.664 1.165 1.165.165.324.23.668.261 1.043.03.36.03.804.03 1.34v3.656c0 .536 0 .98-.03 1.34-.03.375-.096.72-.26 1.043-.257.501-.665.91-1.166 1.165-.324.165-.668.23-1.043.261-.36.03-.804.03-1.34.03H9.123c-.443 0-.572.003-.69.027a1.3 1.3 0 0 0-.338.119c-.107.054-.21.132-.555.409l-1.609 1.287a5 5 0 0 1-.357.27c-.105.07-.308.194-.573.194a1 1 0 0 1-.782-.376 1.04 1.04 0 0 1-.207-.569A5 5 0 0 1 4 13.581v-.92a3 3 0 0 1-.69-.085 2.67 2.67 0 0 1-1.886-1.886c-.091-.34-.09-.735-.09-1.265V5.172c0-.536 0-.98.029-1.34.03-.375.096-.72.261-1.043.256-.501.664-.91 1.165-1.165.324-.165.668-.23 1.043-.261.361-.03.804-.03 1.34-.03ZM4 5.667C4 5.298 4.298 5 4.667 5H8a.667.667 0 0 1 0 1.333H4.667A.667.667 0 0 1 4 5.667m.667 1.666a.667.667 0 0 0 0 1.334H10a.667.667 0 1 0 0-1.334z"
                clipRule="evenodd"
            />
        </svg>
    ),
);
MessageTextSquareIcon.displayName = 'MessageTextSquareIcon';
export default MessageTextSquareIcon;
