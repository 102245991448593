import { defineEndpoints } from '@blockworks/platform/api';
import { GetAssetsResponse } from '@blockworks/platform/api/research/assets';

type GetAssetsWithGovernanceResponse = GetAssetsResponse['data'];

interface GetEthGasResponse {
    updated: string;
    lastBlock: string;
    safe: string;
    proposed: string;
    fast: string;
    suggested: string;
    gasUsedRatio?: string[] | null;
}

interface PostCoverageRequestResponse {
    message: string;
    success: boolean;
}

const INTERNAL_ASSETS_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        ASSETS_WITH_GOVERNANCE: template('/api/assets-with-governance'),
        ETH_GAS: template('/api/ethereum/gas'),
    },
    POST: {
        FAVORITE: template(`/api/assets/{slug}/favorite`),
        REQUEST_COVERAGE: template('/api/assets/{slug}/request-coverage'),
    },
}));

export { INTERNAL_ASSETS_ENDPOINTS };
export type { GetAssetsWithGovernanceResponse, GetEthGasResponse, PostCoverageRequestResponse };
