import { createApi } from '@blockworks/platform/api';

import {
    GetAssetsWithGovernanceResponse,
    GetEthGasResponse,
    INTERNAL_ASSETS_ENDPOINTS,
    PostCoverageRequestResponse,
} from './internal-assets.model';

const internalAssetsApi = createApi(build => ({
    get: {
        assetsWithGovernance: build<GetAssetsWithGovernanceResponse, void>({
            cachePrefix: 'assets-with-governance',
            baseUrl: 'same-origin',
            query: () => ({
                uri: INTERNAL_ASSETS_ENDPOINTS.GET.ASSETS_WITH_GOVERNANCE(),
            }),
        }),
        ethGas: build<GetEthGasResponse, void>({
            cachePrefix: 'ethereum-gas',
            baseUrl: 'same-origin',
            query: () => ({
                uri: INTERNAL_ASSETS_ENDPOINTS.GET.ETH_GAS(),
            }),
        }),
    },
    post: {
        /** @todo add response type */
        favorite: build<any, { code: string }>({
            cachePrefix: 'update-favorite',
            baseUrl: 'same-origin',
            query: ({ code }) => {
                return {
                    uri: INTERNAL_ASSETS_ENDPOINTS.POST.FAVORITE({ slug: code.toLowerCase() }),
                    options: {
                        method: build.Method.Post,
                    },
                };
            },
        }),
        requestCoverage: build<PostCoverageRequestResponse, { code: string; message: string }>({
            cachePrefix: 'request-coverage',
            baseUrl: 'same-origin',
            query: ({ code, message }) => {
                return {
                    uri: INTERNAL_ASSETS_ENDPOINTS.POST.REQUEST_COVERAGE({
                        slug: code.toLowerCase(),
                    }),
                    options: {
                        method: build.Method.Post,
                        body: { message },
                    },
                };
            },
        }),
    },
}));

export { internalAssetsApi };
