import { forwardRef } from 'react';

import { mapClassNamesToSlots } from '../../style-system';
import { TVComponentWithElementProps } from '../../style-system/models';
import { IconComponent } from '../icon';
import { LinkExternalIcon } from '../icon/icons';

import { LinkBase } from './link.base';
import { linkStyles } from './link.styles';

type LinkProps = TVComponentWithElementProps<'a', typeof linkStyles> & {
    /**
     * Show an inline external link icon
     * @default false
     **/
    leadingIcon?: IconComponent;
    trailingIcon?: IconComponent;
    showExternalIcon?: boolean;
} & { children?: React.ReactNode };

export interface LinkComponent extends React.ForwardRefExoticComponent<LinkProps> {
    Base: typeof LinkBase;
}

const getTrailingIcon = (showExternalIcon: boolean, trailingIcon?: IconComponent) => {
    if (trailingIcon) return trailingIcon;
    if (showExternalIcon) return LinkExternalIcon;
    return;
};

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
    (
        {
            children,
            variant = 'default',
            intent = 'neutral',
            size = 'xs',
            classNames,
            showExternalIcon = false,
            leadingIcon: LeadingIcon,
            trailingIcon,
            display,
            ...rest
        },
        ref,
    ) => {
        const { base, icon } = mapClassNamesToSlots(linkStyles, {
            variant,
            size,
            display,
            intent,
            ...rest,
            classNames,
        });
        const ExternalOrTrailingIcon = getTrailingIcon(showExternalIcon, trailingIcon);

        return (
            <LinkBase {...rest} className={base} ref={ref}>
                {LeadingIcon ? <LeadingIcon className={icon} /> : null}
                {children}
                {ExternalOrTrailingIcon ? <ExternalOrTrailingIcon className={icon} /> : null}
            </LinkBase>
        );
    },
) as LinkComponent;

Link.displayName = 'Link';
Link.Base = LinkBase;

export type { LinkProps };
export { Link };
