import { PropsWithChildren } from 'react';
import Link from 'next/link';

import { Bit } from '@blockworks/ui/bit';

import { TVStyleProps } from '../../style-system';
import { safeAttr } from '../../utils';
import { Logo } from '../logo';

import { brandStyles, contentStyles, layoutStyles, sidebarStyles, topbarStyles } from './styles';

type LayoutProps = PropsWithChildren<TVStyleProps<typeof layoutStyles>>;

const Layout = ({ children, ...rest }: LayoutProps) => {
    return <div className={layoutStyles(rest)}>{children}</div>;
};

type TopbarProps = PropsWithChildren<TVStyleProps<typeof topbarStyles>>;

const Topbar = ({ children, className }: TopbarProps) => {
    return <div className={topbarStyles({ className })}>{children}</div>;
};

type BrandProps = {
    logo?: React.ReactNode;
    text?: React.ReactNode;
    showOnMobile?: boolean;
    padded?: boolean;
} & TVStyleProps<typeof brandStyles>;

const Brand = ({ logo, showOnMobile, padded, text, className }: BrandProps) => {
    return (
        <Bit.div
            className={brandStyles({ padded, className })}
            {...safeAttr(showOnMobile, 'show-on-mobile', { fallbackValue: 'hide-on-mobile' })}
        >
            <Link href="/" className="flex gap-3 items-center">
                {logo ?? <Logo />}
                {text}
            </Link>
        </Bit.div>
    );
};

type ContentProps = Omit<PropsWithChildren<TVStyleProps<typeof contentStyles>>, 'withSidebar'>;

const Content = ({ children, className }: ContentProps) => {
    return <div className={contentStyles({ withSidebar: true, className })}>{children}</div>;
};

const ContentWithoutSidebar = ({ children, className }: ContentProps) => {
    return <div className={contentStyles({ withSidebar: false, className })}>{children}</div>;
};

type SidebarProps = PropsWithChildren<TVStyleProps<typeof sidebarStyles>>;

const Sidebar = ({ children, className }: SidebarProps) => {
    return <div className={sidebarStyles({ className })}>{children}</div>;
};

Layout.Topbar = Topbar;
Layout.Sidebar = Sidebar;
Layout.Content = Content;
Layout.ContentWithoutSidebar = ContentWithoutSidebar;
Layout.Brand = Brand;

export type { BrandProps, ContentProps, LayoutProps, SidebarProps, TopbarProps };
export { Layout };
