import { forwardRef } from 'react';
import Link, { LinkProps as NextLinkProps } from 'next/link';

import { filterDOMProps, safelySpreadDOMProps } from '../../style-system/utils';

const isExternalLink = (props: any): props is AnchorElProps => {
    return typeof props.href === 'string' && !props.href?.startsWith('/') && !props.href?.startsWith('#');
};

type AnchorElProps = React.ComponentPropsWithoutRef<'a'>;
type InternalLinkProps = Omit<AnchorElProps, keyof NextLinkProps> & NextLinkProps;

type LinkBaseProps = AnchorElProps | InternalLinkProps;

const safelySpreadNextLinkProps = (props: InternalLinkProps) =>
    filterDOMProps(props, {
        omitUndefinedValues: true,
        include: new Set([
            'href',
            'onClick',
            'onMouseEnter',
            'onTouchStart',
            'target',
            'as',
            'replace',
            'scroll',
            'shallow',
            'passHref',
            'prefetch',
            'locale',
            'legacyBehavior',
        ]),
    });

/**
 * A low-level component that determines if a link is external or not,
 * and renders an `anchor` element if it is or `NextLink` if not
 **/
const LinkBase = forwardRef<HTMLAnchorElement, LinkBaseProps>((props, ref) => {
    /** Next Link requires `href`, so let's render an anchor as a fallback */
    const safelyRenderAnchor = !props.href;

    if (isExternalLink(props) || safelyRenderAnchor) {
        return (
            <a {...safelySpreadDOMProps(props)} rel="noreferrer" target="_blank" ref={ref}>
                {props.children}
            </a>
        );
    }

    const nextLinkProps = safelySpreadNextLinkProps(props).domProps;

    return (
        <Link
            {...nextLinkProps}
            href={nextLinkProps.href}
            prefetch={nextLinkProps?.prefetch || false}
            rel="noreferrer"
            ref={ref}
        >
            {props.children}
        </Link>
    );
});

LinkBase.displayName = 'LinkBase';

export type { AnchorElProps, InternalLinkProps, LinkBaseProps };
export { isExternalLink, LinkBase };
