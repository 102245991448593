import React, { useEffect, useState } from 'react';
import { Router } from 'next/router';

import { Drawer, FlexBox, IconButton, Logo } from '@blockworks/ui/components';
import { MenuIcon, XCloseIcon } from '@blockworks/ui/icon';

import Sidebar from '../sidebar/sidebar';

const handleSetOpen = (setOpen: React.Dispatch<React.SetStateAction<boolean>>, open: boolean) => () => setOpen(open);

const DrawerMenu = () => {
    const [open, setOpen] = useState(false);

    /** close drawer whenever the route path changes  */
    useEffect(() => {
        const closeMenu = handleSetOpen(setOpen, false);
        Router.events.on('routeChangeStart', closeMenu);

        return () => {
            Router.events.off('routeChangeStart', closeMenu);
        };
    }, [open]);

    return (
        <FlexBox display={{ initial: 'flex', md: 'hidden' }} w="max">
            <Drawer open={open}>
                <Drawer.Trigger>
                    <IconButton
                        variant="ghost"
                        aria-label="menu"
                        icon={MenuIcon}
                        onClick={handleSetOpen(setOpen, true)}
                    />
                </Drawer.Trigger>
                <Drawer.Content side="left" onInteractOutside={handleSetOpen(setOpen, false)}>
                    <FlexBox pos="relative" maxH="max" px={5} py={3} w="full">
                        <Logo />
                        <div className="absolute right-3 top-1">
                            <Drawer.Close>
                                <IconButton
                                    aria-label="close menu"
                                    variant="ghost"
                                    icon={XCloseIcon}
                                    onClick={handleSetOpen(setOpen, false)}
                                />
                            </Drawer.Close>
                        </div>
                    </FlexBox>
                    <Sidebar />
                </Drawer.Content>
            </Drawer>
        </FlexBox>
    );
};

export { DrawerMenu };
