/* eslint-disable @typescript-eslint/no-use-before-define */
import { ComponentProps, PropsWithChildren } from 'react';
import * as RadixToast from '@radix-ui/react-toast';

import { Bit } from '../../style-system/factory';
import { FlexBox } from '../flex-box';
import { IconComponent } from '../icon';
import { CheckIcon, ExclamationPointIcon, XCloseIcon } from '../icon/icons';
import { IconButton } from '../icon-button';
import { Text } from '../text';

import { toastIconStyles, toastStyles, toastViewportStyles } from './toast.styles';

enum ToastKind {
    Success,
    Danger,
    Warning,
    Info,
}

type ToastProps = ComponentProps<typeof RadixToast.Root> & {
    title?: string;
    description: string;
    kind: ToastKind;
    icon?: IconComponent;
    onClose: () => void;
};

const Toast = ({ title, description, kind = ToastKind.Info, onClose, ...rest }: ToastProps) => {
    return (
        <RadixToast.Root className={toastStyles()} {...rest}>
            <FlexBox alignItems="center" justifyContent="between" w="full" gap="md">
                <IconContent kind={kind} />
                <FlexBox alignItems="center" justifyContent="between" w="full" gap="sm">
                    <Bit.div flexGrow={1}>
                        {title && (
                            <RadixToast.Title asChild>
                                <Text size="xs" weight="semibold">
                                    {title}
                                </Text>
                            </RadixToast.Title>
                        )}
                        <Bit.div flexGrow={1}>
                            <RadixToast.Description asChild>
                                <Text color="muted" size="xs">
                                    {description}
                                </Text>
                            </RadixToast.Description>
                        </Bit.div>
                    </Bit.div>
                    <FlexBox flexShrink={0} w={40} justifyContent="end">
                        <RadixToast.Action asChild altText="Dismiss">
                            <IconButton icon={XCloseIcon} onClick={onClose} aria-label="Dismiss" />
                        </RadixToast.Action>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </RadixToast.Root>
    );
};

const ToastProvider = ({ children }: PropsWithChildren) => {
    return (
        <RadixToast.Provider swipeDirection="right" duration={12000}>
            {children}
            <RadixToast.Viewport className={toastViewportStyles()} />
        </RadixToast.Provider>
    );
};

const IconContent = ({ kind }: { kind: ToastKind }) => {
    switch (kind) {
        case ToastKind.Success:
            return (
                <div className={toastIconStyles({ color: 'success', rounded: true })}>
                    <CheckIcon size={1} color="success" />
                </div>
            );
        case ToastKind.Danger:
            return (
                <div
                    className={toastIconStyles({
                        color: 'danger',
                    })}
                >
                    <ExclamationPointIcon size={1} color="danger" />
                </div>
            );
        case ToastKind.Warning:
            return (
                <div className={toastIconStyles({ color: 'warning' })}>
                    <ExclamationPointIcon size={1} color="warning" />
                </div>
            );
        case ToastKind.Info:
            return (
                <div
                    className={toastIconStyles({
                        color: 'info',
                    })}
                >
                    <ExclamationPointIcon size={1} color="base" />
                </div>
            );
        default:
            return <></>;
    }
};

export type { ToastProps };
export { Toast, ToastKind, ToastProvider };
