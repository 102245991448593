import React from 'react';
import Script from 'next/script';

import { ILayoutProps } from '../interfaces/layout';

import { DefaultMeta } from './default-meta';

export const LayoutHead = ({ metaProps, additionalScripts }: Pick<ILayoutProps, 'metaProps' | 'additionalScripts'>) => {
    const fullMetaProps = {
        ...metaProps,
        title: metaProps.title ?? 'Blockworks Research',
        description: metaProps.description ?? 'Blockworks Research',
    };

    return (
        <>
            <DefaultMeta {...fullMetaProps} />
            {additionalScripts?.map(script => (
                <Script
                    key={script.src}
                    src={script.src}
                    strategy={script.strategy ?? 'afterInteractive'}
                    async={script.async}
                    onLoad={script.onLoad}
                />
            ))}
        </>
    );
};
