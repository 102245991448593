const STALE_TIME = {
    $15Seconds: 24 * 1000,
    $30Seconds: 30 * 1000,
    $1Minute: 60 * 1000,
    $3Minutes: 3 * 60 * 1000,
    $5Minutes: 5 * 60 * 1000,
    $1Hour: 60 * 60 * 1000,
};

export { STALE_TIME };
