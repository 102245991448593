import { createApi } from '@blockworks/platform/api';

import {
    INTERNAL_DASHBOARD_ENDPOINTS,
    RefreshQueriesRequest,
    RefreshQueriesResponse,
} from './internal-dashboards.model';

const internalDashboardsApi = createApi(build => ({
    get: {
        refreshDashboardQueries: build<RefreshQueriesResponse, RefreshQueriesRequest>({
            cachePrefix: 'refresh-dashboard-queries',
            baseUrl: 'same-origin',
            query: ({ studioDashboardId }) => ({
                uri: INTERNAL_DASHBOARD_ENDPOINTS.GET.REFRESH_QUERIES_ENDPOINT(),
                params: {
                    studioDashboardId,
                },
            }),
        }),
    },
}));

export { internalDashboardsApi };
