import * as React from 'react';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import { MetaTag, OpenGraphMedia } from 'next-seo/lib/types';

import ResearchConfig from '@/research-config';

export type IMetaProps = {
    title: string;
    description: string;
    canonical_url?: string;
    images?: ReadonlyArray<OpenGraphMedia>;
    ogContent?: string;
    authors?: string[];
    categories?: string[];
};

const DefaultMeta = (props: IMetaProps) => {
    const metaTags: MetaTag[] = [{ name: 'viewport', content: 'width=device-width,initial-scale=1' }];

    if (props.authors) {
        metaTags.push({ name: 'article:author', content: props.authors?.join(', ') });
    }
    if (props.categories) {
        metaTags.push({ name: 'article:section', content: props.categories?.join(', ') });
    }

    return (
        <>
            <Head>
                <meta charSet="UTF-8" key="charset" />
                <link rel="apple-touch-icon" href={`${ResearchConfig.SITE_URL}/apple-touch-icon.png`} key="apple" />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href={`${ResearchConfig.SITE_URL}/favicon-32x32.png`}
                    key="icon32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={`${ResearchConfig.SITE_URL}/favicon-16x16.png`}
                    key="icon16"
                />
                <link rel="icon" href={`${ResearchConfig.SITE_URL}/favicon.ico`} key="favicon" />
                <link rel="mask-icon" href={`${ResearchConfig.SITE_URL}/safari-pinned-tab.svg`} color="#fff" />
                <link rel="manifest" href={`${ResearchConfig.SITE_URL}/site.webmanifest`} />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="theme-color" content="#ffffff" />
                <title>{props.title}</title>
            </Head>
            <NextSeo
                title={props.title}
                defaultTitle={ResearchConfig.title}
                description={props.description}
                canonical={props.canonical_url}
                openGraph={{
                    title: props.title,
                    description: props.description,
                    url: props.canonical_url ?? ResearchConfig.SITE_URL,
                    locale: ResearchConfig.locale,
                    site_name: ResearchConfig.site_name,
                    ...(props.ogContent
                        ? { images: [{ url: props.ogContent }] }
                        : { images: props.images ?? [{ url: ResearchConfig.ogImageUrl }] }),
                }}
                additionalMetaTags={metaTags}
                {...ResearchConfig.nextSEO}
            />
        </>
    );
};

export { DefaultMeta };
