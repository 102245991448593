import { createApi } from '../../config/create-api';

import {
    ASSET_ENDPOINTS,
    AssetAddressesRequest,
    GET_ASSETS_DEFAULT_PAGE_LIMIT,
    GetAssetAddressesReponse,
    GetAssetLinksRequest,
    GetAssetLinksResponse,
    GetAssetRequest,
    GetAssetResponse,
    GetAssetSparklineResponse,
    GetAssetSparklinesResponse,
    GetAssetsRequest,
    GetAssetsResponse,
    GetFavoriteAssetsResponse,
    GetGlobalHistoryResponse,
    GetGlobalsHistoryRequest,
    GetSparklineRequest,
    GetSparklinesRequest,
} from './assets.model';

const assetsApi = createApi(build => ({
    get: {
        addresses: build<GetAssetAddressesReponse, AssetAddressesRequest>({
            cachePrefix: 'asset-addresses',
            query: ({ type, slug }) => {
                return {
                    uri: ASSET_ENDPOINTS.GET.ADDRESSES({
                        slug: slug.toLowerCase(),
                    }),
                    params: {
                        type,
                    },
                };
            },
        }),
        assets: build<GetAssetsResponse, GetAssetsRequest>({
            cachePrefix: 'assets',
            query: ({
                withSparklines,
                limit = GET_ASSETS_DEFAULT_PAGE_LIMIT,
                pageNum = 0,
                isSupported,
                favorites,
                ...rest
            }) => {
                return {
                    uri: ASSET_ENDPOINTS.GET.ASSETS(),
                    params: {
                        sparklines: withSparklines ? '1' : '0',
                        limit,
                        page: pageNum,
                        favoritesOnly: favorites ? '1' : '0',
                        supported: isSupported ? '1' : '0',
                        ...rest,
                    },
                };
            },
        }),
        asset: build<GetAssetResponse, GetAssetRequest>({
            cachePrefix: 'asset',
            query: ({ slug }) => ({
                uri: ASSET_ENDPOINTS.GET.ASSET({ slug }),
            }),
        }),
        favorites: build<GetFavoriteAssetsResponse, void>({
            cachePrefix: 'favorite-assets',
            query: () => ({
                uri: ASSET_ENDPOINTS.GET.FAVORITES(),
            }),
        }),
        links: build<GetAssetLinksResponse, GetAssetLinksRequest>({
            cachePrefix: 'asset-links',
            query: ({ slug }) => ({
                uri: ASSET_ENDPOINTS.GET.LINKS({ slug: slug.toLowerCase() }),
            }),
        }),
        sparkline: build<GetAssetSparklineResponse, GetSparklineRequest>({
            cachePrefix: 'asset-sparkline',
            query: ({ asset }) => {
                return {
                    uri: ASSET_ENDPOINTS.GET.SPARKLINE({ asset }),
                };
            },
        }),
        sparklines: build<GetAssetSparklinesResponse, GetSparklinesRequest>({
            cachePrefix: 'asset-sparklines',
            query: ({ assetCodes }) => {
                return {
                    uri: ASSET_ENDPOINTS.GET.SPARKLINES(),
                    params: {
                        assets: assetCodes,
                    },
                };
            },
        }),
        globals: build({
            cachePrefix: 'globals',
            query: () => {
                return {
                    uri: ASSET_ENDPOINTS.GET.GLOBALS(),
                };
            },
        }),
        /** Get global market history over a given set of days i.e. `durationInDays` */
        globalMarketHistory: build<GetGlobalHistoryResponse, GetGlobalsHistoryRequest>({
            cachePrefix: 'global-market-history',
            query: ({ durationInDays }) => {
                return {
                    uri: ASSET_ENDPOINTS.GET.GLOBALS_HISTORY({
                        durationInDays,
                    }),
                };
            },
        }),
    },
}));

export { assetsApi };
