import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const MenuIcon = forwardRef(({ title, titleId, borderRadius, ...props }: IconProps, ref?: LegacyRef<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={props.width}
        height={props.height}
        role="img"
        aria-hidden="true"
        className={styles.icon({
            ...props,
            borderRadius,
        })}
        fill="none"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 12h18M3 6h18M3 18h18"
        />
    </svg>
));
MenuIcon.displayName = 'MenuIcon';
export default MenuIcon;
