import { createApi } from '../../config/create-api';

import {
    AUTH_ENDPOINTS,
    GetSessionRequest,
    GetSessionResponse,
    PatchUpdatePasswordRequest,
    PostRequestNewPasswordRequest,
    PostSessionRequest,
    PostSigninRequest,
    PostSignupRequest,
    PostSignupResponse,
} from './auth.model';

const authApi = createApi(build => ({
    get: {
        session: build<GetSessionResponse, GetSessionRequest>({
            cachePrefix: 'auth-session',
            query: ({ sessionToken }) => {
                return {
                    uri: AUTH_ENDPOINTS.GET.SESSION(),
                    options: { sessionToken },
                };
            },
        }),
        logout: build({
            cachePrefix: 'auth-logout',
            query: () => ({
                uri: AUTH_ENDPOINTS.GET.LOGOUT(),
            }),
        }),
    },
    post: {
        login: build<any, PostSigninRequest>({
            cachePrefix: 'auth-login',
            query: ({ email, password }) => ({
                uri: AUTH_ENDPOINTS.POST.LOGIN(),
                options: {
                    method: build.Method.Post,
                    body: {
                        email,
                        password,
                    },
                },
            }),
        }),
        session: build<any, PostSessionRequest>({
            cachePrefix: 'auth-start-session',
            query: body => ({
                uri: AUTH_ENDPOINTS.POST.SESSION(),
                options: {
                    method: build.Method.Post,
                    body,
                },
            }),
        }),
        signup: build<PostSignupResponse, PostSignupRequest>({
            cachePrefix: 'auth-signup',
            query: ({ company, countryCode, email, firstname, lastname, password, phoneNumber }) => {
                const formData = new FormData();
                formData.append('email', email);
                formData.append('password', password);
                formData.append('firstname', firstname);
                formData.append('lastname', lastname);
                formData.append('company', company);
                formData.append('phoneNumber', `${countryCode}${phoneNumber}`);

                return {
                    uri: AUTH_ENDPOINTS.POST.SIGNUP(),
                    options: {
                        method: build.Method.Post,
                        body: formData,
                    },
                };
            },
        }),
        requestNewPassword: build<any, PostRequestNewPasswordRequest>({
            cachePrefix: 'request-new-password',
            query: ({ email }) => {
                return {
                    uri: AUTH_ENDPOINTS.POST.REQUEST_NEW_PASSWORD(),
                    options: {
                        method: build.Method.Post,
                        body: { email },
                    },
                };
            },
        }),
    },
    patch: {
        updatePassword: build<any, PatchUpdatePasswordRequest>({
            cachePrefix: 'update-password',
            query: ({ code, password }) => {
                return {
                    uri: AUTH_ENDPOINTS.PATCH.UPDATE_PASSWORD(),
                    options: {
                        method: build.Method.Patch,
                        body: { code, password },
                    },
                };
            },
        }),
    },
}));

export { authApi };
