import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { FeedItem } from '@knocklabs/client';
import { FilterStatus } from '@knocklabs/react';

import { Bit } from '@blockworks/ui/bit';
import { FlexBox, Link, List, Repeater } from '@blockworks/ui/components';

import { useKnock } from '@/hooks/use-knock';
import { KnockWorkflowKeys } from '@/utils/knock/knock-constants';

import { NotificationPopoverItem } from './notification-popover-item';

interface NotificationPopoverListProps {
    filterStatus: FilterStatus | undefined;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NotificationPopoverList = ({ filterStatus, setIsOpen }: NotificationPopoverListProps) => {
    const { knockFeed, knockFeedStore } = useKnock(filterStatus);
    const { items: notifications } = knockFeedStore;
    const isLoading = knockFeed.getState().loading || knockFeedStore.networkStatus === 'fetchMore';

    const handleNotificationClick = async (notification: FeedItem): Promise<void> => {
        try {
            await knockFeed?.markAsRead(notification);
        } catch (error) {
            console.error('Error marking notification as read:', error);
        } finally {
            setIsOpen(false);
        }
    };

    if (isLoading) {
        return (
            <FlexBox h={400}>
                <List divided h={400}>
                    <Repeater count={10}>
                        <List.Row>
                            <FlexBox justifyContent="between" p={1} w="full">
                                <FlexBox gap="md" alignItems="center">
                                    <Skeleton width={20} height={20} circle />
                                    <Bit.div>
                                        <Skeleton width={100} height={25} />
                                        <Skeleton width={120} height={15} />
                                    </Bit.div>
                                </FlexBox>
                                <Skeleton width={40} height={15} />
                            </FlexBox>
                        </List.Row>
                    </Repeater>
                </List>
            </FlexBox>
        );
    }

    return (
        <FlexBox h={400}>
            <List divided h={400}>
                {notifications?.map(notification => {
                    const data = notification?.activities[0]?.data;
                    const assetCode = data?.data?.asset?.code.toLowerCase() || data?.assetCode;
                    const workflowKey = notification?.source.key;
                    let actionUrl;

                    if (
                        workflowKey === KnockWorkflowKeys.PROPOSAL_CREATED ||
                        workflowKey === KnockWorkflowKeys.PROPOSAL_STATUS
                    ) {
                        if (data?.id) {
                            actionUrl = `/assets/${assetCode}/governance/${data?.id}`;
                        } else {
                            actionUrl = data?.data?.url ?? '/';
                        }
                    } else {
                        actionUrl = data?.data?.url ?? data?.url;
                    }

                    return (
                        <List.Row
                            key={notification.id}
                            className="p-2"
                            onClick={() => handleNotificationClick(notification)}
                        >
                            <Link href={actionUrl} target="_self" w="full">
                                <NotificationPopoverItem notification={notification} />
                            </Link>
                        </List.Row>
                    );
                })}
            </List>
        </FlexBox>
    );
};
