export const FIRST_PAGE_NUM = 0;

export const EDITORIAL_URL = `https://blockworks.co`;

export const INDUSTRIES = [
    'Investor (Crypto-native)',
    'Builder (Crypto-native)',
    'Investor (Tradfi)',
    'Sell-side (Tradfi)',
    'Government',
    'Media',
    'Education',
    'Tech',
    'Marketing',
    'Other',
] as const;

export const JOB_TITLES = [
    'Founder',
    'C-suite',
    'Partner',
    'VP',
    'Director',
    'Manager',
    'Associate',
    'Analyst',
    'Intern',
    'Student',
    'DAO Contributor',
    'Self-employed / Independent',
] as const;

export const ASSET_THEMES = {
    BTC: {
        asset: 'Bitcoin',
        code: 'BTC',
        accent: '#E6983F',
        background: '',
    },
    ETH: {
        asset: 'Ethereum',
        code: 'ETH',
        accent: '#5A7AE2',
        background: '',
    },
    SOL: {
        asset: 'Solana',
        code: 'SOL',
        accent: '#9844FE',
        background: '',
    },
    LINK: {
        asset: 'Chainlink',
        code: 'LINK',
        accent: '#3A5ED6',
        background: '',
    },
    AVAX: {
        asset: 'Avalanche',
        code: 'AVAX',
        accent: '#D64F49',
        background: '',
    },
    UNI: {
        asset: 'Uniswap',
        code: 'UNI',
        accent: '#EA337A',
        background: '',
    },
    ATOM: {
        asset: 'Cosmos Hub',
        code: 'ATOM',
        accent: '#2A3367',
        background: '',
    },
    LDO: {
        asset: 'Lido DAO',
        code: 'LDO',
        accent: '#E99D8C',
        background: '',
    },
    NEAR: {
        asset: 'NEAR Protocol',
        code: 'NEAR',
        accent: '#00EB91',
        background: '',
    },
    RUNE: {
        asset: 'THORChain',
        code: 'RUNE',
        accent: '#70E7C6',
        background: '',
    },
    OPT: {
        asset: 'Optimism',
        code: 'OPT',
        accent: '#EA3431',
        background: '',
    },
    ARB: {
        asset: 'Arbitrum',
        code: 'ARB',
        accent: '#4EA7F8',
        background: '',
    },
    AAVE: {
        asset: 'Aave',
        code: 'AAVE',
        accent: '#5EB7C3',
        background: '',
    },
    MKR: {
        asset: 'Maker',
        code: 'MKR',
        accent: '#7FC6B7',
        background: '',
    },
    SNX: {
        asset: 'Synthetix Network',
        code: 'SNX',
        accent: '#5FCEFA',
        background: '',
    },
    FXS: {
        asset: 'Frax Share',
        code: 'FXS',
        accent: '##000000',
        background: '',
    },
    DYDX: {
        asset: 'dYdX',
        code: 'DYDX',
        accent: '#6967F6',
        background: '',
    },
    CRV: {
        asset: 'Curve DAO',
        code: 'CRV',
        accent: '#EC6030',
        background: '',
    },
    OSMO: {
        asset: 'Osmosis',
        code: 'OSMO',
        accent: '#CB2DC5',
        background: '',
    },
    COMP: {
        asset: 'Compound',
        code: 'COMP',
        accent: '#60D099',
        background: '',
    },
    CVX: {
        asset: 'Convex Finance',
        code: 'CVX',
        accent: '#3A3A3A',
        background: '',
    },
    YFI: {
        asset: 'yearn.finance',
        code: 'YFI',
        accent: '#316FE3',
        background: '',
    },
    SUSHI: {
        asset: 'Sushi',
        code: 'SUSHI',
        accent: '#E65F9F',
        background: '',
    },
    RBN: {
        asset: 'Ribbon Finance',
        code: 'RBN',
        accent: '#E73558',
        background: '',
    },
    BAL: {
        asset: 'Balancer',
        code: 'BAL',
        accent: '#242424',
        background: '',
    },
    JOE: {
        asset: 'JOE',
        code: 'JOE',
        accent: '#CC6359',
        background: '',
    },
    STG: {
        asset: 'Stargate Finance',
        code: 'STG',
        accent: '#8C8C8C',
        background: '',
    },
    SYP: {
        asset: 'Synapse',
        code: 'SYP',
        accent: '#EA0DFC',
        background: '',
    },
    AURA: {
        asset: 'Aura Finance',
        code: 'AURA',
        accent: '#9649D5',
        background: '',
    },
    VLO: {
        asset: 'Velodrome Finance',
        code: 'VLO',
        accent: '#EA3724',
        background: '#191919',
    },
};
