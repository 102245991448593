import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const SocialTelegramIcon = forwardRef(
    ({ title, titleId, borderRadius, ...props }: IconProps, ref?: LegacyRef<SVGSVGElement>) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
            width={props.width}
            height={props.height}
            role="img"
            aria-hidden="true"
            className={styles.icon({
                ...props,
                borderRadius,
            })}
            ref={ref}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <circle cx={7} cy={7} r={7} fill="currentColor" />
            <path
                fill="var(--color-current-bg)"
                d="M10.057 4.466c.054-.352-.28-.63-.594-.493l-6.24 2.74c-.226.099-.21.44.024.514l1.287.41a.83.83 0 0 0 .726-.111l2.902-2.005c.087-.06.182.064.108.141L6.18 7.816a.47.47 0 0 0 .081.715L8.6 9.998c.262.164.6 0 .649-.318l.807-5.214Z"
            />
        </svg>
    ),
);
SocialTelegramIcon.displayName = 'SocialTelegramIcon';
export default SocialTelegramIcon;
