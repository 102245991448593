import * as React from 'react';

import { Avatar, Badge, FlexBox, List, Text } from '@blockworks/ui/components';
import { DateFormat } from '@blockworks/ui/date-time';

import { AppRoutes } from '@/services/auth/auth.routes';

import { ItemTypes } from '../utils/search.enums';
import { ResearchHit, SearchHitProps } from '../utils/search.types';

const ResearchItem = (props: SearchHitProps<ResearchHit>) => {
    const { objectID, tags = [], slug, kind, featuredImage, title, publishedAt, onSelect } = props;
    const itemData = { objectID, tags, slug, kind, featuredImage, title } as ResearchHit;
    let href = '';

    if (kind === 'flashnote') {
        href = AppRoutes.FLASHNOTES_SLUG.pathname(slug);
    } else if (kind === 'article') {
        href = AppRoutes.RESEARCH_SLUG.pathname(slug);
    }

    return (
        <List.Row id={objectID} onClick={() => onSelect?.(itemData, ItemTypes.Research)} href={href}>
            <List.Col size="content">
                <Avatar alt={title!} src={featuredImage?.formats?.thumbnail?.url || ''} size={32} />
            </List.Col>
            <List.Col size="content" grow>
                <FlexBox h="full" alignItems="start" col>
                    <Text size="sm" weight="semibold" lines={1}>
                        {title}
                    </Text>
                    <Text size="xs" color="deselect" lines={1}>
                        <DateFormat date={publishedAt} />
                    </Text>
                </FlexBox>
            </List.Col>
            <List.Col size="content">
                <FlexBox gap="xs">
                    {tags
                        ?.filter(t => t.name.indexOf(':') === -1)
                        .slice(0, 4)
                        .map(tag => <Badge key={tag.name}>{tag.name}</Badge>)}
                </FlexBox>
            </List.Col>
        </List.Row>
    );
};

export { ResearchItem };
