import { createMutation, createQuery } from 'react-query-kit';

import { STALE_TIME } from '@blockworks/platform/api';
import {
    AssetFieldParam,
    assetsApi,
    type GetAssetsRequest,
    type GetAssetsResponse,
    type GetFavoriteAssetsResponse,
} from '@blockworks/platform/api/research/assets';
import { strapiApi } from '@blockworks/platform/api/strapi';
import { AssetTabCategory } from '@blockworks/platform/api/strapi/models/raw';

import { internalAssetsApi } from '@/api/assets/internal';

import {
    selectAddressList,
    selectAssetByAnalyst,
    selectAssetData,
    selectAssetLinks,
    selectAssetsAndTotal,
    selectAssetsByExcludedCodes,
} from './asset-queries.selectors';

const useGetEthGas = createQuery({
    queryKey: [internalAssetsApi.get.ethGas.cachePrefix],
    fetcher: internalAssetsApi.get.ethGas,
    staleTime: STALE_TIME.$3Minutes,
});

const useGetAsset = createQuery({
    queryKey: [assetsApi.get.asset.cachePrefix],
    fetcher: assetsApi.get.asset,
    staleTime: STALE_TIME.$1Minute,
});

const useGetAssets = createQuery<GetAssetsResponse, GetAssetsRequest>({
    queryKey: [assetsApi.get.assets.cachePrefix],
    fetcher: assetsApi.get.assets,
    staleTime: STALE_TIME.$1Minute,
});

const useGetFavoriteAssets = createQuery<GetFavoriteAssetsResponse>({
    queryKey: [assetsApi.get.favorites.cachePrefix],
    fetcher: assetsApi.get.favorites,
});

/** @todo add response type */
const useUpdateFavorite = createMutation({
    mutationKey: [internalAssetsApi.post.favorite.cachePrefix],
    mutationFn: internalAssetsApi.post.favorite,
});

const useGetAssetTabs = createQuery({
    queryKey: ['strapi-asset-tabs'],
    fetcher: async ({ slug, tabs }: { slug: string; tabs: AssetTabCategory[] }) => {
        const { data } = await strapiApi.get.assetTabs({
            sort: 'tab:asc',
            filters: { code: { $eqi: slug }, tab: { $eq: tabs } },
            pagination: { pageSize: 20, page: 1 },
        });
        return data;
    },
    staleTime: STALE_TIME.$1Hour,
});

const useGetAssetsWithGovernance = createQuery({
    queryKey: [internalAssetsApi.get.assetsWithGovernance.cachePrefix],
    fetcher: internalAssetsApi.get.assetsWithGovernance,
    staleTime: STALE_TIME.$1Hour,
});

const useGetAddressByAsset = createQuery({
    queryKey: [assetsApi.get.addresses.cachePrefix],
    fetcher: async ({ slug, type }: { slug: string; type?: string[] }) => assetsApi.get.addresses({ slug, type }),
});

const useGetAssetLinks = createQuery({
    queryKey: [assetsApi.get.links.cachePrefix],
    fetcher: assetsApi.get.links,
});

const assetQueries = {
    getAddressesByAsset: { use: useGetAddressByAsset, select: { addresses: selectAddressList } },
    getAssets: {
        use: useGetAssets,
        select: {
            byAnalyst: selectAssetByAnalyst,
            assetsAndTotal: selectAssetsAndTotal,
            excludeAssetsByCode: selectAssetsByExcludedCodes,
        },
        params: {
            Field: AssetFieldParam,
        },
    },
    getAsset: { use: useGetAsset, select: { assetData: selectAssetData } },
    getEthGas: { use: useGetEthGas },
    getFavorites: { use: useGetFavoriteAssets },
    updateFavorite: { use: useUpdateFavorite },
    getAssetTabs: { use: useGetAssetTabs },
    getAssetsWithGovernance: { use: useGetAssetsWithGovernance },
    getAssetLinks: { use: useGetAssetLinks, select: { links: selectAssetLinks } },
};

export { assetQueries };
