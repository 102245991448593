import { PlatformConfig } from '@blockworks/platform';

const ResearchConfig = {
    ...PlatformConfig,
    alchemyRpcUrl: `https://eth-mainnet.alchemyapi.io/v2/${process.env.ALCHEMY_KEY}`,
    stripePrice: {
        QUARTERLY: process.env.NEXT_PUBLIC_STRIPE_PRICE_QUARTERLY || '',
        YEARLY: process.env.NEXT_PUBLIC_STRIPE_PRICE_YEARLY || '',
        PERMIE: process.env.NEXT_PUBLIC_STRIPE_PRICE_PERMIE || '',
    },
    STRAPI_PROXY_TOKEN: process.env.STRAPI_PROXY_TOKEN,
    STRIPE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    editorial: {
        ALGOLIA_APP_ID: process.env.EDITORIAL_ALGOLIA_APP_ID || '',
        ALGOLIA_KEY: process.env.EDITORIAL_ALGOLIA_KEY || '',
    },
    nextSEO: {
        twitter: {
            handle: '@Blockworks_',
            site: '@Blockworks_',
            cardType: 'summary_large_image',
        },
    },
    imgixUrl: 'https://blockworksresearch.imgix.net',
    prodBasename: 'blockworksresearch.com',
    site_name: 'Blockworks Research',
    title: 'Blockworks Research',
    description:
        'Crypto has evolved into distinct sectors: DeFi, Gaming, NFTs, Bitcoin, Stablecoins, DAOs and more. ' +
        'Blockworks Research brings you protocol and sector specialists who guide you through each area so ' +
        'you can build tailored investment strategies for each.',
    logoUrl: 'https://blockworks-research.s3.us-east-2.amazonaws.com/assets/blockworks-logo-v2.png',
    ogImageUrl: `${process.env.SITE_URL}/images/og/BWR-OG-IMG-1200x630.png`,
    permittedPaths: [
        '/sign-in',
        '/sign-in/forgot',
        '/sign-in/forgot/[token]',
        '/sign-up',
        '/sign-up/profile',
        '/sign-up/select-plan',
        '/sign-up/contact-sales',
        '/sign-up/welcome',
        '/sign-up/welcome/favorites',
        '/sign-up/welcome/initialize',
        '/settings/wallet',
        '/[slug]',
        '/unlocked/[slug]',
        '/404',
    ],
    assetConfig: {
        btc: {
            analytics: true,
        },
        bnb: {
            analytics: true,
        },
        eth: {
            analytics: true,
        },
        sol: {
            analytics: true,
        },
        avax: {
            analytics: true,
        },
        uni: {
            analytics: true,
            governance: true,
        },
        op: {
            analytics: true,
            governance: true,
        },
        tia: {
            analytics: true,
        },
        arb: {
            analytics: true,
            governance: true,
        },
        mkr: {
            analytics: true,
            governance: true,
        },
        crv: {
            analytics: true,
            governance: true,
        },
        matic: {
            analytics: true,
        },
        base: {
            analytics: true,
        },
        atom: {
            governance: true,
        },
        aave: {
            governance: true,
        },
        rune: {
            governance: true,
        },
        cvx: {
            governance: true,
        },
        comp: {
            governance: true,
        },
        yfi: {
            governance: true,
        },
        sushi: {
            governance: true,
        },
        ethdydx: {
            governance: true,
        },
        ens: {
            governance: true,
        },
        rpl: {
            governance: true,
        },
        fxs: {
            governance: true,
        },
        joe: {
            governance: true,
        },
        bal: {
            governance: true,
        },
        ldo: {
            governance: true,
        },
        syn: {
            governance: true,
        },
        rbn: {
            governance: true,
        },
        snx: {
            governance: true,
        },
        osmo: {
            governance: true,
        },
        gmx: {
            governance: true,
        },
        stg: {
            governance: true,
        },
        aura: {
            governance: true,
        },
    },
};

export default ResearchConfig;
