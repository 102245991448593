import { Session } from 'next-auth';

import { AuthProvider as BlockworksAuthProvider } from '@blockworks/session/context';

import { IdentifyUser } from './identify-user';

const AuthProvider = ({ children, session }: React.PropsWithChildren<{ session: Session | null }>) => {
    return (
        <BlockworksAuthProvider initialSession={session}>
            <IdentifyUser />
            {children}
        </BlockworksAuthProvider>
    );
};

export { AuthProvider };
