import { useMemo } from 'react';
import { Session } from 'next-auth';
import { SessionContextValue, SessionProvider, useSession } from 'next-auth/react';

import { useIsomorphicLayoutEffect } from '@blockworks/platform/hooks';
import { createSafeContext } from '@blockworks/ui/utils';

import { authQueries } from '../../api/queries';
import { AuthRole } from '../../models';
import { getPermissionLevel } from '../../utils';
import { ThemeProvider } from '../theme';

import { useOptimisticTheme } from './auth.utils';

type AuthContextValue = SessionContextValue & {
    user: Session['user'];
    permission?: AuthRole;
};

const [AuthContextProvider, useAuth] = createSafeContext<AuthContextValue>({
    strict: false,
    name: 'AuthContext',
    hookName: 'useAuth',
    providerName: 'AuthProvider',
    defaultValue: {
        user: undefined,
        update: async () => null,
        data: null,
        status: 'loading',
        permission: undefined,
    },
});

const SessionSetter = ({ children }: React.PropsWithChildren) => {
    const session = useSession();
    const { data } = session;
    const user = data?.user;

    useOptimisticTheme(session);

    authQueries.update.use({
        variables: { type: 'refresh' },
        refetchInterval: 1000 * 60 * 5,
    });

    const sessionCtx = useMemo(
        () => ({
            ...session,
            user,
            permission: getPermissionLevel(user!),
        }),
        [session, user],
    );

    useIsomorphicLayoutEffect(() => {
        if (typeof window !== 'undefined' && user?.sessionToken) {
            window.sessionStorage.setItem('sessionToken', user?.sessionToken);
        }
    }, [user?.sessionToken]);

    return <AuthContextProvider value={sessionCtx}>{children}</AuthContextProvider>;
};

type AuthProviderProps = React.PropsWithChildren<{
    initialSession?: Session | null;
}>;

const AuthProvider = ({ children, initialSession }: AuthProviderProps) => {
    // If the initial session is null it will not trigger an iniitial fetch
    // this ensures that sessions thought to be unauthenticated are validated immediately with the backend
    const session = initialSession ?? undefined;
    // This value is used for the toggle / any components that may have differing server / client behavior like Radix components
    const initialTheme = session?.user?.colorTheme;
    return (
        <ThemeProvider initialTheme={initialTheme}>
            <SessionProvider session={session}>
                <SessionSetter>{children}</SessionSetter>
            </SessionProvider>
        </ThemeProvider>
    );
};

export type { AuthContextValue };
export { AuthProvider, useAuth };
