import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

const scrollAreaStyles = tVariants({
    base: 'max-h-[--scroll-height] print:max-h-max w-full max-w-[--scroll-width]',
    slots: {
        wrapper: `relative rounded-md max-h-[--scroll-height] print:max-h-max`,
        scrollbar:
            'data-vertical:w-[--thumb-size] data-vertical:[--radix-scroll-area-thumb-height:--thumb-size] data-horizontal::w-[--thumb-size] data-horizontal:[--radix-scroll-area-thumb-height:--thumb-size]',
        thumb: 'group-data-vertical/scrollbar:w-[--thumb-size] group-data-horizontal/scrollbar:h-[--thumb-size] bg-surface-interact',
    },
    variants: {
        transparent: { true: { wrapper: `bg-transparent` } },
        thumb: {
            none: { wrapper: '[--thumb-size:0px]' },
            xs: { wrapper: '[--thumb-size:2px]' },
            sm: { wrapper: '[--thumb-size:4px]' },
            md: { wrapper: '[--thumb-size:8px]' },
        },
        fitContainer: { true: { wrapper: 'h-[--scroll-height]', base: 'h-[--scroll-height]' } },
        w: factoryStyleProps.size.w,
        maxW: factoryStyleProps.size.maxW,
    },
});

export { scrollAreaStyles };
