import { forwardRef } from 'react';

import { TVComponentProps } from '../../style-system';

import { flexBoxStyles } from './flex-box.styles';

type FlexBoxProps = TVComponentProps<'div', typeof flexBoxStyles>;

const FlexBox = forwardRef<HTMLDivElement, FlexBoxProps>(({ children, ...rest }, ref) => {
    return (
        <div className={flexBoxStyles(rest)} ref={ref}>
            {children}
        </div>
    );
});

FlexBox.displayName = 'FlexBox';

export type { FlexBoxProps };
export { FlexBox };
