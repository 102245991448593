import { tVariants } from '../../style-system';

const tabStyles = tVariants({
    base: 'group/tabs w-full',
    slots: {
        list: 'px-1 border-surface-divider group-[.tabs-top]/tabs:border-t group-[.tabs-bottom]/tabs:border-b',
        tab: 'p-2 -mb-[1px] inline-flex gap-1 font-medium text-xs text-content-deselected data-selected:text-content border-content group-[.tabs-top]/tabs:data-selected:border-t group-[.tabs-bottom]/tabs:data-selected:border-b',
    },
    variants: {
        tabPlacement: {
            top: 'tabs-top',
            bottom: 'tabs-bottom',
        },
        colorVariant: {
            primary: {
                base: 'w-max',
                list: 'border-none',
                tab: 'border-b-2 border-b-transparent data-selected:border-b-primary data-selected:text-primary',
            },
        },
    },
});

export { tabStyles };
