import { useMemo } from 'react';

type ReactRef<T> = React.RefCallback<T> | React.MutableRefObject<T> | React.ForwardedRef<T>;

const assignRef = <T = any>(ref: ReactRef<T> | null | undefined, value: T | null) => {
    if (ref == null) return;

    if (typeof ref === 'function') {
        ref(value);
        return;
    }

    try {
        ref.current = value;
    } catch (error) {
        throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
    }
};

const mergeRefs =
    <T>(...refs: (ReactRef<T> | null | undefined)[]) =>
    (node: T | null) => {
        refs.forEach(ref => {
            assignRef(ref, node);
        });
    };

const useMergeRefs = <T>(...refs: (ReactRef<T> | null | undefined)[]) => useMemo(() => mergeRefs(...refs), refs);

export { assignRef, mergeRefs, useMergeRefs };
