import React from 'react';

import { useBoolean } from '@blockworks/platform/hooks';
import * as SessionComponents from '@blockworks/session/components';
import { Divider, Menu } from '@blockworks/ui/components';

type UserDropdownProps = {
    hideMenuLinks?: boolean;
};

const UserDropdown = (props: UserDropdownProps) => {
    const dropdown = useBoolean(false);

    return (
        <SessionComponents.UserDropdown open={dropdown.value} onOpenChange={dropdown.setValue}>
            <Divider orientation="horizontal" />
            <Menu>
                <Menu.List>
                    {props.hideMenuLinks ? null : (
                        <>
                            <span className="px-2">
                                <Menu.Item onClick={dropdown.setFalse} href="/settings/profile">
                                    Profile
                                </Menu.Item>
                                <Menu.Item onClick={dropdown.setFalse} href="/settings/notifications">
                                    Notifications
                                </Menu.Item>
                                <Menu.Item onClick={dropdown.setFalse} href="/settings/team">
                                    Team
                                </Menu.Item>
                                <Menu.Item onClick={dropdown.setFalse} href="/settings/billing">
                                    Billing
                                </Menu.Item>
                            </span>
                        </>
                    )}
                </Menu.List>
            </Menu>
        </SessionComponents.UserDropdown>
    );
};

export { UserDropdown };
