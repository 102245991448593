import 'react-loading-skeleton/dist/skeleton.css';

import { SkeletonTheme } from 'react-loading-skeleton';

import { Layout } from '@blockworks/ui/components';

import { ILayoutProps } from '@/interfaces/layout';
import Sidebar from '@/layout/global/sidebar/sidebar';
import TopBar from '@/layout/global/top-bar/top-bar';
import { LayoutHead } from '@/layout/layout-head';

export const GlobalLayout = (props: ILayoutProps) => {
    const { metaProps, children } = props;

    return (
        <SkeletonTheme baseColor="var(--color-surface-selected)" highlightColor="var(--color-surface-deselected)">
            <Layout grid>
                <LayoutHead metaProps={metaProps} />
                <Layout.Brand />
                <Layout.Topbar>
                    <TopBar />
                </Layout.Topbar>
                <Layout.Sidebar>
                    <Sidebar />
                </Layout.Sidebar>
                <Layout.Content>{children}</Layout.Content>
            </Layout>
        </SkeletonTheme>
    );
};
