import { useRouter } from 'next/router';

import { Menu } from '@blockworks/ui/components';

const govhubFilters = [
    {
        children: 'Most Important',
        href: `/governance?order=importance,desc`,
    },
    {
        children: 'Recent Proposals',
        href: `/governance?order=createdAt,desc`,
    },
    {
        children: 'Emerging Proposals',
        href: `/governance?order=createdAt,desc&type=emerging`,
    },
    // {
    //     children: 'Forum Discussions',
    //     href: `/governance?`, // TODO: add this
    // },
];

const GovHubMenu = () => {
    const router = useRouter();
    const { asPath, query } = router;
    return (
        <Menu sub>
            <Menu.List>
                {govhubFilters?.map(item => {
                    const onClick = () => {
                        router.push(item.href);
                    };
                    return (
                        <Menu.Item
                            active={`${asPath}?tag=${query.tag}` === item.href || asPath === item.href}
                            key={`govhub-${item.href}`}
                            onClick={onClick}
                            id={item.href}
                        >
                            {item.children}
                        </Menu.Item>
                    );
                })}
            </Menu.List>
        </Menu>
    );
};

export default GovHubMenu;
