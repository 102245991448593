import type {
    GetAssetAddressesReponse,
    GetAssetLinksResponse,
    GetAssetResponse,
    GetAssetsResponse,
} from '@blockworks/platform/api/research/assets';

const selectAssetData = (res: GetAssetResponse) => res.data;

const selectAssetByAnalyst = (analystSlug?: string | null) => (res: GetAssetsResponse) =>
    res.data.filter(asset => asset.analystRef === analystSlug);

const selectAddressList = (res: GetAssetAddressesReponse) => res.data.addresses;

const selectAssetLinks = (res: GetAssetLinksResponse) => res.data;

const selectAssetsAndTotal = (res: GetAssetsResponse) => ({ assets: res.data, totalCount: res._meta.total });

const selectAssetsByExcludedCodes = (excludedAssetCodes: string[]) => (res: GetAssetsResponse) =>
    res.data?.filter(asset => !excludedAssetCodes.includes(asset.code));

export {
    selectAddressList,
    selectAssetByAnalyst,
    selectAssetData,
    selectAssetLinks,
    selectAssetsAndTotal,
    selectAssetsByExcludedCodes,
};
