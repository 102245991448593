import { createMutation } from 'react-query-kit';
import { signIn, signOut } from 'next-auth/react';

import { authApi } from '@blockworks/platform/api/research/auth';

import { authMiddleware } from '../middleware';

const useSignIn = createMutation({
    mutationKey: ['signIn'],
    mutationFn: async ({
        provider,
        options,
    }: {
        provider: Parameters<typeof signIn>[0];
        options: Parameters<typeof signIn>[1];
    }) => {
        return signIn(provider, options);
    },
});

const useLogout = createMutation({
    mutationFn: async () => {
        try {
            authApi.get.logout();
            signOut({ redirect: true });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }

        return Promise.resolve();
    },
});

const useUpdateTheme = createMutation({
    mutationKey: ['updateTheme'],
    use: [authMiddleware.mutation.session, authMiddleware.mutation.theme],
    mutationFn: () => Promise.resolve(null),
});

const useSignup = createMutation({
    mutationKey: [authApi.post.signup.cachePrefix],
    mutationFn: authApi.post.signup,
});

const useUpdatePassword = createMutation({
    mutationKey: [authApi.patch.updatePassword.cachePrefix],
    mutationFn: authApi.patch.updatePassword,
});

const useRequestNewPassword = createMutation({
    mutationKey: [authApi.post.requestNewPassword.cachePrefix],
    mutationFn: authApi.post.requestNewPassword,
});

const authMutations = {
    logout: { use: useLogout },
    signin: { use: useSignIn },
    signup: { use: useSignup },
    updateTheme: { use: useUpdateTheme },
    updatePassword: { use: useUpdatePassword },
    requestNewPassword: { use: useRequestNewPassword },
};

export { authMutations };
