import { tVariants } from '../../style-system';
import { factoryStyles } from '../../style-system/factory/factory.styles';

const flexBoxStyles = tVariants(
    {
        extend: factoryStyles,
        base: 'flex',
        variants: {
            reverse: { true: 'flex-row-reverse' },
            center: { true: 'items-center justify-center' },
            col: { true: 'flex-col' },
            wrap: { true: 'flex-wrap' },
            gap: {
                none: 'gap-0',
                '2xs': 'gap-0.5',
                xs: 'gap-1',
                sm: 'gap-2',
                md: 'gap-3',
                lg: 'gap-4',
                xl: 'gap-6',
                '2xl': 'gap-8',
                '3xl': 'gap-10',
            },
            direction: {
                col: 'flex-col',
                row: 'flex-row',
            },
        },
    },
    {
        responsiveVariants: true,
    },
);

export { flexBoxStyles };
