import { tVariants } from '../../style-system';

const tooltipStyles = tVariants({
    base: 'text-content-deselected text-xs py-1 px-3 rounded-md shadow-md',
    slots: {
        arrow: 'text-content-deselected',
    },
    variants: {
        variant: {
            default: {
                base: 'bg-surface-highlight',
                arrow: 'fill-surface-highlight',
            },
            surface: {
                base: 'bg-surface',
                arrow: 'fill-surface',
            },
            depth: {
                base: 'bg-surface-depth',
                arrow: 'fill-surface-depth',
            },
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export { tooltipStyles };
