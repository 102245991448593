import type { ClassProp, VariantProps } from 'tailwind-variants';

import type { SlotClassNames, TVPropFunc } from '../models';

type SlotFn = (config: ClassProp) => string;

type TVProps<T extends TVPropFunc> = VariantProps<T> & {
    classNames?: Partial<SlotClassNames<T>>;
    className?: never;
};

export const mapClassNamesToSlots = <T extends TVPropFunc>(tvPropFn: T, props: TVProps<T>) => {
    const { classNames, className: _doNotUse, ...rest } = props;

    // Apply base + variant override
    const slots = tvPropFn(rest) as unknown as Record<string, SlotFn>;

    // Apply instance override
    return Object.fromEntries(
        Object.entries(slots).map(([key, slot]) => [key, slot({ className: classNames?.[key] })]),
    ) as SlotClassNames<T>;
};
