import React, { useEffect } from 'react';

import { Bit } from '@blockworks/ui/bit';

import { Search } from '@/components/search/search';

const GlobalSearch = () => {
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        const cmdK = (e: KeyboardEvent) => {
            if ((e.ctrlKey || e.metaKey) && e.key === 'k') {
                setOpen(prev => !prev);
            }
        };
        window.addEventListener('keydown', cmdK);
        return () => {
            window.removeEventListener('keydown', cmdK);
        };
    }, []);

    return (
        <Bit.div w="full" hoverCursor="text" data-testid="search-box">
            <Search open={open} setOpen={setOpen} />
        </Bit.div>
    );
};

export default GlobalSearch;
