import { tVariants } from '../../style-system';

const menuSelectStyles = tVariants({
    base: `w-[--radix-popper-anchor-width] rounded-md bg-surface overflow-hidden border  border-surface-deselected shadow-2xl`,
    slots: {
        button: 'w-full bg-surface-muted text-content font-medium text-sm p-1.5 rounded-md border border-surface-selected mb-1',
        option: 'flex items-center data-highlighted:outline-0 data-highlighted:focus:outline-0  data-highlighted:bg-surface-selected max-w-full p-2 text-sm cursor-pointer',
    },
});

export { menuSelectStyles };
