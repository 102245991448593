import Stripe from 'stripe';

import { StringUnion } from '../../../typescript';
import { defineEndpoints } from '../../config/define-endpoints';
import { DataWithMeta } from '../../config/type-helpers';

type GetSessionRequest = { sessionToken?: string | null };
type PostSignupRequest = {
    email: string;
    password: string;
    firstname: string;
    lastname: string;
    company: string;
    phoneNumber: string;
    countryCode: string;
};

type PostSignupResponse = DataWithMeta<{
    message: string;
    status: number;
}>;

type PostSigninRequest = {
    email: string;
    password: string;
};

type PostSessionRequest = {
    name?: string | null;
    email?: string | null;
    image?: string | null;
    id: string;
    provider: string;
    phoneNumber: string;
    authToken?: string;
    authTokenType?: string;
    accessToken: string;
    expiresAt?: number;
    emailVerified: StringUnion<'0' | '1' | unknown>;
    firstname: string | null;
    lastname: string | null;
};

type UserSessionShape = {
    id?: string | null;
    name: string;
    firstname: string;
    lastname: string;
    email: string;
    image: string;
    lastLogin?: number | null;
    phoneNumber: string | null;
    authProvider?: string | null;
    customerId?: string | null;
    subscription?: string | null;
    subscriptionStatus?: Stripe.Subscription.Status | null;
    company: {
        id?: number;
        name: string;
    };
};

type GetSessionResponse = DataWithMeta<UserSessionShape>;

type PatchUpdatePasswordRequest = { code: string; password: string };
type PostRequestNewPasswordRequest = { email: string };

const AUTH_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        LOGOUT: template('/api/v1/logout'),
        /** GET a user's session in our PHP backend */
        SESSION: template('/api/v1/user'),
    },
    POST: {
        LOGIN: template('/api/v1/login'),
        REQUEST_NEW_PASSWORD: template('/api/v1/forgot-password'),
        SIGNUP: template('/api/v1/signup'),
        /** Initialize a user's session in our PHP backend */
        SESSION: template('/api/v1/user'),
    },
    PATCH: {
        UPDATE_PASSWORD: template('/api/v1/forgot-password'),
    },
}));

export type {
    GetSessionRequest,
    GetSessionResponse,
    PatchUpdatePasswordRequest,
    PostRequestNewPasswordRequest,
    PostSessionRequest,
    PostSigninRequest,
    PostSignupRequest,
    PostSignupResponse,
    UserSessionShape,
};
export { AUTH_ENDPOINTS };
