import { useEffect, useRef, useState } from 'react';

const RESULTS_CLASS = '.search-results';
const HITS_QUERY = `${RESULTS_CLASS} li > div`;
const PREV_HITS_QUERY = `${RESULTS_CLASS} > div`;

const ARROW_DOWN = 'ArrowDown';
const ARROW_UP = 'ArrowUp';
const ENTER = 'Enter';

// For Algolia instantsearch search results dropdown navigation
const useHitsNavigation = (query: string) => {
    const [focusedIndex, setFocusedIndex] = useState<number | undefined>(undefined);
    const focusedIndexRef = useRef<HTMLDivElement>();

    useEffect(() => {
        const domQuery = query?.length ? HITS_QUERY : PREV_HITS_QUERY;
        const hits = document.querySelectorAll(domQuery);
        if (hits?.length && typeof focusedIndex !== 'undefined') {
            for (const h of hits) {
                h.ariaSelected = 'false';
            }
            const hitIndex = ((focusedIndex % hits.length) + hits.length) % hits.length;
            const hit = hits[hitIndex] as HTMLDivElement;
            if (hit) {
                hit.ariaSelected = 'true';
                hit.scrollIntoView({ block: 'nearest' });
                focusedIndexRef.current = hit;
            }
        }
    }, [focusedIndex, query]);

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ([ARROW_DOWN, ARROW_UP, ENTER].includes(e.key)) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (e.key === ARROW_DOWN) {
            setFocusedIndex(prev => (prev ?? -1) + 1);
        } else if (e.key === ARROW_UP) {
            setFocusedIndex(prev => (prev ?? 0) - 1);
        } else if (e.key === ENTER && focusedIndex !== null) {
            if (focusedIndexRef.current) {
                focusedIndexRef.current.click();
            }
        }
    };

    return { focusedIndexRef, handleInputKeyDown, setFocusedIndex };
};

export { useHitsNavigation };
