/**
 * Define the provider in its own file to avoid circular dependencies.
 *
 * more info: API queries consume the useWorker hook from this provider...
 * If we accidentally import the provider below with the hook, we'll get a circular dependency issue.
 */
import { PropsWithChildren, useState } from 'react';
import { type Remote, wrap } from 'comlink';

import type { VisualizationWorkerApi } from './viz-worker';
import { VisualizationWorkerContextProvider } from './viz-worker.context';

const VisualizationWorkerProvider = (props: PropsWithChildren) => {
    const [worker] = useState<Remote<VisualizationWorkerApi> | undefined>(() => {
        if (typeof window !== 'undefined') {
            const worker = new Worker(new URL('./viz-worker.ts', import.meta.url));
            worker.onmessage = ev => console.log(`Worker Message`, ev);
            return wrap(worker);
        }
        return undefined;
    });

    return <VisualizationWorkerContextProvider value={worker!}>{props.children}</VisualizationWorkerContextProvider>;
};

export { VisualizationWorkerProvider };
