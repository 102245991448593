import React, { PropsWithChildren } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';

import { TVStylePropsWithoutClassName } from '../../style-system';

import { tooltipStyles } from './tooltip.styles';

export interface TooltipProps
    extends TVStylePropsWithoutClassName<typeof tooltipStyles>,
        Pick<RadixTooltip.TooltipContentProps, 'side' | 'align'> {
    children?: React.ReactNode;
    triggerText?: React.ReactElement | string;
    contentText?: React.ReactElement | string;
    hideArrow?: boolean;
    disableHoverableContent?: RadixTooltip.TooltipProps['disableHoverableContent'];
}

const tooltipContext = React.createContext<Pick<TooltipProps, 'variant' | 'side' | 'align'>>({
    variant: 'default',
    side: 'right',
    align: 'center',
});

const TooltipProvider = tooltipContext.Provider;

const useTooltipContext = () => React.useContext(tooltipContext);

const Trigger = ({ children, ...rest }: PropsWithChildren<RadixTooltip.TooltipTriggerProps>) => {
    return <RadixTooltip.Trigger {...rest}>{children}</RadixTooltip.Trigger>;
};

const Content = ({
    children,
    hideArrow,
    ...rest
}: PropsWithChildren<{ hideArrow?: boolean } & Pick<RadixTooltip.TooltipPortalProps, 'container'>>) => {
    const { variant, side, align } = useTooltipContext();
    const { base, arrow } = tooltipStyles({ variant });
    return (
        <RadixTooltip.Portal {...rest}>
            <RadixTooltip.Content side={side} align={align} className={base()} sideOffset={hideArrow ? 4 : 2}>
                {children}
                {!hideArrow && <RadixTooltip.Arrow width={10} height={5} className={arrow()} />}
            </RadixTooltip.Content>
        </RadixTooltip.Portal>
    );
};

/**
 * This function uses composition for flexibility.
 * If the trigger is text only, pass the text as the `triggerText` prop.
 * Otherwise, pass the trigger as the `children` of the Tooltip.Trigger component.
 */
export const Tooltip = ({ children, triggerText, contentText, disableHoverableContent, ...rest }: TooltipProps) => {
    const { variant = 'default', side = 'right', align = 'center' } = { ...rest };
    if (triggerText && contentText && children) {
        return <p>Tooltip cannot have triggerText, contentText and children defined</p>;
    }
    return (
        <TooltipProvider value={{ variant, side, align }}>
            <RadixTooltip.Provider delayDuration={200} disableHoverableContent={disableHoverableContent}>
                <RadixTooltip.Root>
                    {triggerText && <Trigger>{triggerText}</Trigger>}
                    {contentText && <Content {...rest}>{contentText}</Content>}
                    {children}
                </RadixTooltip.Root>
            </RadixTooltip.Provider>
        </TooltipProvider>
    );
};

Tooltip.Trigger = Trigger;

Tooltip.Content = Content;
