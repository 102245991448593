import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

const menuListStyles = tVariants({
    base: 'list center m-0 flex w-full list-none rounded-md data-horizontal:flex-row data-horizontal:gap-2 data-vertical:flex-col data-vertical:gap-0.5',
    variants: {
        ...factoryStyleProps.space,
    },
});

export { menuListStyles };
