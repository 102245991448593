import { useRouter } from 'next/router';

import { Menu } from '@blockworks/ui/components';

import { useAppConfig } from '@/modules/context';

const getMenuItems = (assetCode: string) => [
    {
        children: 'Overview',
        href: `/assets/${assetCode}`,
        exact: true,
    },
    { children: 'Analytics', href: `/analytics/${assetCode}` },
    { children: 'Governance', href: `/assets/${assetCode}/governance` },
    {
        children: 'Research',
        href: `/assets/${assetCode}/research`,
    },
    { children: 'About', href: `/assets/${assetCode}/about` },
];

const AssetSubMenu = () => {
    const { asPath, query } = useRouter();
    const pathname = asPath.split('?')[0] ?? '';
    const code = typeof query.slug === 'string' ? query.slug.toLowerCase() : '';
    const { assetConfig } = useAppConfig();

    // Filter out analytics and governance links if not available from asset config
    const menuItems = getMenuItems(code)?.filter(item => {
        if (!assetConfig) {
            return true;
        }

        if (
            (item.children === 'Analytics' && !assetConfig[code]?.analytics) ||
            (item.children === 'Governance' && !assetConfig[code]?.governance)
        ) {
            return false;
        }

        return true;
    });

    return (
        <Menu sub>
            <Menu.List>
                {menuItems?.map(item => {
                    return (
                        <Menu.Item
                            active={item?.exact ? pathname === item.href : pathname.startsWith(item.href)}
                            key={`assets-${item.href}`}
                            href={item.href}
                            id={item.href}
                        >
                            {item.children}
                        </Menu.Item>
                    );
                })}
            </Menu.List>
        </Menu>
    );
};

export default AssetSubMenu;
