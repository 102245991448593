import { forwardRef } from 'react';

import type { DataTestIdProps } from '../../models';
import { mapClassNamesToSlots, safelySpreadDOMProps } from '../../style-system';
import type { TVComponentWithElementProps } from '../../style-system/models';
import { safeAttr } from '../../utils';
import type { IconComponent } from '../icon';

import { iconButtonStyles } from './icon-button.styles';

type IconButtonProps = DataTestIdProps &
    TVComponentWithElementProps<'button', typeof iconButtonStyles, 'children'> & {
        icon: IconComponent;
        children?: never;
        'aria-label': string;
        /**
         * Passing `true` displays the button's active state.
         * Useful for trigger buttons e.g. Popover Trigger
         **/
        active?: boolean;
    };

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(
    {
        icon: Icon,
        variant = 'fill',
        intent = 'neutral',
        size = 'md',
        borderRadius = 'md',
        classNames,
        active = false,
        ...rest
    },
    ref,
) {
    const { base, icon } = mapClassNamesToSlots(iconButtonStyles, {
        ...rest,
        classNames,
        intent,
        borderRadius,
        variant,
        size,
    });

    return (
        <button {...safelySpreadDOMProps(rest)} className={base} ref={ref} {...safeAttr(active, 'active')}>
            {Icon && <Icon borderRadius="full" className={icon} />}
        </button>
    );
});

export type { IconButtonProps };
export { IconButton };
