import { PropsWithChildren } from 'react';
import googleTagManager from '@analytics/google-tag-manager';
import mixpanelPlugin from '@analytics/mixpanel';
import { Analytics as VercelAnalytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';

import { AnalyticsProvider, usePageView } from '@blockworks/platform/services/analytics';
import { ChartbeatPlugin } from '@blockworks/platform/services/analytics/plugins/chartbeat';

import type { IMetaProps } from '@/layout/default-meta';

type AnalyticsProps = PropsWithChildren<{
    meta: IMetaProps;
}>;

const registerPlugins = (includeClientPlugins: boolean) => {
    const plugins = [];

    if (process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID) {
        plugins.push(
            googleTagManager({
                containerId: process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID,
                dataLayerName: 'PageDataLayer',
            }),
        );
    }

    if (process.env.CHARTBEAT_UID) {
        plugins.push(
            ChartbeatPlugin({
                uid: process.env.CHARTBEAT_UID || '',
                domain: 'app.blockworksresearch.com',
            }),
        );
    }
    if (includeClientPlugins) {
        if (process.env.MIXPANEL_API_KEY) {
            plugins.push(
                mixpanelPlugin({
                    pageEvent: 'Page View',
                    token: process.env.MIXPANEL_API_KEY,
                    customScriptSrc: `${process.env.SITE_URL}/mp/lib.min.js`,
                    options: {
                        api_host: `${process.env.SITE_URL}/mp`,
                        persistence: 'localStorage',
                        verbose: process.env.NODE_ENV === 'development',
                        debug: process.env.NODE_ENV === 'development',
                    },
                }),
            );
        }
    }
    return plugins;
};

const PageView = ({ meta }: Pick<AnalyticsProps, 'meta'>) => {
    usePageView({ meta });
    return null;
};

const Analytics = ({ meta, children }: AnalyticsProps) => (
    <AnalyticsProvider app="blockworks-research" version="1.0.0" registerPlugins={registerPlugins}>
        {children}
        <PageView meta={meta} />
        <VercelAnalytics />
        <SpeedInsights sampleRate={0.7} />
    </AnalyticsProvider>
);

export { Analytics };
