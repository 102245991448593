import { isServerSide } from './is-serverside';

const openNewTab = (href?: string) => {
    if (isServerSide() || !href) return;
    window.open(href, '_blank');
};

openNewTab.with = (href?: string) => () => openNewTab(href);

export { openNewTab };
