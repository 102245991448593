import { FunctionComponent } from "react";

const LockIcon: FunctionComponent = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_251_243)">
      <path
        d="M8.62497 4.99975H8.24997V3.12476C8.24997 2.52802 8.01292 1.95572 7.59096 1.53377C7.169 1.11181 6.59671 0.874756 5.99997 0.874756C5.40323 0.874756 4.83094 1.11181 4.40898 1.53377C3.98702 1.95572 3.74997 2.52802 3.74997 3.12476V4.99975H3.37497C2.97728 5.00019 2.596 5.15836 2.31479 5.43957C2.03358 5.72078 1.8754 6.10206 1.87497 6.49975V10.6248C1.8754 11.0224 2.03358 11.4037 2.31479 11.6849C2.596 11.9661 2.97728 12.1243 3.37497 12.1248H8.62497C9.02266 12.1243 9.40394 11.9661 9.68515 11.6849C9.96636 11.4037 10.1245 11.0224 10.125 10.6248V6.49975C10.1245 6.10206 9.96636 5.72078 9.68515 5.43957C9.40394 5.15836 9.02266 5.00019 8.62497 4.99975ZM7.49997 4.99975H4.49997V3.12476C4.49997 2.72693 4.658 2.3454 4.93931 2.0641C5.22061 1.78279 5.60214 1.62476 5.99997 1.62476C6.39779 1.62476 6.77932 1.78279 7.06063 2.0641C7.34193 2.3454 7.49997 2.72693 7.49997 3.12476V4.99975Z"
        fill="#3C4257"
      />
    </g>
    <defs>
      <clipPath id="clip0_251_243">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LockIcon;
