import type { AnyFunction } from '@blockworks/platform/typescript';

const callAll =
    <T extends AnyFunction>(...fns: (T | undefined)[]) =>
    (...args: Parameters<T>) => {
        fns.forEach(fn => fn?.(...args));
    };

const callAllHandlers =
    <T extends (event: any) => void>(...fns: (T | undefined)[]) =>
    (event: Parameters<T>[0]) => {
        fns.some(fn => {
            fn?.(event);
            return event?.defaultPrevented;
        });
    };

export { callAll, callAllHandlers };
