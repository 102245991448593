import { type VariantProps } from 'tailwind-variants';

type As = keyof JSX.IntrinsicElements;

const DEFAULT_AS = 'span' as const;

type El = React.ElementType<any, keyof JSX.IntrinsicElements>;
type TVPropFunc = (...args: any) => any;
type SlotClassNames<T extends TVPropFunc> = { [key in keyof ReturnType<T>]: string };
type ClassNameProp<T extends TVPropFunc, ForceString extends boolean = false> = {
    className?: ForceString extends true ? string : keyof ReturnType<T> extends string ? never : string;
    classNames?: ForceString extends true
        ? never
        : keyof ReturnType<T> extends string
          ? Partial<SlotClassNames<T>>
          : never;
};

type PropsOf<T extends El, OmitProps extends string | void = void> = OmitProps extends string
    ? Omit<React.ComponentPropsWithoutRef<T>, OmitProps>
    : React.ComponentPropsWithoutRef<T>;

type OmitElementProps<T extends El, PropsToOmit extends string | number | symbol> = Omit<PropsOf<T>, PropsToOmit>;

/**
 * Primary exports
 */

/**
 * Get className props for slots if they exist, or just a plain string.
 * Optionally force the className to be of `string` type with the second paramater.  */
type TVStyleProps<T extends TVPropFunc, ForceString extends boolean = false> = VariantProps<T> &
    ClassNameProp<T, ForceString>;
type TVStylePropsWithoutClassName<T extends TVPropFunc> = VariantProps<T>;

/** Get React component props with strongly typed className for possible component slots  */
type TVComponentProps<E extends El, T extends TVPropFunc> = TVStyleProps<T> & OmitElementProps<E, 'className'>;

type TVComponentWithElementProps<
    E extends El,
    T extends TVPropFunc,
    ExtraProps extends string | void = void,
> = TVStyleProps<T> &
    OmitElementProps<E, ExtraProps extends void ? keyof TVStyleProps<T> : keyof TVStyleProps<T> | ExtraProps>;

export type {
    As,
    PropsOf,
    SlotClassNames,
    TVComponentProps,
    TVComponentWithElementProps,
    TVPropFunc,
    TVStyleProps,
    TVStylePropsWithoutClassName,
};

export { DEFAULT_AS };
