import * as RadixToggleGroup from '@radix-ui/react-toggle-group';

import { TVStylePropsWithoutClassName } from '../../style-system';
import type { IconComponent } from '../icon';

import { multiToggleStyles } from './multi-toggle.styles';

type MultiToggleProps = Omit<RadixToggleGroup.ToggleGroupSingleProps, 'type'> &
    TVStylePropsWithoutClassName<typeof multiToggleStyles> & {
        values: string[] | { icon?: IconComponent; label: string; value: string }[];
        ariaLabel: string;
        value: string;
    };

const MultiToggle = ({
    values,
    ariaLabel,
    grouped = true,
    size = 'md',
    value,
    capitalize,
    onValueChange,
    intent,
    ...rest
}: MultiToggleProps) => {
    const { base, item: itemStyles, icon } = multiToggleStyles({ size, capitalize, intent });
    const handleValueChange = (newValue: string) => {
        if (!newValue || newValue === value) return;
        onValueChange?.(newValue);
    };

    const renderValues = () => {
        return values.map(item => {
            if (typeof item === 'string') {
                return (
                    <RadixToggleGroup.Item key={`${ariaLabel}-${item}`} className={itemStyles()} value={item}>
                        {item}
                    </RadixToggleGroup.Item>
                );
            }
            const Icon = item.icon;

            return (
                <RadixToggleGroup.Item
                    key={`${String()}-${item.value}`}
                    className={itemStyles()}
                    value={item.value}
                    aria-label={item.label}
                >
                    {Icon ? <Icon className={icon()} /> : item.label}
                </RadixToggleGroup.Item>
            );
        });
    };

    return (
        <RadixToggleGroup.Root
            type="single"
            onValueChange={handleValueChange}
            className={base({ grouped })}
            aria-label={ariaLabel}
            value={value}
            {...rest}
        >
            {renderValues()}
        </RadixToggleGroup.Root>
    );
};

export type { MultiToggleProps };
export { MultiToggle };
