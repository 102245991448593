import { TVStyleProps } from '../models';
import { tVariants } from '../utils/t-variants';

import { factoryStyleProps } from './style-props';

const factoryStyles = tVariants(
    {
        base: 'relative',
        variants: {
            ...factoryStyleProps.miscellaneous,
            ...factoryStyleProps.layout,
            ...factoryStyleProps.border,
            ...factoryStyleProps.color,
            ...factoryStyleProps.typography,
            ...factoryStyleProps.size,
            ...factoryStyleProps.space,
        },
    },
    {
        responsiveVariants: true,
    },
);

type FactoryStyleProps = TVStyleProps<typeof factoryStyles>;

export type { FactoryStyleProps };
export { factoryStyles };
