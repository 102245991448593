import { forwardRef } from 'react';

import type { As, TVComponentWithElementProps } from '../models';
import { DEFAULT_AS } from '../models';

import { factoryStyles } from './factory.styles';
import { safelySpreadProps } from './factory.utils';

type FactoryProps<T extends As = typeof DEFAULT_AS> = { as?: T | As } & TVComponentWithElementProps<
    T,
    typeof factoryStyles
>;

type FactoryPropsWithRef<T extends As = typeof DEFAULT_AS> = FactoryProps<T> & {
    ref?: React.ForwardedRef<React.ElementRef<T>>;
};

export declare function FactoryFnComponent<T extends As>(props: FactoryPropsWithRef<T>): JSX.Element;

const FactoryComponent = forwardRef<React.ElementRef<any>, FactoryProps>(function FactoryComponent(
    { as: Tag = DEFAULT_AS, children, ...rest },
    ref,
) {
    const { styleProps, htmlProps } = safelySpreadProps(rest);
    return (
        // @ts-ignore union is too complex to represent
        <Tag className={factoryStyles(styleProps)} {...htmlProps} ref={ref}>
            {children}
        </Tag>
    );
}) as unknown as typeof FactoryFnComponent;

export type { FactoryProps, FactoryPropsWithRef };
export { FactoryComponent };
