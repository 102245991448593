import React, { useRef } from 'react';
import algoliasearch from 'algoliasearch/lite';

import { ResearchIndexes } from '@blockworks/platform/services/algolia';
import { useTrack } from '@blockworks/platform/services/analytics';
import { AlgoliaSearchInput } from '@blockworks/ui/algolia-search-input';
import { Bit } from '@blockworks/ui/bit';
import { FlexBox, Input, Modal, ScrollArea } from '@blockworks/ui/components';
import { MagnifyingGlassIcon } from '@blockworks/ui/icon';

import { AnalyticsEvent } from '@/modules/utils/enums/events-tracking';
import ResearchConfig from '@/research-config';
import { useEdgeConfig } from '@/utils/edge-config';

import { AssetItem } from './hit-items/asset-item';
import { AuthorItem } from './hit-items/author-item';
import { DashboardItem } from './hit-items/dashboard-item';
import { NewsletterItem } from './hit-items/newsletter-item';
import { ProposalItem } from './hit-items/proposal-item';
import { ResearchItem } from './hit-items/research-item';
import { ItemTypes } from './utils/search.enums';
import { SearchHit } from './utils/search.types';
import { addRecentlyViewedHit } from './utils/search.utils';
import { RecentlyViewedSection } from './recently-viewed-section';
import { ResultSection } from './result-section';

const searchClient = algoliasearch(ResearchConfig.ALGOLIA_APP_ID, ResearchConfig.ALGOLIA_KEY);

interface SearchProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const Search = ({ open, setOpen }: SearchProps) => {
    const track = useTrack();
    const resultsRef = useRef<HTMLDivElement>(null);

    const { features } = useEdgeConfig();

    const handleInputClick = () => {
        setOpen(true);
        track(AnalyticsEvent.clickedSearchBar, { source: 'global-search' });
    };

    const handleSelect = (hit: SearchHit, type: ItemTypes) => {
        setOpen(false);
        addRecentlyViewedHit(hit, type);
    };

    return (
        <>
            <Bit.div onClick={handleInputClick}>
                <FlexBox alignItems="center" pl={3} pos="relative">
                    <FlexBox pos="absolute" alignItems="center" w="full" gap="sm">
                        <MagnifyingGlassIcon className="text-content-deselected h-6 w-6" />
                        <Bit.div className="text-content-deselected text-sm" id="search-text">
                            Search for assets, articles, or proposals.
                        </Bit.div>
                        <Bit.kbd className="inline-flex items-center bg-surface-raised text-content-deselected border gap-1  rounded-lg text-xxs px-1.5 border-surface-selected">
                            <Bit.span className="text-sm mt-[1px]">&#8984;</Bit.span>
                            <Bit.span>K</Bit.span>
                        </Bit.kbd>
                    </FlexBox>
                    <Input
                        size="md"
                        aria-label="Search"
                        aria-describedby="search-text"
                        w="full"
                        id="global-search"
                        variant="transparent"
                        noOutline
                        name="search-field"
                        disabled={open}
                        type="search"
                    />
                </FlexBox>
            </Bit.div>

            <Modal open={open} onOpenChange={setOpen} shadow="lg" borderRadius="xl">
                <Bit.div className="bg-surface-depth w-96 md:w-[720px] xl:w-[950px]">
                    <AlgoliaSearchInput.Provider searchClient={searchClient} indexName={ResearchIndexes.BWRSearch}>
                        <AlgoliaSearchInput resultsRef={resultsRef} setOpen={setOpen} />
                        <Bit.div className="min-h-[420px]">
                            <ScrollArea h={420} ref={resultsRef}>
                                <RecentlyViewedSection />
                                <ResultSection
                                    indexName={ResearchIndexes.BWRSearch}
                                    title="Research"
                                    indexId="research"
                                    filters={`kind:"article" OR kind:"flashnote"`}
                                    HitComponent={ResearchItem}
                                    onSelect={handleSelect}
                                />
                                <ResultSection
                                    indexName={ResearchIndexes.BWRSearch}
                                    title="Assets"
                                    indexId="assets"
                                    filters={`kind:"asset"`}
                                    HitComponent={AssetItem}
                                    onSelect={handleSelect}
                                />
                                <ResultSection
                                    indexName={ResearchIndexes.BWRSearch}
                                    title="Proposals"
                                    indexId="proposals"
                                    filters={`kind:"proposal"`}
                                    HitComponent={ProposalItem}
                                    onSelect={handleSelect}
                                />
                                <ResultSection
                                    indexName={ResearchIndexes.BWRSearch}
                                    title="Analytics"
                                    indexId="dashboards"
                                    filters={`kind:"dashboard"`}
                                    HitComponent={DashboardItem}
                                    onSelect={handleSelect}
                                />
                                <ResultSection
                                    indexName={ResearchIndexes.BWRSearch}
                                    title="Analysts"
                                    indexId="authors"
                                    filters={`kind:"author"`}
                                    HitComponent={AuthorItem}
                                    onSelect={handleSelect}
                                />
                                {features?.newsletters && (
                                    <ResultSection
                                        indexName={ResearchIndexes.BWRSearch}
                                        title="Newsletters"
                                        indexId="newsletters"
                                        filters={`kind:"newsletter"`}
                                        HitComponent={NewsletterItem}
                                        onSelect={handleSelect}
                                    />
                                )}
                            </ScrollArea>
                        </Bit.div>
                    </AlgoliaSearchInput.Provider>
                </Bit.div>
            </Modal>
        </>
    );
};
