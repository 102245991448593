import { defineEndpoints } from '@blockworks/platform/api';

interface RefreshQueriesRequest {
    studioDashboardId: string;
}

interface RefreshQueriesResponse {
    isExecuting?: boolean;
    startedAt?: string;
    completedAt?: string;
}

const INTERNAL_DASHBOARD_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        REFRESH_QUERIES_ENDPOINT: template('/api/dashboards/refresh-queries'),
    },
}));

export { INTERNAL_DASHBOARD_ENDPOINTS };
export type { RefreshQueriesRequest, RefreshQueriesResponse };
