import { useCallback, useState } from 'react';

type UseControllableStateProps<T> = {
    controlledValue?: T;
    defaultValue?: T;
    onChange?: (value: T) => void;
};

/**
 * Returns the state and function that updates the state just like `React.useState` does,
 * but with the additional ability to control state more explicitly.
 *
 * Pass `controlledValue` to imply the component is **controlled**.
 */
const useControllableState = <T>(props: UseControllableStateProps<T>) => {
    const { controlledValue, defaultValue, onChange } = props;

    const [uncontrolledState, setUncontrolledState] = useState(defaultValue as T);
    const isControlled = controlledValue !== undefined;
    const value = isControlled ? controlledValue : uncontrolledState;

    const setValue = useCallback(
        (next: React.SetStateAction<T>) => {
            const setter = next as (prevState?: T) => T;
            const nextValue = typeof next === 'function' ? setter(value) : next;

            if (!isControlled) {
                setUncontrolledState(nextValue);
            }
            onChange?.(nextValue);
        },
        [onChange, isControlled, value],
    );

    return [value, setValue] as [T, React.Dispatch<React.SetStateAction<T>>];
};

export type { UseControllableStateProps };
export { useControllableState };
