import { tVariants } from '../../style-system';

const sidebarNavStyles = tVariants({
    base: 'bg-surface p-2 pr-1 scroller overflow-y-auto w-[--sidebar-width] max-w-[--sidebar-width]',
    slots: {
        secondary:
            'bg-surface-contrast overflow-y-auto max-w-[--sidebar-width] [&:empty]:hidden [&:not(:empty)]:p-2 [&:not(:empty)]:w-[--sidebar-width]',
    },
});

export { sidebarNavStyles };
