import { buildEndpoint, type Dict } from './build-endpoint';

type BuildShape<T> = (makeEndpoint: typeof buildEndpoint) => T;

type EndpointsShape = {
    get?: Dict;
    post?: Dict;
    put?: Dict;
    delete?: Dict;
    patch?: Dict;
};

const createApi = <T extends EndpointsShape>(buildShape: BuildShape<T>) => buildShape(buildEndpoint);

export { createApi };
