import React, { createContext, useContext } from 'react';

import { defaultConfig, EdgeConfigContext, useEdgeConfig } from '@/utils/edge-config';

export const AppConfigContext = createContext<EdgeConfigContext>(defaultConfig);

export const useAppConfig = (): EdgeConfigContext => useContext(AppConfigContext);

export const AppConfigProvider = ({ children }: { children: React.ReactNode }) => {
    const providerValue = useEdgeConfig();

    return <AppConfigContext.Provider value={providerValue}>{children}</AppConfigContext.Provider>;
};
