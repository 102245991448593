import { createQuery } from 'react-query-kit';
import { Session } from 'next-auth';

import type { SessionActions } from '../../make-auth-config';
import { authMiddleware } from '../middleware';

export const update = createQuery<Session | null, SessionActions>({
    queryKey: ['session'],
    // The fetch is overwritten by the middleware here - if auth provider is not available, the session will always be null
    fetcher: () => Promise.resolve(null),
    use: [authMiddleware.query.session],
});

const authQueries = {
    update: { use: update },
};

export { authQueries };
