import React, { ReactNode } from 'react';
import { KnockFeedProvider, KnockProvider } from '@knocklabs/react';

import { useAuth } from '@blockworks/session/context';

interface KnockProviderProps {
    children: ReactNode;
}

export const NotificationsProvider = ({ children }: KnockProviderProps) => {
    const { user } = useAuth();

    return (
        <KnockProvider apiKey={process.env.KNOCK_PUBLIC_API_KEY as string} userId={user?.email as string}>
            <KnockFeedProvider feedId={process.env.KNOCK_FEED_CHANNEL_ID as string}>{children}</KnockFeedProvider>
        </KnockProvider>
    );
};
