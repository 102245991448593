import { useEffect } from 'react';
import Router from 'next/router';
import NProgress from 'nprogress';

NProgress.configure({
    showSpinner: false,
});

const useNProgress = () => {
    useEffect(() => {
        let timeout: NodeJS.Timeout;

        const start = () => {
            clearTimeout(timeout);
            /** if route change takes longer than .25s to load, then start */
            timeout = setTimeout(() => NProgress.start(), 250);
        };
        const stop = () => {
            clearTimeout(timeout);
            NProgress.done();
        };

        Router.events.on('routeChangeStart', start);
        Router.events.on('routeChangeComplete', stop);
        Router.events.on('routeChangeError', stop);

        return () => {
            Router.events.off('routeChangeStart', start);
            Router.events.off('routeChangeComplete', stop);
            Router.events.off('routeChangeError', stop);
            clearTimeout(timeout);
        };
    }, []);
};

export { useNProgress };
