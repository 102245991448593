import { FlexBox, Text } from '@blockworks/ui/components';
import { type IconComponent, MessageTextSquareIcon } from '@blockworks/ui/icon';

interface ResultsSectionHeaderProps {
    title: string;
    Icon?: IconComponent;
}

export const ResultsSectionHeader = ({ title, Icon = MessageTextSquareIcon }: ResultsSectionHeaderProps) => {
    return (
        <FlexBox gap="sm" p={2} borderTop={1} w="full">
            <Text size="xs" color="deselect">
                <Icon className="w-4 h-4" />
            </Text>
            <Text size="xs" color="deselect">
                {title}
            </Text>
        </FlexBox>
    );
};
