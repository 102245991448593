import React, { forwardRef } from 'react';

import { XCloseIcon } from '@blockworks/ui/icon';

import { cn } from '../../../style-system';
import { Divider } from '../../divider';
import { FlexBox } from '../../flex-box';
import { IconButton, IconButtonProps } from '../../icon-button';

import { ModalClose } from './modal-close';

type ModalHeaderProps = React.PropsWithChildren<{
    closeButtonSize?: IconButtonProps['size'];
    hideCloseButton?: boolean;
    disableCloseButton?: boolean;
}>;

const ModalHeader = forwardRef<HTMLDivElement, ModalHeaderProps>(function ModalHeader(
    { children, hideCloseButton = false, disableCloseButton = false, closeButtonSize = 'md' },
    ref,
) {
    return (
        <>
            <FlexBox ref={ref} w="full" justifyContent="between" alignItems="center" pl={4} pr={2} pt={2}>
                {children}
                {!hideCloseButton && (
                    <ModalClose>
                        <IconButton
                            size={closeButtonSize}
                            disabled={disableCloseButton}
                            variant="ghost"
                            icon={XCloseIcon}
                            aria-label="Close Dialog"
                        />
                    </ModalClose>
                )}
            </FlexBox>
            <div className={cn('w-full')}>
                <Divider orientation="horizontal" mb={0} />
            </div>
        </>
    );
});

export type { ModalHeaderProps };
export { ModalHeader };
