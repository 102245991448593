import { PropsWithChildren } from 'react';
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';

import type { DataTestIdProps } from '../../models/props';
import type { TVStyleProps } from '../../style-system';

import { menuListStyles } from './menu.styles';
import { MenuGroup } from './menu-group';
import { MenuItem, MenuItemProps } from './menu-item';
import { MenuSelect } from './menu-select';

export type MenuProps = PropsWithChildren<
    RadixNavigationMenu.NavigationMenuProps & {
        sub?: boolean;
    }
>;

export const Menu = ({ children, orientation = 'vertical', sub = false, ...rest }: MenuProps) => {
    if (!sub) {
        return (
            <RadixNavigationMenu.Root orientation={orientation} className="group/menu" {...rest}>
                {children}
            </RadixNavigationMenu.Root>
        );
    }
    return (
        <RadixNavigationMenu.Sub orientation={orientation} className="group/menu sub" {...rest}>
            {children}
        </RadixNavigationMenu.Sub>
    );
};

export interface MenuListProps extends DataTestIdProps, PropsWithChildren, TVStyleProps<typeof menuListStyles> {}

const MenuList = ({ children, ...rest }: MenuListProps) => (
    <RadixNavigationMenu.List className={menuListStyles(rest)} {...rest}>
        {children}
    </RadixNavigationMenu.List>
);

MenuList.displayName = 'Menu.List';
Menu.List = MenuList;
Menu.Viewport = RadixNavigationMenu.Viewport;
Menu.Group = MenuGroup;
Menu.Select = MenuSelect;
Menu.Item = MenuItem;

export type { MenuItemProps };
