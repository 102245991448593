import { AnyObj, Obj, ObjUnion, StringUnion } from '../../../typescript';
import { BASE_URLS, BaseUrl } from '../base-urls';
import type { FetcherOptions } from '../fetcher';
import { fetcher } from '../fetcher';
import { HttpMethod } from '../http-method';
import { UserAgent } from '../user-agent';

import { flow, stripEmpty } from './build-endpoint.utils';

type Dict = Obj<unknown>;
type DictOrVoid = Dict | void;

type Query<RequestParams extends DictOrVoid> = (args: RequestParams) => {
    uri: string;
    params?: RequestParams extends void ? AnyObj : ObjUnion<Partial<RequestParams>>;
    options?: Omit<FetcherOptions<any>, 'url' | 'originalResponse'>;
    overrideBaseUrl?: StringUnion<BaseUrl>;
};

type QueryFn<RequestParams extends DictOrVoid, EndpointResponse> = (
    reqParams: RequestParams,
    tools: { baseFetch: typeof fetcher },
) => Promise<EndpointResponse>;

type PrepareFetcher<RequestParams extends DictOrVoid> = (
    queryReturnValue: ReturnType<Query<RequestParams>>,
) => FetcherOptions<any>;

type BuildEndpointOpts<EndpointResponse, RequestParams extends DictOrVoid> =
    | {
          baseUrl?: BaseUrl;
          cachePrefix: string;
          query: Query<RequestParams>;
          queryFn?: never;
      }
    | {
          baseUrl?: BaseUrl;
          cachePrefix: string;
          query?: never;
          queryFn: QueryFn<RequestParams, EndpointResponse>;
      };

interface FetchEndpoint<Res, RequestArgs extends DictOrVoid> {
    (args?: RequestArgs): Promise<Res>;
    cachePrefix: string;
}

const getBaseUrl = (baseUrl: BaseUrl, override?: StringUnion<BaseUrl>) => {
    if (override) {
        return BASE_URLS[override as BaseUrl] || override;
    }
    return BASE_URLS[baseUrl];
};

const buildEndpoint = <EndpointResponse = any, RequestArgs extends DictOrVoid = void>({
    baseUrl = 'research-api',
    cachePrefix,
    query,
    queryFn,
}: BuildEndpointOpts<EndpointResponse, RequestArgs>) => {
    const prepareFetcher: PrepareFetcher<RequestArgs> = ({ uri, params, options = {}, overrideBaseUrl }) => {
        const base = getBaseUrl(baseUrl, overrideBaseUrl);
        let url = `${base}${uri}`;

        if (params) {
            const search = new URLSearchParams(stripEmpty(params));
            url += `?${search.toString()}`;
        }

        return { url, ...options };
    };

    let fetchEndpoint: FetchEndpoint<EndpointResponse, RequestArgs>;

    if (queryFn) {
        const prepareQueryFn = (requestArgs: RequestArgs) => [requestArgs, { baseFetch: fetcher }];
        fetchEndpoint = flow(prepareQueryFn, queryFn) as FetchEndpoint<EndpointResponse, RequestArgs>;
    } else {
        fetchEndpoint = flow(
            query,
            prepareFetcher,
            fetcher,
            /** asserted here so TS doesn't complain about `cachePrefix` not being assigned  */
        ) as FetchEndpoint<EndpointResponse, RequestArgs>;
    }

    fetchEndpoint.cachePrefix = cachePrefix;
    return fetchEndpoint;
};

buildEndpoint.Method = HttpMethod;
buildEndpoint.UserAgent = UserAgent;

export type { Dict, DictOrVoid, FetchEndpoint, QueryFn };
export { buildEndpoint };
