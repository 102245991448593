// For whatever reason null responses from the server do not trigger the useSession hook to sign out the user

import { Middleware, MutationHook, QueryHook } from 'react-query-kit';
import { Session } from 'next-auth';
import { signOut, useSession } from 'next-auth/react';

import { useTheme } from '../../context/theme';
import type { RefreshSessionAction, UpdateThemeAction } from '../../make-auth-config';

// For whatever reason null responses from the server do not trigger the useSession hook to sign out the user
// this middleware is a workaround for that issue and appropriately signs out the user in case of failure.
const sessionQueryMiddleware: Middleware<QueryHook<Session | null, RefreshSessionAction>> = useNextQuery => {
    return options => {
        const { update } = useSession();

        return useNextQuery({
            ...options,
            fetcher: async action => {
                const session = await update(action);
                if (session === null) {
                    signOut({ redirect: true });
                }
                return session;
            },
        });
    };
};

const sessionMutationMiddleware: Middleware<MutationHook<Session | null, UpdateThemeAction>> = useNextMutation => {
    return options => {
        const { update } = useSession();

        return useNextMutation({
            ...options,
            mutationFn: async action => {
                const session = await update(action);
                if (session === null) {
                    signOut({ redirect: true });
                }
                return session;
            },
        });
    };
};

// This allows us to optimistically update the theme on the client prior to the server response
const themeMutationMiddleware: Middleware<MutationHook<Session | null, UpdateThemeAction>> = useNextMutation => {
    return options => {
        const { setTheme } = useTheme();

        return useNextMutation({
            ...options,
            onMutate: ({ payload }) => {
                if (!payload) return;
                setTheme(payload);
            },
        });
    };
};

export const authMiddleware = {
    query: {
        session: sessionQueryMiddleware,
    },
    mutation: {
        theme: themeMutationMiddleware,
        session: sessionMutationMiddleware,
    },
};
