import React, { useCallback } from 'react';

import { useControllableState } from '@blockworks/platform/hooks';
import { Avatar, Button, Divider, FlexBox, Menu, Popover, Text } from '@blockworks/ui/components';
import { ChevronDownIcon } from '@blockworks/ui/icon';

import { authMutations } from '../api/mutations';
import { useAuth } from '../context/auth';

const getInitials = (name?: string, firstName?: string, lastname?: string) => {
    if (firstName && lastname) return `${firstName?.charAt(0)}${lastname?.charAt(0)}`;
    return name
        ?.split(' ')
        ?.map(n => n[0])
        ?.join('');
};

type UserDropdownProps = React.PropsWithChildren<{
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    defaultValue?: boolean;
}>;

const UserDropdown = ({ children, open: controlledValue, onOpenChange, defaultValue = false }: UserDropdownProps) => {
    const [open, setOpen] = useControllableState({
        defaultValue,
        controlledValue,
        onChange: onOpenChange,
    });
    const logout = authMutations.logout.use({});
    const handleLogout = useCallback(() => {
        setOpen(false);
        logout.mutate();
    }, [logout, setOpen]);
    const { user, data } = useAuth();

    const name = user?.name;
    const initials = getInitials(name, user?.firstname, user?.lastname);

    return (
        <Popover
            open={open}
            onOpenChange={setOpen}
            trigger={
                <Button intent="primary" w="max" size="xs" trailingIcon={ChevronDownIcon}>
                    <span className="hidden sm:flex">{name}</span>
                    <span className="sm:hidden flex uppercase">{initials}</span>
                </Button>
            }
            contentProps={{
                align: 'end',
            }}
        >
            <div className="w-[300px]">
                <FlexBox col pt={3} pb={2}>
                    <FlexBox alignItems="center" gap="sm" px={4} pb={1}>
                        <Avatar size={32} src={data?.user?.image ?? ''} alt={name} />
                        <FlexBox col gap="2xs" pb={1}>
                            <Text size="sm" lines={1}>
                                {user?.email}
                            </Text>
                            <Text size="xs" lines={1} color="deselect">
                                {user?.company?.name ?? ''}
                            </Text>
                        </FlexBox>
                    </FlexBox>
                    {children}
                    <Divider orientation="horizontal" />
                    <Menu>
                        <Menu.List px={2}>
                            <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
                        </Menu.List>
                    </Menu>
                </FlexBox>
            </div>
        </Popover>
    );
};

export { UserDropdown };
