import { tVariants } from '../../style-system';

const toastStyles = tVariants({
    base: 'w-[95vw] sm:w-[300px] py-2 px-3 shadow-md rounded-lg bg-surface',
});

const toastViewportStyles = tVariants({
    base: 'fixed z-50 top-4 left-1/2 sm:left-auto -translate-x-1/2 sm:-translate-x-0 right-auto sm:right-4 flex flex-col gap-2',
});

const toastIconStyles = tVariants({
    base: 'flex items-center justify-center relative w-6 h-6 p-1 rounded-sm',
    variants: {
        rounded: {
            true: 'rounded-full',
        },
        color: {
            info: 'bg-secondary-surface',
            warning: 'bg-warning-surface',
            danger: 'bg-danger-surface',
            success: 'bg-success-surface',
        },
    },
});

export { toastIconStyles, toastStyles, toastViewportStyles };
