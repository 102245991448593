import { Menu } from '../menu';

import { sidebarNavStyles } from './sidebar-nav.styles';

export type SidebarNavProps = React.PropsWithChildren<{
    activeRoute?: string;
}>;

export const SidebarNav = ({ children, activeRoute }: SidebarNavProps) => {
    const { base, secondary } = sidebarNavStyles();
    return (
        <Menu orientation="vertical" className="flex h-full divide-x divide-surface-divider" value={activeRoute}>
            <div className={base()}>{children}</div>
            <Menu.Viewport forceMount className={secondary()} />
        </Menu>
    );
};
