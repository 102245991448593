import type { Obj } from '../../typescript';
import { makeStringTemplate } from '../../utils/string/make-string-template';

type DefineEndpointsShape = {
    GET?: Obj<unknown>;
    POST?: Obj<unknown>;
    PUT?: Obj<unknown>;
    DELETE?: Obj<unknown>;
    PATCH?: Obj<unknown>;
};

/**
 *
 * @example
 * ```typescript
 *  const USER_ENDPOINTS = defineEndpoints((template) => ({
 *    GET_USER: template('/api/users/{userId}'),
 *    UPDATE: template('/api/users/{userId}'),
 *    SIGNUP: '/api/users/signup' as const, // or template('/api/users/signup')
 *  }));
 * ```
 */
const defineEndpoints = <T extends DefineEndpointsShape>(
    endpoints: (makePathTemplate: typeof makeStringTemplate) => T,
) => {
    return endpoints(makeStringTemplate);
};

export { defineEndpoints };
