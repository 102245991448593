import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

const logoStyles = tVariants(
    {
        base: 'fill-content',
        variants: {
            display: factoryStyleProps.layout.display,
        },
    },
    { responsiveVariants: true },
);

export { logoStyles };
