import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const GasolinePumpIcon = forwardRef(
    ({ title, titleId, borderRadius, ...props }: IconProps, ref?: LegacyRef<SVGSVGElement>) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width={props.width}
            height={props.height}
            role="img"
            aria-hidden="true"
            className={styles.icon({
                ...props,
                borderRadius,
            })}
            fill="none"
            ref={ref}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fill="currentColor"
                d="m14.38 4.991.004-.003a.012.012 0 0 0 0-.017l-2.826-2.742a.19.19 0 0 0-.207-.04.2.2 0 0 0-.086.068.2.2 0 0 0-.033.103v.881a1 1 0 0 0 .303.717l.285.277c.194.189.32.452.255.713-.086.344-.257.537-.257 1.196 0 .31.476 1.193 1.046 1.741.397.383.863.906.863 1.459v3.094a.84.84 0 0 1-.849.825.86.86 0 0 1-.6-.243.82.82 0 0 1-.248-.583V9.65c0-.91-.76-1.65-1.697-1.65a.85.85 0 0 1-.85-.85v-4c.003-.911-.756-1.65-1.693-1.65H2.697C1.76 1.5 1 2.24 1 3.151V13.5a1 1 0 0 0 1 1h6.485a1 1 0 0 0 1-1V9.874a.636.636 0 0 1 1.272 0v2.563c.012.54.24 1.053.637 1.431s.93.59 1.485.59 1.087-.212 1.484-.59.625-.892.637-1.431V6.452a2.04 2.04 0 0 0-.62-1.46ZM7.788 6.278a1 1 0 0 1-1 1h-3.09a1 1 0 0 1-1-1V4.15a1 1 0 0 1 1-1h3.09a1 1 0 0 1 1 1z"
            />
        </svg>
    ),
);
GasolinePumpIcon.displayName = 'GasolinePumpIcon';
export default GasolinePumpIcon;
