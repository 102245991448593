import { uniqBy } from 'lodash-es';

import { ItemTypes } from './search.enums';
import { RecentlyViewedItem, SearchHit } from './search.types';

export const getRecentlyViewedHits = (): RecentlyViewedItem[] => {
    const recentlyViewed = localStorage.getItem('recently-viewed');
    return recentlyViewed ? JSON.parse(recentlyViewed) : [];
};

export const addRecentlyViewedHit = (hit: SearchHit, itemType: ItemTypes) => {
    const recentlyViewed = getRecentlyViewedHits();
    const hitItem = {
        hit,
        type: itemType,
    } as RecentlyViewedItem;
    const uniqItems = uniqBy([hitItem, ...recentlyViewed], 'hit.objectID');
    const updatedRecentlyViewed = uniqItems.slice(0, 5);
    localStorage.setItem('recently-viewed', JSON.stringify(updatedRecentlyViewed));
};
