import { StringUnion } from '@blockworks/platform/typescript';

type Booleanish = boolean | 'true' | 'false';

const dataAttr = (condition?: boolean) => (condition ? '' : undefined) as Booleanish;
const ariaAttr = (condition?: boolean) => (condition ? true : undefined);

type AttrValues = StringUnion<
    | 'active'
    | 'selected'
    | 'checked'
    | 'truncated'
    | 'open'
    | 'closed'
    | 'show-on-mobile'
    | 'hide-on-mobile'
    | 'true'
    | 'false'
>;

type AttrType = 'data' | 'aria';

type SafeAttrOpts = {
    /**
     * The fallback value to use if the condition is false.
     * If no fallback value is provided, the attribute will be removed and an empty object will be returned.
     * @default undefined
     */
    fallbackValue?: AttrValues | boolean;
    /**
     * The prefix to use for the attribute
     * @default 'data'
     */
    prefix?: AttrType;
    /** The suffix to use for the attribute
     * @default 'state'
     */
    suffix?: string;
};
/**
 * @name safeAttr
 * @summary Returns a predictable object with a safe `data || aria` attribute for easy prop spreading.
 * @description If the condition is `true` a data attribute is returned like so `{ 'data-state': value }`.
 * An empty object is returned when the condition is falsey, so the attribute can be safely spread on components.
 * Given a `fallbackValue`, that value is used instead of returning an empty object.
 */
const safeAttr = (
    condition: boolean | undefined,
    value: AttrValues | undefined,
    opts: SafeAttrOpts = {},
): Record<string, any> => {
    const dataAttr = `${opts?.prefix ?? 'data'}-${opts?.suffix ?? 'state'}` as const;

    if (condition) {
        return { [dataAttr]: value };
    }
    if (opts?.fallbackValue) {
        return { [dataAttr]: opts.fallbackValue };
    }

    return {};
};

export { ariaAttr, dataAttr, safeAttr };
