import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const SocialXIcon = forwardRef(
    ({ title, titleId, borderRadius, ...props }: IconProps, ref?: LegacyRef<SVGSVGElement>) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            className={styles.icon({
                ...props,
                borderRadius,
            })}
            viewBox="0 0 16 16"
            width={props.width}
            height={props.height}
            role="img"
            aria-hidden="true"
            ref={ref}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fill="currentColor"
                d="M12 1.7h2.2L9.5 7l5.5 7.3h-4.3L7.3 10l-3.9 4.4H1.3l5-5.7-5.3-7h4.4l3 4.1 3.6-4ZM11.3 13h1.2L4.8 3H3.5z"
            />
        </svg>
    ),
);
SocialXIcon.displayName = 'SocialXIcon';
export default SocialXIcon;
