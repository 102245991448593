import React, { forwardRef } from 'react';
import { DialogTitle } from '@radix-ui/react-dialog';

import { Text, TextProps } from '../../text';

type ModalTitleProps = React.PropsWithChildren<{ visuallyHidden?: boolean } & TextProps>;

const ModalTitle = forwardRef<HTMLDivElement, ModalTitleProps>(function ModalTitle(
    { children, visuallyHidden, ...rest },
    ref,
) {
    if (visuallyHidden) return <DialogTitle className="sr-only">{children}</DialogTitle>;

    return (
        <>
            <DialogTitle asChild>
                <Text as="span" size="lg" weight="semibold" ref={ref} {...rest}>
                    {children}
                </Text>
            </DialogTitle>
        </>
    );
});

export type { ModalTitleProps };
export { ModalTitle };
