import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

export const icon = tVariants({
    base: 'inline-block align-text-top',
    variants: {
        size: {
            1: 'w-4 h-4',
            1.5: 'w-6 h-6',
            2: 'w-8 h-8',
            3: 'w-12 h-12',
            4: 'w-16 h-16',
            5: 'w-20 h-20',
        },
        borderRadius: factoryStyleProps.border.borderRadius,
        ...factoryStyleProps.__atoms.layout.flex,
        ...factoryStyleProps.__atoms.space.margin,
        ...factoryStyleProps.color,
        ...factoryStyleProps.size,
    },
});
