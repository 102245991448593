import { tVariants } from '../../../utils/t-variants';

const tvSpace = tVariants(
    {
        variants: {
            gap: {
                0: 'gap-0',
                0.5: 'gap-0.5',
                1: 'gap-1',
                2: 'gap-2',
                3: 'gap-3',
                4: 'gap-4',
                5: 'gap-5',
                6: 'gap-6',
                7: 'gap-7',
                8: 'gap-8',
                9: 'gap-9',
                10: 'gap-10',
            },

            p: {
                0: 'p-0',
                0.5: 'p-0.5',
                1: 'p-1',
                2: 'p-2',
                3: 'p-3',
                4: 'p-4',
                5: 'p-5',
                6: 'p-6',
                7: 'p-8',
                8: 'p-8',
            },
            px: {
                0: 'px-0',
                0.5: 'px-0.5',
                1: 'px-1',
                2: 'px-2',
                3: 'px-3',
                4: 'px-4',
                5: 'px-5',
                6: 'px-6',
                7: 'px-8',
                8: 'px-8',
            },
            py: {
                0: 'py-0',
                0.5: 'py-0.5',
                1: 'py-1',
                2: 'py-2',
                3: 'py-3',
                4: 'py-4',
                5: 'py-5',
                6: 'py-6',
                7: 'py-8',
                8: 'py-8',
            },
            pt: {
                0: 'pt-0',
                0.5: 'pt-0.5',
                1: 'pt-1',
                2: 'pt-2',
                3: 'pt-3',
                4: 'pt-4',
                5: 'pt-5',
                6: 'pt-6',
                7: 'pt-8',
                8: 'pt-8',
            },
            pb: {
                0: 'pb-0',
                0.5: 'pb-0.5',
                1: 'pb-1',
                2: 'pb-2',
                3: 'pb-3',
                4: 'pb-4',
                5: 'pb-5',
                6: 'pb-6',
                7: 'pb-7',
                8: 'pb-8',
            },
            pl: {
                0: 'pl-0',
                0.5: 'pl-0.5',
                1: 'pl-1',
                2: 'pl-2',
                3: 'pl-3',
                4: 'pl-4',
                5: 'pl-5',
                6: 'pl-6',
                7: 'pl-7',
                8: 'pl-8',
            },
            pr: {
                0: 'pr-0',
                0.5: 'pr-0.5',
                1: 'pr-1',
                2: 'pr-2',
                3: 'pr-3',
                4: 'pr-4',
                5: 'pr-5',
                6: 'pr-6',
                7: 'pr-7',
                8: 'pr-8',
            },

            m: {
                0: 'm-0',
                1: 'm-1',
                2: 'm-2',
                3: 'm-3',
                4: 'm-4',
                5: 'm-5',
                6: 'm-6',
                7: 'm-7',
                8: 'm-8',
                auto: 'm-auto',
            },
            mx: {
                0: 'mx-0',
                1: 'mx-1',
                2: 'mx-2',
                3: 'mx-3',
                4: 'mx-4',
                5: 'mx-5',
                6: 'mx-6',
                7: 'mx-7',
                8: 'mx-8',
                auto: 'mx-auto',
            },
            my: {
                0: 'my-0',
                1: 'my-1',
                2: 'my-2',
                3: 'my-3',
                4: 'my-4',
                5: 'my-5',
                6: 'my-5',
                7: 'mt-7',
                8: 'mt-8',
                auto: 'my-auto',
            },
            mt: {
                0: 'mt-0',
                1: 'mt-1',
                2: 'mt-2',
                3: 'mt-3',
                4: 'mt-4',
                5: 'mt-5',
                6: 'mt-6',
                7: 'mt-7',
                8: 'mt-8',
                auto: 'mt-auto',
            },
            mb: {
                0: 'mb-0',
                1: 'mb-1',
                2: 'mb-2',
                3: 'mb-3',
                4: 'mb-4',
                5: 'mb-5',
                6: 'mb-6',
                7: 'mb-7',
                8: 'mb-8',
                auto: 'mb-auto',
            },
            ml: {
                0: 'ml-0',
                1: 'ml-1',
                2: 'ml-2',
                3: 'ml-3',
                4: 'ml-4',
                5: 'ml-5',
                6: 'ml-6',
                7: 'ml-7',
                8: 'ml-8',
                auto: 'ml-auto',
            },
            mr: {
                0: 'mr-0',
                1: 'mr-1',
                2: 'mr-2',
                3: 'mr-3',
                4: 'mr-4',
                5: 'mr-5',
                6: 'mr-6',
                7: 'mr-7',
                8: 'mr-8',
                auto: 'mr-auto',
            },
        },
    },
    { responsiveVariants: true },
);

const space = tvSpace.variants;

const spaceAtoms = {
    margin: {
        m: space.m,
        mx: space.mx,
        my: space.my,
        mt: space.mt,
        mb: space.mb,
        ml: space.ml,
        mr: space.mr,
    },
    padding: {
        p: space.p,
        pt: space.pt,
        pr: space.pr,
        pb: space.pb,
        pl: space.pl,
        py: space.py,
        px: space.px,
    },
};
export { space, spaceAtoms };
