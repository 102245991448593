import { createContext as createReactContext, useContext as useReactContext } from 'react';

type CreateContextOptions<T> = {
    strict?: boolean;
    hookName?: string;
    providerName?: string;
    errorMessage?: string;
    name?: string;
    defaultValue?: T;
};

type CreateContextReturn<T> = [React.Provider<T>, () => T, React.Context<T>];

const getErrorMessage = (hook: string, provider: string) =>
    `${hook} returned \`undefined\`. Seems you forgot to wrap component within ${provider}`;

const createSafeContext = <T>(options: CreateContextOptions<T> = {}) => {
    const {
        name,
        strict = true,
        hookName = 'useContext',
        providerName = 'Provider',
        errorMessage,
        defaultValue,
    } = options;

    const Context = createReactContext<T | undefined>(defaultValue);

    Context.displayName = name;

    const useContext = () => {
        const context = useReactContext(Context);

        if (!context && strict) {
            const error = new Error(errorMessage ?? getErrorMessage(hookName, providerName));
            error.name = 'ContextError';
            Error.captureStackTrace?.(error, useContext);
            throw error;
        }

        return context;
    };

    return [Context.Provider, useContext, Context] as CreateContextReturn<T>;
};

export type { CreateContextOptions, CreateContextReturn };
export { createSafeContext };
