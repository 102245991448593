export type AssetSymbol = keyof typeof logos;

export const logos = {
    $pac: '/images/tokens/$pac.svg',
    btu: '/images/tokens/btu.svg',
    eca: '/images/tokens/eca.svg',
    hush: '/images/tokens/hush.svg',
    mwc: '/images/tokens/mwc.svg',
    rari: '/images/tokens/rari.svg',
    trig: '/images/tokens/trig.svg',
    '0xbtc': '/images/tokens/0xbtc.svg',
    btx: '/images/tokens/btx.svg',
    edg: '/images/tokens/edg.svg',
    hvn: '/images/tokens/hvn.svg',
    mxm: '/images/tokens/mxm.svg',
    ray: '/images/tokens/ray.svg',
    trtl: '/images/tokens/trtl.svg',
    '1inch': '/images/tokens/1inch.svg',
    bu: '/images/tokens/bu.svg',
    edo: '/images/tokens/edo.svg',
    hydro: '/images/tokens/hydro.svg',
    myb: '/images/tokens/myb.svg',
    rbn: '/images/tokens/rbn.svg',
    tru: '/images/tokens/tru.svg',
    '1st': '/images/tokens/1st.svg',
    bunny: '/images/tokens/bunny.svg',
    edoge: '/images/tokens/edoge.svg',
    hyn: '/images/tokens/hyn.svg',
    mzc: '/images/tokens/mzc.svg',
    rcn: '/images/tokens/rcn.svg',
    trx: '/images/tokens/trx.svg',
    '2give': '/images/tokens/2give.svg',
    burst: '/images/tokens/burst.svg',
    efi: '/images/tokens/efi.svg',
    hzn: '/images/tokens/hzn.svg',
    nano: '/images/tokens/nano.svg',
    rdd: '/images/tokens/rdd.svg',
    tryb: '/images/tokens/tryb.svg',
    aave: '/images/tokens/aave.svg',
    busd: '/images/tokens/busd.svg',
    efx: '/images/tokens/efx.svg',
    ibat: '/images/tokens/ibat.svg',
    nas: '/images/tokens/nas.svg',
    rdn: '/images/tokens/rdn.svg',
    ttc: '/images/tokens/ttc.svg',
    abbc: '/images/tokens/abbc.svg',
    bwt: '/images/tokens/bwt.svg',
    egld: '/images/tokens/egld.svg',
    icn: '/images/tokens/icn.svg',
    nav: '/images/tokens/nav.svg',
    ren: '/images/tokens/ren.svg',
    ttt: '/images/tokens/ttt.svg',
    abt: '/images/tokens/abt.svg',
    bz: '/images/tokens/bz.svg',
    egt: '/images/tokens/egt.svg',
    icp: '/images/tokens/icp.svg',
    ncash: '/images/tokens/ncash.svg',
    renbtc: '/images/tokens/renbtc.svg',
    tube: '/images/tokens/tube.svg',
    ac3: '/images/tokens/ac3.svg',
    bze: '/images/tokens/bze.svg',
    ekg: '/images/tokens/ekg.svg',
    icx: '/images/tokens/icx.svg',
    nct: '/images/tokens/nct.svg',
    rep: '/images/tokens/rep.svg',
    tusd: '/images/tokens/tusd.svg',
    act: '/images/tokens/act.svg',
    bznt: '/images/tokens/bznt.svg',
    ekt: '/images/tokens/ekt.svg',
    idai: '/images/tokens/idai.svg',
    ndz: '/images/tokens/ndz.svg',
    repv2: '/images/tokens/repv2.svg',
    twt: '/images/tokens/twt.svg',
    actn: '/images/tokens/actn.svg',
    bzrx: '/images/tokens/bzrx.svg',
    ela: '/images/tokens/ela.svg',
    idex: '/images/tokens/idex.svg',
    near: '/images/tokens/near.svg',
    req: '/images/tokens/req.svg',
    tzc: '/images/tokens/tzc.svg',
    ada: '/images/tokens/ada.svg',
    c20: '/images/tokens/c20.svg',
    elec: '/images/tokens/elec.svg',
    ieth: '/images/tokens/ieth.svg',
    nebl: '/images/tokens/nebl.svg',
    rev: '/images/tokens/rev.svg',
    ubq: '/images/tokens/ubq.svg',
    adb: '/images/tokens/adb.svg',
    c98: '/images/tokens/c98.svg',
    elf: '/images/tokens/elf.svg',
    ignis: '/images/tokens/ignis.svg',
    nec: '/images/tokens/nec.svg',
    rfox: '/images/tokens/rfox.svg',
    ubt: '/images/tokens/ubt.svg',
    add: '/images/tokens/add.svg',
    cake: '/images/tokens/cake.svg',
    elix: '/images/tokens/elix.svg',
    iknc: '/images/tokens/iknc.svg',
    neo: '/images/tokens/neo.svg',
    rhoc: '/images/tokens/rhoc.svg',
    uft: '/images/tokens/uft.svg',
    adx: '/images/tokens/adx.svg',
    call: '/images/tokens/call.svg',
    ella: '/images/tokens/ella.svg',
    ilink: '/images/tokens/ilink.svg',
    neos: '/images/tokens/neos.svg',
    ric: '/images/tokens/ric.svg',
    ult: '/images/tokens/ult.svg',
    ae: '/images/tokens/ae.svg',
    capp: '/images/tokens/capp.svg',
    emb: '/images/tokens/emb.svg',
    ilk: '/images/tokens/ilk.svg',
    neu: '/images/tokens/neu.svg',
    rif: '/images/tokens/rif.svg',
    uma: '/images/tokens/uma.svg',
    aeon: '/images/tokens/aeon.svg',
    car: '/images/tokens/car.svg',
    emc: '/images/tokens/emc.svg',
    imx: '/images/tokens/imx.svg',
    new: '/images/tokens/new.svg',
    rise: '/images/tokens/rise.svg',
    uncx: '/images/tokens/uncx.svg',
    aergo: '/images/tokens/aergo.svg',
    card: '/images/tokens/card.svg',
    emc2: '/images/tokens/emc2.svg',
    inb: '/images/tokens/inb.svg',
    nex: '/images/tokens/nex.svg',
    rlc: '/images/tokens/rlc.svg',
    unfi: '/images/tokens/unfi.svg',
    aeur: '/images/tokens/aeur.svg',
    cbat: '/images/tokens/cbat.svg',
    eng: '/images/tokens/eng.svg',
    inj: '/images/tokens/inj.svg',
    nexo: '/images/tokens/nexo.svg',
    rndr: '/images/tokens/rndr.svg',
    uni: '/images/tokens/uni.svg',
    agi: '/images/tokens/agi.svg',
    cbc: '/images/tokens/cbc.svg',
    enj: '/images/tokens/enj.svg',
    ink: '/images/tokens/ink.svg',
    nexxo: '/images/tokens/nexxo.svg',
    rook: '/images/tokens/rook.svg',
    unity: '/images/tokens/unity.svg',
    agrs: '/images/tokens/agrs.svg',
    cbt: '/images/tokens/cbt.svg',
    ens: '/images/tokens/ens.svg',
    ins: '/images/tokens/ins.svg',
    nft: '/images/tokens/nft.svg',
    rose: '/images/tokens/rose.svg',
    unn: '/images/tokens/unn.svg',
    aion: '/images/tokens/aion.svg',
    cc: '/images/tokens/cc.svg',
    entrp: '/images/tokens/entrp.svg',
    ion: '/images/tokens/ion.svg',
    ngc: '/images/tokens/ngc.svg',
    rpx: '/images/tokens/rpx.svg',
    uos: '/images/tokens/uos.svg',
    ait: '/images/tokens/ait.svg',
    cccx: '/images/tokens/cccx.svg',
    eon: '/images/tokens/eon.svg',
    iop: '/images/tokens/iop.svg',
    nio: '/images/tokens/nio.svg',
    rsr: '/images/tokens/rsr.svg',
    upp: '/images/tokens/upp.svg',
    akro: '/images/tokens/akro.svg',
    cdai: '/images/tokens/cdai.svg',
    eop: '/images/tokens/eop.svg',
    iost: '/images/tokens/iost.svg',
    niox: '/images/tokens/niox.svg',
    rsv: '/images/tokens/rsv.svg',
    usd: '/images/tokens/usd.svg',
    akt: '/images/tokens/akt.svg',
    cdn: '/images/tokens/cdn.svg',
    eos: '/images/tokens/eos.svg',
    iota: '/images/tokens/iota.svg',
    nkn: '/images/tokens/nkn.svg',
    rub: '/images/tokens/rub.svg',
    usdc: '/images/tokens/usdc.svg',
    alcx: '/images/tokens/alcx.svg',
    cdt: '/images/tokens/cdt.svg',
    eosdac: '/images/tokens/eosdac.svg',
    iotx: '/images/tokens/iotx.svg',
    nlc2: '/images/tokens/nlc2.svg',
    rune: '/images/tokens/rune.svg',
    usdn: '/images/tokens/usdn.svg',
    aleph: '/images/tokens/aleph.svg',
    cel: '/images/tokens/cel.svg',
    eqli: '/images/tokens/eqli.svg',
    iq: '/images/tokens/iq.svg',
    nlg: '/images/tokens/nlg.svg',
    rvn: '/images/tokens/rvn.svg',
    usdp: '/images/tokens/usdp.svg',
    algo: '/images/tokens/algo.svg',
    celo: '/images/tokens/celo.svg',
    equa: '/images/tokens/equa.svg',
    irep: '/images/tokens/irep.svg',
    nmc: '/images/tokens/nmc.svg',
    ryo: '/images/tokens/ryo.svg',
    usds: '/images/tokens/usds.svg',
    alis: '/images/tokens/alis.svg',
    celr: '/images/tokens/celr.svg',
    erd: '/images/tokens/erd.svg',
    iris: '/images/tokens/iris.svg',
    nmr: '/images/tokens/nmr.svg',
    s: '/images/tokens/s.svg',
    usdt: '/images/tokens/usdt.svg',
    alpha: '/images/tokens/alpha.svg',
    cennz: '/images/tokens/cennz.svg',
    ern: '/images/tokens/ern.svg',
    isusd: '/images/tokens/isusd.svg',
    noia: '/images/tokens/noia.svg',
    safe: '/images/tokens/safe.svg',
    ust: '/images/tokens/ust.svg',
    alx: '/images/tokens/alx.svg',
    cenz: '/images/tokens/cenz.svg',
    esd: '/images/tokens/esd.svg',
    itc: '/images/tokens/itc.svg',
    nper: '/images/tokens/nper.svg',
    safemoon: '/images/tokens/safemoon.svg',
    utk: '/images/tokens/utk.svg',
    amb: '/images/tokens/amb.svg',
    ceth: '/images/tokens/ceth.svg',
    esp: '/images/tokens/esp.svg',
    iusdc: '/images/tokens/iusdc.svg',
    npxs: '/images/tokens/npxs.svg',
    sai: '/images/tokens/sai.svg',
    uuu: '/images/tokens/uuu.svg',
    amlt: '/images/tokens/amlt.svg',
    cfx: '/images/tokens/cfx.svg',
    ess: '/images/tokens/ess.svg',
    iwbtc: '/images/tokens/iwbtc.svg',
    nrg: '/images/tokens/nrg.svg',
    salt: '/images/tokens/salt.svg',
    value: '/images/tokens/value.svg',
    amp: '/images/tokens/amp.svg',
    chai: '/images/tokens/chai.svg',
    etc: '/images/tokens/etc.svg',
    izrx: '/images/tokens/izrx.svg',
    nrve: '/images/tokens/nrve.svg',
    san: '/images/tokens/san.svg',
    velo: '/images/tokens/velo.svg',
    ampl: '/images/tokens/ampl.svg',
    chain: '/images/tokens/chain.svg',
    eth: '/images/tokens/eth.svg',
    jet: '/images/tokens/jet.svg',
    ntbc: '/images/tokens/ntbc.svg',
    sand: '/images/tokens/sand.svg',
    veri: '/images/tokens/veri.svg',
    anc: '/images/tokens/anc.svg',
    chat: '/images/tokens/chat.svg',
    ethdydx: '/images/tokens/ethdydx.svg',
    jnt: '/images/tokens/jnt.svg',
    nu: '/images/tokens/nu.svg',
    sar: '/images/tokens/sar.svg',
    vest: '/images/tokens/vest.svg',
    anj: '/images/tokens/anj.svg',
    chips: '/images/tokens/chips.svg',
    ethos: '/images/tokens/ethos.svg',
    joe: '/images/tokens/joe.svg',
    nuls: '/images/tokens/nuls.svg',
    sbd: '/images/tokens/sbd.svg',
    vet: '/images/tokens/vet.svg',
    ankr: '/images/tokens/ankr.svg',
    chr: '/images/tokens/chr.svg',
    etn: '/images/tokens/etn.svg',
    jpy: '/images/tokens/jpy.svg',
    nxs: '/images/tokens/nxs.svg',
    sberbank: '/images/tokens/sberbank.svg',
    vgx: '/images/tokens/vgx.svg',
    ant: '/images/tokens/ant.svg',
    chsb: '/images/tokens/chsb.svg',
    etp: '/images/tokens/etp.svg',
    jrt: '/images/tokens/jrt.svg',
    nxt: '/images/tokens/nxt.svg',
    sc: '/images/tokens/sc.svg',
    via: '/images/tokens/via.svg',
    apex: '/images/tokens/apex.svg',
    chz: '/images/tokens/chz.svg',
    etz: '/images/tokens/etz.svg',
    jst: '/images/tokens/jst.svg',
    oag: '/images/tokens/oag.svg',
    scrl: '/images/tokens/scrl.svg',
    vib: '/images/tokens/vib.svg',
    aph: '/images/tokens/aph.svg',
    cix: '/images/tokens/cix.svg',
    eur: '/images/tokens/eur.svg',
    juno: '/images/tokens/juno.svg',
    oax: '/images/tokens/oax.svg',
    scrt: '/images/tokens/scrt.svg',
    vibe: '/images/tokens/vibe.svg',
    apl: '/images/tokens/apl.svg',
    ckb: '/images/tokens/ckb.svg',
    evx: '/images/tokens/evx.svg',
    kava: '/images/tokens/kava.svg',
    ocean: '/images/tokens/ocean.svg',
    sdt: '/images/tokens/sdt.svg',
    vidt: '/images/tokens/vidt.svg',
    appc: '/images/tokens/appc.svg',
    clam: '/images/tokens/clam.svg',
    ewt: '/images/tokens/ewt.svg',
    kcs: '/images/tokens/kcs.svg',
    ocn: '/images/tokens/ocn.svg',
    seele: '/images/tokens/seele.svg',
    vikky: '/images/tokens/vikky.svg',
    apw: '/images/tokens/apw.svg',
    clo: '/images/tokens/clo.svg',
    exmo: '/images/tokens/exmo.svg',
    kda: '/images/tokens/kda.svg',
    ode: '/images/tokens/ode.svg',
    sefi: '/images/tokens/sefi.svg',
    vin: '/images/tokens/vin.svg',
    apy: '/images/tokens/apy.svg',
    cloak: '/images/tokens/cloak.svg',
    exp: '/images/tokens/exp.svg',
    keep: '/images/tokens/keep.svg',
    ogn: '/images/tokens/ogn.svg',
    sem: '/images/tokens/sem.svg',
    vite: '/images/tokens/vite.svg',
    ar: '/images/tokens/ar.svg',
    clout: '/images/tokens/clout.svg',
    exrn: '/images/tokens/exrn.svg',
    key: '/images/tokens/key.svg',
    ohm: '/images/tokens/ohm.svg',
    sfi: '/images/tokens/sfi.svg',
    viu: '/images/tokens/viu.svg',
    arb: '/images/tokens/arb.svg',
    cmm: '/images/tokens/cmm.svg',
    exy: '/images/tokens/exy.svg',
    kick: '/images/tokens/kick.svg',
    ok: '/images/tokens/ok.svg',
    sfp: '/images/tokens/sfp.svg',
    vivo: '/images/tokens/vivo.svg',
    ardr: '/images/tokens/ardr.svg',
    cmt: '/images/tokens/cmt.svg',
    fab: '/images/tokens/fab.svg',
    kin: '/images/tokens/kin.svg',
    okb: '/images/tokens/okb.svg',
    shib: '/images/tokens/shib.svg',
    vlx: '/images/tokens/vlx.svg',
    arg: '/images/tokens/arg.svg',
    cnd: '/images/tokens/cnd.svg',
    fair: '/images/tokens/fair.svg',
    klay: '/images/tokens/klay.svg',
    okt: '/images/tokens/okt.svg',
    shift: '/images/tokens/shift.svg',
    vrc: '/images/tokens/vrc.svg',
    ark: '/images/tokens/ark.svg',
    cnx: '/images/tokens/cnx.svg',
    fct: '/images/tokens/fct.svg',
    klown: '/images/tokens/klown.svg',
    olt: '/images/tokens/olt.svg',
    shr: '/images/tokens/shr.svg',
    vrs: '/images/tokens/vrs.svg',
    armor: '/images/tokens/armor.svg',
    cny: '/images/tokens/cny.svg',
    feed: '/images/tokens/feed.svg',
    klv: '/images/tokens/klv.svg',
    omg: '/images/tokens/omg.svg',
    shuf: '/images/tokens/shuf.svg',
    vrsc: '/images/tokens/vrsc.svg',
    arn: '/images/tokens/arn.svg',
    cob: '/images/tokens/cob.svg',
    fei: '/images/tokens/fei.svg',
    kmd: '/images/tokens/kmd.svg',
    omni: '/images/tokens/omni.svg',
    sia: '/images/tokens/sia.svg',
    vsp: '/images/tokens/vsp.svg',
    arnx: '/images/tokens/arnx.svg',
    colx: '/images/tokens/colx.svg',
    fet: '/images/tokens/fet.svg',
    knc: '/images/tokens/knc.svg',
    one: '/images/tokens/one.svg',
    sib: '/images/tokens/sib.svg',
    vsys: '/images/tokens/vsys.svg',
    aro: '/images/tokens/aro.svg',
    comp: '/images/tokens/comp.svg',
    fida: '/images/tokens/fida.svg',
    krb: '/images/tokens/krb.svg',
    ong: '/images/tokens/ong.svg',
    sin: '/images/tokens/sin.svg',
    vtc: '/images/tokens/vtc.svg',
    arrr: '/images/tokens/arrr.svg',
    coni: '/images/tokens/coni.svg',
    fil: '/images/tokens/fil.svg',
    ksm: '/images/tokens/ksm.svg',
    onion: '/images/tokens/onion.svg',
    skl: '/images/tokens/skl.svg',
    vtho: '/images/tokens/vtho.svg',
    ary: '/images/tokens/ary.svg',
    coqui: '/images/tokens/coqui.svg',
    filda: '/images/tokens/filda.svg',
    kyl: '/images/tokens/kyl.svg',
    ont: '/images/tokens/ont.svg',
    sky: '/images/tokens/sky.svg',
    wabi: '/images/tokens/wabi.svg',
    ast: '/images/tokens/ast.svg',
    cosm: '/images/tokens/cosm.svg',
    fjc: '/images/tokens/fjc.svg',
    lamb: '/images/tokens/lamb.svg',
    oot: '/images/tokens/oot.svg',
    slr: '/images/tokens/slr.svg',
    wan: '/images/tokens/wan.svg',
    astro: '/images/tokens/astro.svg',
    coti: '/images/tokens/coti.svg',
    fldc: '/images/tokens/fldc.svg',
    land: '/images/tokens/land.svg',
    op: '/images/tokens/op.svg',
    sls: '/images/tokens/sls.svg',
    waves: '/images/tokens/waves.svg',
    atm: '/images/tokens/atm.svg',
    cov: '/images/tokens/cov.svg',
    flo: '/images/tokens/flo.svg',
    lba: '/images/tokens/lba.svg',
    open: '/images/tokens/open.svg',
    slt: '/images/tokens/slt.svg',
    wax: '/images/tokens/wax.svg',
    atmi: '/images/tokens/atmi.svg',
    cova: '/images/tokens/cova.svg',
    flow: '/images/tokens/flow.svg',
    lbc: '/images/tokens/lbc.svg',
    opium: '/images/tokens/opium.svg',
    smart: '/images/tokens/smart.svg',
    waxp: '/images/tokens/waxp.svg',
    atom: '/images/tokens/atom.svg',
    cover: '/images/tokens/cover.svg',
    flux: '/images/tokens/flux.svg',
    lcx: '/images/tokens/lcx.svg',
    orbs: '/images/tokens/orbs.svg',
    snc: '/images/tokens/snc.svg',
    wbtc: '/images/tokens/wbtc.svg',
    auc: '/images/tokens/auc.svg',
    cpc: '/images/tokens/cpc.svg',
    fota: '/images/tokens/fota.svg',
    ldo: '/images/tokens/ldo.svg',
    orc: '/images/tokens/orc.svg',
    sngls: '/images/tokens/sngls.svg',
    wct: '/images/tokens/wct.svg',
    audio: '/images/tokens/audio.svg',
    cpx: '/images/tokens/cpx.svg',
    fox: '/images/tokens/fox.svg',
    lend: '/images/tokens/lend.svg',
    orn: '/images/tokens/orn.svg',
    snm: '/images/tokens/snm.svg',
    wexpoly: '/images/tokens/wexpoly.svg',
    audr: '/images/tokens/audr.svg',
    cre: '/images/tokens/cre.svg',
    frax: '/images/tokens/frax.svg',
    leo: '/images/tokens/leo.svg',
    osmo: '/images/tokens/osmo.svg',
    snt: '/images/tokens/snt.svg',
    wgr: '/images/tokens/wgr.svg',
    aura: '/images/tokens/aura.svg',
    cream: '/images/tokens/cream.svg',
    frm: '/images/tokens/frm.svg',
    link: '/images/tokens/link.svg',
    ost: '/images/tokens/ost.svg',
    snx: '/images/tokens/snx.svg',
    whale: '/images/tokens/whale.svg',
    auto: '/images/tokens/auto.svg',
    cred: '/images/tokens/cred.svg',
    front: '/images/tokens/front.svg',
    lit: '/images/tokens/lit.svg',
    ovc: '/images/tokens/ovc.svg',
    soc: '/images/tokens/soc.svg',
    wib: '/images/tokens/wib.svg',
    avax: '/images/tokens/avax.svg',
    crep: '/images/tokens/crep.svg',
    fsn: '/images/tokens/fsn.svg',
    lkk: '/images/tokens/lkk.svg',
    ox: '/images/tokens/ox.svg',
    sol: '/images/tokens/sol.svg',
    wicc: '/images/tokens/wicc.svg',
    awc: '/images/tokens/awc.svg',
    cro: '/images/tokens/cro.svg',
    ft: '/images/tokens/ft.svg',
    lky: '/images/tokens/lky.svg',
    oxt: '/images/tokens/oxt.svg',
    soul: '/images/tokens/soul.svg',
    wing: '/images/tokens/wing.svg',
    axp: '/images/tokens/axp.svg',
    crpt: '/images/tokens/crpt.svg',
    ftc: '/images/tokens/ftc.svg',
    ln: '/images/tokens/ln.svg',
    pBTC: '/images/tokens/pBTC.svg',
    sov: '/images/tokens/sov.svg',
    wings: '/images/tokens/wings.svg',
    axs: '/images/tokens/axs.svg',
    crv: '/images/tokens/crv.svg',
    ftm: '/images/tokens/ftm.svg',
    loki: '/images/tokens/loki.svg',
    pai: '/images/tokens/pai.svg',
    spacehbit: '/images/tokens/spacehbit.svg',
    wld: '/images/tokens/wld.svg',
    aywa: '/images/tokens/aywa.svg',
    crw: '/images/tokens/crw.svg',
    ftt: '/images/tokens/ftt.svg',
    lon: '/images/tokens/lon.svg',
    paint: '/images/tokens/paint.svg',
    spank: '/images/tokens/spank.svg',
    woo: '/images/tokens/woo.svg',
    bab: '/images/tokens/bab.svg',
    cs: '/images/tokens/cs.svg',
    fuel: '/images/tokens/fuel.svg',
    looks: '/images/tokens/looks.svg',
    pal: '/images/tokens/pal.svg',
    spell: '/images/tokens/spell.svg',
    wpr: '/images/tokens/wpr.svg',
    bac: '/images/tokens/bac.svg',
    csai: '/images/tokens/csai.svg',
    fun: '/images/tokens/fun.svg',
    loom: '/images/tokens/loom.svg',
    par: '/images/tokens/par.svg',
    sphtx: '/images/tokens/sphtx.svg',
    wrc: '/images/tokens/wrc.svg',
    bal: '/images/tokens/bal.svg',
    csc: '/images/tokens/csc.svg',
    fxc: '/images/tokens/fxc.svg',
    lpt: '/images/tokens/lpt.svg',
    part: '/images/tokens/part.svg',
    spn: '/images/tokens/spn.svg',
    wrx: '/images/tokens/wrx.svg',
    bam: '/images/tokens/bam.svg',
    cspr: '/images/tokens/cspr.svg',
    fxs: '/images/tokens/fxs.svg',
    lqd: '/images/tokens/lqd.svg',
    pasc: '/images/tokens/pasc.svg',
    srm: '/images/tokens/srm.svg',
    wtc: '/images/tokens/wtc.svg',
    band: '/images/tokens/band.svg',
    ctc: '/images/tokens/ctc.svg',
    fxt: '/images/tokens/fxt.svg',
    lqty: '/images/tokens/lqty.svg',
    pasl: '/images/tokens/pasl.svg',
    srn: '/images/tokens/srn.svg',
    wxt: '/images/tokens/wxt.svg',
    bao: '/images/tokens/bao.svg',
    ctr: '/images/tokens/ctr.svg',
    gala: '/images/tokens/gala.svg',
    lrc: '/images/tokens/lrc.svg',
    pax: '/images/tokens/pax.svg',
    stak: '/images/tokens/stak.svg',
    x: '/images/tokens/x.svg',
    base: '/images/tokens/base.svg',
    ctxc: '/images/tokens/ctxc.svg',
    game: '/images/tokens/game.svg',
    lsk: '/images/tokens/lsk.svg',
    paxg: '/images/tokens/paxg.svg',
    stake: '/images/tokens/stake.svg',
    xas: '/images/tokens/xas.svg',
    bat: '/images/tokens/bat.svg',
    cube: '/images/tokens/cube.svg',
    gas: '/images/tokens/gas.svg',
    ltc: '/images/tokens/ltc.svg',
    pay: '/images/tokens/pay.svg',
    start: '/images/tokens/start.svg',
    xbc: '/images/tokens/xbc.svg',
    bay: '/images/tokens/bay.svg',
    cusdc: '/images/tokens/cusdc.svg',
    gbp: '/images/tokens/gbp.svg',
    lto: '/images/tokens/lto.svg',
    payx: '/images/tokens/payx.svg',
    steem: '/images/tokens/steem.svg',
    xbp: '/images/tokens/xbp.svg',
    bbr: '/images/tokens/bbr.svg',
    cv: '/images/tokens/cv.svg',
    gbx: '/images/tokens/gbx.svg',
    lun: '/images/tokens/lun.svg',
    pbr: '/images/tokens/pbr.svg',
    step: '/images/tokens/step.svg',
    xby: '/images/tokens/xby.svg',
    bcbc: '/images/tokens/bcbc.svg',
    cvc: '/images/tokens/cvc.svg',
    gbyte: '/images/tokens/gbyte.svg',
    luna: '/images/tokens/luna.svg',
    pendle: '/images/tokens/pendle.svg',
    steth: '/images/tokens/steth.svg',
    xch: '/images/tokens/xch.svg',
    bcc: '/images/tokens/bcc.svg',
    cvp: '/images/tokens/cvp.svg',
    gdc: '/images/tokens/gdc.svg',
    lunc: '/images/tokens/lunc.svg',
    perl: '/images/tokens/perl.svg',
    stg: '/images/tokens/stg.svg',
    xchf: '/images/tokens/xchf.svg',
    bcd: '/images/tokens/bcd.svg',
    cvt: '/images/tokens/cvt.svg',
    gem: '/images/tokens/gem.svg',
    lxt: '/images/tokens/lxt.svg',
    perp: '/images/tokens/perp.svg',
    stmx: '/images/tokens/stmx.svg',
    xcp: '/images/tokens/xcp.svg',
    bch: '/images/tokens/bch.svg',
    cvx: '/images/tokens/cvx.svg',
    gen: '/images/tokens/gen.svg',
    lym: '/images/tokens/lym.svg',
    pickle: '/images/tokens/pickle.svg',
    storj: '/images/tokens/storj.svg',
    xdb: '/images/tokens/xdb.svg',
    bcha: '/images/tokens/bcha.svg',
    cwbtc: '/images/tokens/cwbtc.svg',
    generic: '/images/tokens/generic.svg',
    maha: '/images/tokens/maha.svg',
    pink: '/images/tokens/pink.svg',
    storm: '/images/tokens/storm.svg',
    xdce: '/images/tokens/xdce.svg',
    bcio: '/images/tokens/bcio.svg',
    czrx: '/images/tokens/czrx.svg',
    gin: '/images/tokens/gin.svg',
    maid: '/images/tokens/maid.svg',
    pirl: '/images/tokens/pirl.svg',
    stox: '/images/tokens/stox.svg',
    xdn: '/images/tokens/xdn.svg',
    bcn: '/images/tokens/bcn.svg',
    d: '/images/tokens/d.svg',
    glxt: '/images/tokens/glxt.svg',
    man: '/images/tokens/man.svg',
    pivx: '/images/tokens/pivx.svg',
    stpt: '/images/tokens/stpt.svg',
    xem: '/images/tokens/xem.svg',
    bco: '/images/tokens/bco.svg',
    dadi: '/images/tokens/dadi.svg',
    gmr: '/images/tokens/gmr.svg',
    'mana-old': '/images/tokens/mana-old.svg',
    plDAI: '/images/tokens/plDAI.svg',
    stq: '/images/tokens/stq.svg',
    xhv: '/images/tokens/xhv.svg',
    bcpt: '/images/tokens/bcpt.svg',
    dafi: '/images/tokens/dafi.svg',
    gno: '/images/tokens/gno.svg',
    mana: '/images/tokens/mana.svg',
    plUSDC: '/images/tokens/plUSDC.svg',
    strat: '/images/tokens/strat.svg',
    xin: '/images/tokens/xin.svg',
    bdl: '/images/tokens/bdl.svg',
    dag: '/images/tokens/dag.svg',
    gns: '/images/tokens/gns.svg',
    mars: '/images/tokens/mars.svg',
    play: '/images/tokens/play.svg',
    stx: '/images/tokens/stx.svg',
    xlm: '/images/tokens/xlm.svg',
    beam: '/images/tokens/beam.svg',
    dai: '/images/tokens/dai.svg',
    gnt: '/images/tokens/gnt.svg',
    math: '/images/tokens/math.svg',
    plr: '/images/tokens/plr.svg',
    sub: '/images/tokens/sub.svg',
    xlq: '/images/tokens/xlq.svg',
    bel: '/images/tokens/bel.svg',
    dasc: '/images/tokens/dasc.svg',
    gnx: '/images/tokens/gnx.svg',
    matic: '/images/tokens/matic.svg',
    png: '/images/tokens/png.svg',
    sumo: '/images/tokens/sumo.svg',
    xmark: '/images/tokens/xmark.svg',
    bela: '/images/tokens/bela.svg',
    dash: '/images/tokens/dash.svg',
    go: '/images/tokens/go.svg',
    matter: '/images/tokens/matter.svg',
    pnk: '/images/tokens/pnk.svg',
    super: '/images/tokens/super.svg',
    xmcc: '/images/tokens/xmcc.svg',
    beta: '/images/tokens/beta.svg',
    dat: '/images/tokens/dat.svg',
    goc: '/images/tokens/goc.svg',
    max: '/images/tokens/max.svg',
    pnt: '/images/tokens/pnt.svg',
    suqa: '/images/tokens/suqa.svg',
    xmg: '/images/tokens/xmg.svg',
    bf: '/images/tokens/bf.svg',
    data: '/images/tokens/data.svg',
    gold: '/images/tokens/gold.svg',
    mbc: '/images/tokens/mbc.svg',
    poa: '/images/tokens/poa.svg',
    sushi: '/images/tokens/sushi.svg',
    xmo: '/images/tokens/xmo.svg',
    bifi: '/images/tokens/bifi.svg',
    datx: '/images/tokens/datx.svg',
    got: '/images/tokens/got.svg',
    mcap: '/images/tokens/mcap.svg',
    poe: '/images/tokens/poe.svg',
    suter: '/images/tokens/suter.svg',
    xmr: '/images/tokens/xmr.svg',
    bix: '/images/tokens/bix.svg',
    dbc: '/images/tokens/dbc.svg',
    grc: '/images/tokens/grc.svg',
    mco: '/images/tokens/mco.svg',
    pokt: '/images/tokens/pokt.svg',
    swap: '/images/tokens/swap.svg',
    xmx: '/images/tokens/xmx.svg',
    blcn: '/images/tokens/blcn.svg',
    dcc: '/images/tokens/dcc.svg',
    grin: '/images/tokens/grin.svg',
    mcx: '/images/tokens/mcx.svg',
    polis: '/images/tokens/polis.svg',
    swth: '/images/tokens/swth.svg',
    xmy: '/images/tokens/xmy.svg',
    blk: '/images/tokens/blk.svg',
    dcn: '/images/tokens/dcn.svg',
    grs: '/images/tokens/grs.svg',
    mda: '/images/tokens/mda.svg',
    pols: '/images/tokens/pols.svg',
    sxdt: '/images/tokens/sxdt.svg',
    xnk: '/images/tokens/xnk.svg',
    block: '/images/tokens/block.svg',
    dcr: '/images/tokens/dcr.svg',
    grt: '/images/tokens/grt.svg',
    mds: '/images/tokens/mds.svg',
    poly: '/images/tokens/poly.svg',
    sxp: '/images/tokens/sxp.svg',
    xns: '/images/tokens/xns.svg',
    blt: '/images/tokens/blt.svg',
    dct: '/images/tokens/dct.svg',
    gsc: '/images/tokens/gsc.svg',
    med: '/images/tokens/med.svg',
    pond: '/images/tokens/pond.svg',
    syn: '/images/tokens/syn.svg',
    xor: '/images/tokens/xor.svg',
    blue: '/images/tokens/blue.svg',
    ddd: '/images/tokens/ddd.svg',
    gswap: '/images/tokens/gswap.svg',
    medx: '/images/tokens/medx.svg',
    pool: '/images/tokens/pool.svg',
    sys: '/images/tokens/sys.svg',
    xp: '/images/tokens/xp.svg',
    blz: '/images/tokens/blz.svg',
    deez: '/images/tokens/deez.svg',
    gt: '/images/tokens/gt.svg',
    meetone: '/images/tokens/meetone.svg',
    pot: '/images/tokens/pot.svg',
    taas: '/images/tokens/taas.svg',
    xpa: '/images/tokens/xpa.svg',
    bnb: '/images/tokens/bnb.svg',
    dent: '/images/tokens/dent.svg',
    gtc: '/images/tokens/gtc.svg',
    met: '/images/tokens/met.svg',
    powr: '/images/tokens/powr.svg',
    tau: '/images/tokens/tau.svg',
    xpm: '/images/tokens/xpm.svg',
    bnt: '/images/tokens/bnt.svg',
    deri: '/images/tokens/deri.svg',
    gto: '/images/tokens/gto.svg',
    mfg: '/images/tokens/mfg.svg',
    ppay: '/images/tokens/ppay.svg',
    tbtc: '/images/tokens/tbtc.svg',
    xpr: '/images/tokens/xpr.svg',
    bnty: '/images/tokens/bnty.svg',
    dew: '/images/tokens/dew.svg',
    gup: '/images/tokens/gup.svg',
    mft: '/images/tokens/mft.svg',
    ppc: '/images/tokens/ppc.svg',
    tbx: '/images/tokens/tbx.svg',
    xrd: '/images/tokens/xrd.svg',
    bond: '/images/tokens/bond.svg',
    dfi: '/images/tokens/dfi.svg',
    gusd: '/images/tokens/gusd.svg',
    mim: '/images/tokens/mim.svg',
    ppp: '/images/tokens/ppp.svg',
    tct: '/images/tokens/tct.svg',
    xrp: '/images/tokens/xrp.svg',
    bondly: '/images/tokens/bondly.svg',
    dft: '/images/tokens/dft.svg',
    gvt: '/images/tokens/gvt.svg',
    mina: '/images/tokens/mina.svg',
    ppt: '/images/tokens/ppt.svg',
    tel: '/images/tokens/tel.svg',
    xsg: '/images/tokens/xsg.svg',
    booty: '/images/tokens/booty.svg',
    dfyn: '/images/tokens/dfyn.svg',
    gxs: '/images/tokens/gxs.svg',
    miota: '/images/tokens/miota.svg',
    pre: '/images/tokens/pre.svg',
    ten: '/images/tokens/ten.svg',
    xsn: '/images/tokens/xsn.svg',
    bora: '/images/tokens/bora.svg',
    dgb: '/images/tokens/dgb.svg',
    gzr: '/images/tokens/gzr.svg',
    mir: '/images/tokens/mir.svg',
    premia: '/images/tokens/premia.svg',
    tern: '/images/tokens/tern.svg',
    xsr: '/images/tokens/xsr.svg',
    bos: '/images/tokens/bos.svg',
    dgd: '/images/tokens/dgd.svg',
    hakka: '/images/tokens/hakka.svg',
    mith: '/images/tokens/mith.svg',
    prl: '/images/tokens/prl.svg',
    tfuel: '/images/tokens/tfuel.svg',
    xtz: '/images/tokens/xtz.svg',
    box: '/images/tokens/box.svg',
    dgtx: '/images/tokens/dgtx.svg',
    hav: '/images/tokens/hav.svg',
    mkr: '/images/tokens/mkr.svg',
    pro: '/images/tokens/pro.svg',
    tgch: '/images/tokens/tgch.svg',
    xuc: '/images/tokens/xuc.svg',
    bpt: '/images/tokens/bpt.svg',
    divi: '/images/tokens/divi.svg',
    hbar: '/images/tokens/hbar.svg',
    mln: '/images/tokens/mln.svg',
    pros: '/images/tokens/pros.svg',
    thc: '/images/tokens/thc.svg',
    xvc: '/images/tokens/xvc.svg',
    bq: '/images/tokens/bq.svg',
    dlt: '/images/tokens/dlt.svg',
    hc: '/images/tokens/hc.svg',
    mngo: '/images/tokens/mngo.svg',
    prq: '/images/tokens/prq.svg',
    theta: '/images/tokens/theta.svg',
    xvg: '/images/tokens/xvg.svg',
    bqx: '/images/tokens/bqx.svg',
    dmt: '/images/tokens/dmt.svg',
    hedg: '/images/tokens/hedg.svg',
    mnx: '/images/tokens/mnx.svg',
    pst: '/images/tokens/pst.svg',
    thr: '/images/tokens/thr.svg',
    xyo: '/images/tokens/xyo.svg',
    brd: '/images/tokens/brd.svg',
    dnt: '/images/tokens/dnt.svg',
    her: '/images/tokens/her.svg',
    mnz: '/images/tokens/mnz.svg',
    pungo: '/images/tokens/pungo.svg',
    tio: '/images/tokens/tio.svg',
    xzc: '/images/tokens/xzc.svg',
    bsd: '/images/tokens/bsd.svg',
    dock: '/images/tokens/dock.svg',
    hex: '/images/tokens/hex.svg',
    moac: '/images/tokens/moac.svg',
    pura: '/images/tokens/pura.svg',
    titan: '/images/tokens/titan.svg',
    yfi: '/images/tokens/yfi.svg',
    bsv: '/images/tokens/bsv.svg',
    dodo: '/images/tokens/dodo.svg',
    high: '/images/tokens/high.svg',
    mob: '/images/tokens/mob.svg',
    qash: '/images/tokens/qash.svg',
    tix: '/images/tokens/tix.svg',
    yoyo: '/images/tokens/yoyo.svg',
    'btc++': '/images/tokens/btc++.svg',
    doge: '/images/tokens/doge.svg',
    hight: '/images/tokens/hight.svg',
    mod: '/images/tokens/mod.svg',
    qbit: '/images/tokens/qbit.svg',
    tkn: '/images/tokens/tkn.svg',
    yoyow: '/images/tokens/yoyow.svg',
    btc: '/images/tokens/btc.svg',
    dor: '/images/tokens/dor.svg',
    hive: '/images/tokens/hive.svg',
    mona: '/images/tokens/mona.svg',
    qi: '/images/tokens/qi.svg',
    tks: '/images/tokens/tks.svg',
    zai: '/images/tokens/zai.svg',
    btcd: '/images/tokens/btcd.svg',
    dot: '/images/tokens/dot.svg',
    hns: '/images/tokens/hns.svg',
    mot: '/images/tokens/mot.svg',
    qiwi: '/images/tokens/qiwi.svg',
    tky: '/images/tokens/tky.svg',
    zb: '/images/tokens/zb.svg',
    btch: '/images/tokens/btch.svg',
    drgn: '/images/tokens/drgn.svg',
    hnt: '/images/tokens/hnt.svg',
    mpl: '/images/tokens/mpl.svg',
    qkc: '/images/tokens/qkc.svg',
    tlos: '/images/tokens/tlos.svg',
    zcl: '/images/tokens/zcl.svg',
    btcp: '/images/tokens/btcp.svg',
    drop: '/images/tokens/drop.svg',
    hodl: '/images/tokens/hodl.svg',
    msr: '/images/tokens/msr.svg',
    qlc: '/images/tokens/qlc.svg',
    tnb: '/images/tokens/tnb.svg',
    zco: '/images/tokens/zco.svg',
    btcz: '/images/tokens/btcz.svg',
    dta: '/images/tokens/dta.svg',
    'hot-x': '/images/tokens/hot-x.svg',
    mta: '/images/tokens/mta.svg',
    qnt: '/images/tokens/qnt.svg',
    tnc: '/images/tokens/tnc.svg',
    zec: '/images/tokens/zec.svg',
    btdx: '/images/tokens/btdx.svg',
    dth: '/images/tokens/dth.svg',
    hot: '/images/tokens/hot.svg',
    mth: '/images/tokens/mth.svg',
    qrl: '/images/tokens/qrl.svg',
    tnt: '/images/tokens/tnt.svg',
    zel: '/images/tokens/zel.svg',
    btg: '/images/tokens/btg.svg',
    dtr: '/images/tokens/dtr.svg',
    hpb: '/images/tokens/hpb.svg',
    mtl: '/images/tokens/mtl.svg',
    qsp: '/images/tokens/qsp.svg',
    toke: '/images/tokens/toke.svg',
    zen: '/images/tokens/zen.svg',
    btm: '/images/tokens/btm.svg',
    dtx: '/images/tokens/dtx.svg',
    hsr: '/images/tokens/hsr.svg',
    mtn: '/images/tokens/mtn.svg',
    qtum: '/images/tokens/qtum.svg',
    tomo: '/images/tokens/tomo.svg',
    zest: '/images/tokens/zest.svg',
    btmx: '/images/tokens/btmx.svg',
    dvf: '/images/tokens/dvf.svg',
    ht: '/images/tokens/ht.svg',
    music: '/images/tokens/music.svg',
    quick: '/images/tokens/quick.svg',
    torn: '/images/tokens/torn.svg',
    zil: '/images/tokens/zil.svg',
    bto: '/images/tokens/bto.svg',
    dxd: '/images/tokens/dxd.svg',
    html: '/images/tokens/html.svg',
    mvc: '/images/tokens/mvc.svg',
    r: '/images/tokens/r.svg',
    tpay: '/images/tokens/tpay.svg',
    zilla: '/images/tokens/zilla.svg',
    btrst: '/images/tokens/btrst.svg',
    dxt: '/images/tokens/dxt.svg',
    huc: '/images/tokens/huc.svg',
    mvl: '/images/tokens/mvl.svg',
    rads: '/images/tokens/rads.svg',
    trac: '/images/tokens/trac.svg',
    zks: '/images/tokens/zks.svg',
    bts: '/images/tokens/bts.svg',
    dydx: '/images/tokens/dydx.svg',
    hum: '/images/tokens/hum.svg',
    mvp: '/images/tokens/mvp.svg',
    rae: '/images/tokens/rae.svg',
    trb: '/images/tokens/trb.svg',
    zrx: '/images/tokens/zrx.svg',
    btt: '/images/tokens/btt.svg',
    ebst: '/images/tokens/ebst.svg',
    husd: '/images/tokens/husd.svg',
    mwat: '/images/tokens/mwat.svg',
    rap: '/images/tokens/rap.svg',
    tribe: '/images/tokens/tribe.svg',
};

export const getAssetLogo = (slug: string) => {
    if (!slug) return '';
    return logos[slug as AssetSymbol] || logos[slug.toLowerCase() as AssetSymbol];
};
