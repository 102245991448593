import { useState } from 'react';

import { useIsomorphicLayoutEffect } from '@blockworks/platform/hooks';
import { isServerSide } from '@blockworks/platform/utils';

type UseMediaQueryOptions = {
    defaultValue?: boolean;
    initializeWithValue?: boolean;
};

const useMatchMedia = (
    query: string,
    { defaultValue = false, initializeWithValue = true }: UseMediaQueryOptions = {},
): boolean => {
    const getMatches = (mediaQuery: string): boolean => {
        if (isServerSide()) {
            return defaultValue;
        }
        return window.matchMedia(mediaQuery).matches;
    };

    const [matches, setMatches] = useState<boolean>(() => {
        if (initializeWithValue) {
            return getMatches(query);
        }
        return defaultValue;
    });

    const handleChange = () => {
        setMatches(getMatches(query));
    };

    useIsomorphicLayoutEffect(() => {
        const matchMedia = window.matchMedia(query);

        handleChange();

        // Use deprecated `addListener` and `removeListener` to support Safari < 14
        if (matchMedia.addListener) {
            matchMedia.addListener(handleChange);
        } else {
            matchMedia.addEventListener('change', handleChange);
        }

        return () => {
            if (matchMedia.removeListener) {
                matchMedia.removeListener(handleChange);
            } else {
                matchMedia.removeEventListener('change', handleChange);
            }
        };
    }, [query]);

    return matches;
};

export { useMatchMedia };
