import { FunctionComponent } from "react";

const SearchIcon: FunctionComponent = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4881 12.0235L9.91536 9.45071C10.5348 8.62611 10.8691 7.62242 10.868 6.5911C10.868 3.9568 8.72481 1.8136 6.09052 1.8136C3.45622 1.8136 1.31302 3.9568 1.31302 6.5911C1.31302 9.22539 3.45622 11.3686 6.09052 11.3686C7.12184 11.3697 8.12553 11.0353 8.95013 10.4159L11.5229 12.9887C11.6531 13.1051 11.823 13.1673 11.9976 13.1624C12.1722 13.1575 12.3383 13.086 12.4619 12.9624C12.5854 12.8389 12.6569 12.6728 12.6618 12.4982C12.6667 12.3236 12.6046 12.1537 12.4881 12.0235ZM2.67802 6.5911C2.67802 5.91617 2.87816 5.2564 3.25313 4.69521C3.6281 4.13403 4.16106 3.69664 4.78461 3.43836C5.40816 3.18008 6.0943 3.1125 6.75626 3.24417C7.41822 3.37584 8.02627 3.70085 8.50352 4.1781C8.98077 4.65534 9.30578 5.26339 9.43745 5.92535C9.56912 6.58731 9.50154 7.27345 9.24326 7.897C8.98497 8.52056 8.54758 9.05352 7.9864 9.42849C7.42522 9.80346 6.76545 10.0036 6.09052 10.0036C5.1858 10.0025 4.31845 9.64263 3.67872 9.0029C3.03898 8.36317 2.6791 7.49582 2.67802 6.5911Z"
      fill="currentColor"
    />
  </svg>
);

export default SearchIcon;
