import { TVStyleProps } from '../../style-system';
import { FlexBox } from '../flex-box';

import { betweenDividerStyles, betweenTextStyles, dividerStyles } from './divider.styles';

export type DividerProps = TVStyleProps<typeof dividerStyles> & {
    betweenText?: string;
};

export const Divider = ({ betweenText, ...props }: DividerProps) => {
    if (betweenText) {
        return (
            <FlexBox center gap="sm" w="full">
                <div className={betweenDividerStyles()} />
                <span className={betweenTextStyles()}>{betweenText}</span>
                <div className={betweenDividerStyles()} />
            </FlexBox>
        );
    }
    return <div role="separator" className={dividerStyles(props)} />;
};
