import type { AriaLabelingProps, DOMProps } from '@react-types/shared';

import type { AnyObj } from '@blockworks/platform/typescript';

import { DOMEventNames, DOMPropNames } from './dom-props';

interface Options {
    /**
     * If the filter should be enabled.
     */
    enabled?: boolean;
    /**
     * If aria properties should be returned in `domProps`.
     */
    includeAria?: boolean;
    /**
     * A Set of other property names that should be returned in `domProps`.
     */
    include?: Set<string>;
    /**
     * A Set of other property names that should *not* be returned in `domProps`.
     */
    omit?: Set<string>;
    /**
     * Whether to return `data-*` props in `domProps`.
     */
    omitDataProps?: boolean;
    /**
     * Whether to return props that have values of `undefined`
     */
    omitUndefinedValues?: boolean;
    /**
     * Whether to omit event props in `domProps`.
     */
    omitEventProps?: boolean;
}

const dataRgx = /^(data-.*)$/;
const ariaRgx = /^(aria-.*)$/;
const eventRgx = /^(on[A-Z].*)$/;

const filterDOMProps = <T extends AnyObj, F extends DOMProps & AriaLabelingProps>(
    props: T,
    {
        enabled = true,
        includeAria = true,
        include,
        omit,
        omitDataProps,
        omitUndefinedValues,
        omitEventProps,
    }: Options = {},
): { domProps: AnyObj; restProps: Omit<T, keyof F> } => {
    const domProps = {} as AnyObj;
    const restProps = {} as Omit<T, keyof F>;

    if (!enabled) {
        return { domProps: props, restProps: props };
    }

    for (const prop in props) {
        if (omit?.has(prop)) {
            continue;
        }

        if (eventRgx.test(prop) && !DOMEventNames.has(prop)) {
            continue;
        }

        if (omitDataProps && dataRgx.test(prop)) {
            continue;
        }

        if (omitEventProps && eventRgx.test(prop)) {
            continue;
        }

        if (
            (Object.prototype.hasOwnProperty.call(props, prop) &&
                (DOMPropNames.has(prop) ||
                    (includeAria && ariaRgx.test(prop)) ||
                    include?.has(prop as any) ||
                    dataRgx.test(prop))) ||
            eventRgx.test(prop)
        ) {
            if (omitUndefinedValues && typeof props[prop] === 'undefined') continue;
            // @ts-ignore
            domProps[prop] = props[prop];
        } else {
            if (omitUndefinedValues && typeof props[prop] === 'undefined') continue;
            // @ts-ignore
            restProps[prop] = props[prop];
        }
    }

    return { domProps, restProps };
};

/**
 * Returns all props that are valid DOM props or defined via override options.
 * @param props - The component props to be filtered.
 */
const safelySpreadDOMProps = (props: AnyObj) => filterDOMProps(props).domProps;
const safelySpreadNonDOMProps = (props: AnyObj) => filterDOMProps(props).restProps;

export { filterDOMProps, safelySpreadDOMProps, safelySpreadNonDOMProps };
