import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

export const dividerStyles = tVariants(
    {
        base: 'bg-surface-divider',
        variants: {
            orientation: {
                horizontal: 'min-w-full h-[1px] my-2',
                vertical: 'min-h-full w-[1px] mx-2',
            },
            ...factoryStyleProps.space,
            display: factoryStyleProps.layout.display,
        },
    },
    {
        responsiveVariants: true,
    },
);

export const betweenTextStyles = tVariants({
    base: 'text-content-deselected text-xs mb-1',
});

export const betweenDividerStyles = tVariants({
    base: 'bg-surface-interact w-1/2 h-[1px] my-2',
});
