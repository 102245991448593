import { GenericData } from '@knocklabs/types';

import { KnockWorkflowKeys } from './knock-constants';

export const getNotificationTitle = (workflowKey: KnockWorkflowKeys, data?: GenericData) => {
    switch (workflowKey) {
        case KnockWorkflowKeys.PROPOSAL_CREATED:
            return 'New Proposal';
        case KnockWorkflowKeys.PROPOSAL_STATUS:
            return `Proposal ${data?.status ?? data?.data?.status ?? 'Updated'}`.trim();
        case KnockWorkflowKeys.NEWS_CREATED:
            return 'New Blockworks News';
        case KnockWorkflowKeys.FLASHNOTE_CREATED:
            return 'New Flashnote';
        case KnockWorkflowKeys.REPORT_CREATED:
            return 'New Research Article';
        case KnockWorkflowKeys.DASHBOARD_CREATED:
            return 'New Analytics Dashboard';
        default:
            return 'Notification';
    }
};
