import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
    InstantSearch,
    useInstantSearch,
    useSearchBox,
    UseSearchBoxProps,
} from '@blockworks/platform/libs/react-instantsearch';
import { FlexBox, IconButton, Input } from '@blockworks/ui/components';
import { MagnifyingGlassIcon, XCloseIcon } from '@blockworks/ui/icon';

import { useHitsNavigation } from './algolia-search-input.utils';

type AlgoliaSearchInputProps = UseSearchBoxProps & {
    resultsRef: React.RefObject<HTMLDivElement>;
    setOpen?: (open: boolean) => void;
    placeholder?: string;
    className?: string;
    variant?: 'ghost' | 'outline' | 'transparent' | 'outline-transparent' | 'outline-fill' | undefined;
    size?: 'sm' | 'md' | 'lg' | undefined;
    hideClearButton?: boolean;
};

export const AlgoliaSearchInput = ({ hideClearButton, ...props }: AlgoliaSearchInputProps) => {
    const { resultsRef, setOpen, placeholder, variant = 'transparent', size = 'lg', className } = props;
    const { query, refine } = useSearchBox(props);
    const { status } = useInstantSearch();
    const [inputValue, setInputValue] = useState(query ?? '');
    const inputRef = useRef<HTMLInputElement>(null);
    const isSearchStalled = status === 'stalled';
    const isResetHidden = inputValue.length === 0 || isSearchStalled;

    useEffect(() => {
        refine('');
    }, [refine]);

    const setQuery = useCallback(
        (newQuery: string) => {
            refine(newQuery);
            resultsRef.current?.scrollTo({ top: 0 });
        },
        [refine, resultsRef],
    );

    useEffect(() => {
        const handler = setTimeout(() => {
            setQuery(inputValue);
        }, 300);
        return () => {
            clearTimeout(handler);
        };
    }, [inputValue, setQuery]);

    const { handleInputKeyDown, setFocusedIndex } = useHitsNavigation(query);

    useEffect(() => {
        setFocusedIndex(undefined);
    }, [inputValue, setFocusedIndex]);

    return (
        <FlexBox
            px={3}
            bgColor="raised"
            h="full"
            borderBottom={1}
            borderColor="selected"
            alignItems="center"
            className={className}
        >
            <form
                action=""
                role="search"
                className="flex w-full align-center items-center gap-1"
                noValidate
                onSubmit={event => {
                    event.preventDefault();
                    event.stopPropagation();

                    if (inputRef.current) {
                        inputRef.current.blur();
                    }

                    setQuery(inputRef.current?.value ?? '');
                }}
                onReset={event => {
                    event.preventDefault();
                    event.stopPropagation();

                    setQuery('');

                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }}
            >
                <Input
                    ref={inputRef}
                    icon={MagnifyingGlassIcon}
                    size={size}
                    w="full"
                    data-testid="search-input"
                    variant={variant}
                    noOutline
                    name="search-field"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    placeholder={placeholder ?? 'Search for assets, articles, proposals and more...'}
                    spellCheck={false}
                    maxLength={512}
                    type="search"
                    value={inputValue}
                    onKeyDown={handleInputKeyDown}
                    onChange={event => {
                        setInputValue(event.target.value);
                        if (event.target.value === '') {
                            refine(' ');
                        }
                    }}
                    autoFocus
                />
                {!hideClearButton && (
                    <IconButton
                        size={size}
                        icon={XCloseIcon}
                        type="reset"
                        data-testid="search-reset-button"
                        borderRadius="full"
                        aria-label="reset"
                        display={isResetHidden ? 'hidden' : undefined}
                        onClick={() => {
                            if (setOpen) setOpen(false);
                            setInputValue('');
                            refine(' ');
                        }}
                    />
                )}
            </form>
        </FlexBox>
    );
};

AlgoliaSearchInput.Provider = InstantSearch;
