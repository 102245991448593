import { PropsWithChildren, useCallback } from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';

import { mapClassNamesToSlots, TVStyleProps } from '../../style-system';
import { FlexBox } from '../flex-box';

import { modalStyles } from './modal.styles';
import { ModalBody } from './modal-body';
import { ModalFooter } from './modal-footer';
import { ModalClose, ModalHeader, ModalTitle } from './modal-header';

type ModalProps = PropsWithChildren<
    TVStyleProps<typeof modalStyles> & {
        open: boolean;
        onOpenChange?: (open: boolean) => void | React.Dispatch<React.SetStateAction<boolean>>;
        onClose?: () => void;
        onOpen?: () => void;
    }
>;

const Modal = ({ children, open, onOpenChange, onClose, onOpen, classNames, ...rest }: ModalProps) => {
    const { shroud, base } = mapClassNamesToSlots(modalStyles, { classNames, ...rest });
    const handleOnOpenChange = useCallback(
        (open: boolean) => {
            if (!open) {
                onClose?.();
            } else {
                onOpen?.();
            }
            onOpenChange?.(open);
        },
        [onOpenChange, onOpen, onClose],
    );

    return (
        <RadixDialog.Root open={open} onOpenChange={handleOnOpenChange}>
            <RadixDialog.Portal>
                <RadixDialog.Overlay className={shroud} />
                <RadixDialog.Content className={base}>{children}</RadixDialog.Content>
            </RadixDialog.Portal>
        </RadixDialog.Root>
    );
};

const ModalContent = ({ children }: React.PropsWithChildren) => {
    return (
        <FlexBox w="full" h="full" col>
            {children}
        </FlexBox>
    );
};

Modal.Content = ModalContent;
Modal.Close = ModalClose;
Modal.Header = ModalHeader;
Modal.Title = ModalTitle;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export type { ModalProps };
export { Modal };
