import { getPublicationTitleById } from '@blockworks/platform/api/beehiiv';
import { FlexBox, List, Text } from '@blockworks/ui/components';

import { AppRoutes } from '@/services/auth/auth.routes';
import { getTimeDistance } from '@/utils/date';

import { ItemTypes } from '../utils/search.enums';
import { NewsletterHit, SearchHitProps } from '../utils/search.types';

export const NewsletterItem = (props: SearchHitProps<NewsletterHit>) => {
    const { objectID, id, title, subjectLine, publication, publishDate, onSelect } = props;
    const itemData = { objectID, id, title, publication, publishDate, subjectLine } as NewsletterHit;
    const href = AppRoutes.NEWSLETTER_ID.pathname(publication, id);
    const timeAgo = getTimeDistance(publishDate);

    return (
        <List.Row id={objectID} onClick={() => onSelect?.(itemData, ItemTypes.Newsletter)} href={href}>
            <List.Col size="xl" grow>
                <FlexBox gap="xs">
                    <Text size="sm" weight="semibold">
                        {title}
                    </Text>
                    <Text size="xs" color="deselect">{`·`}</Text>
                    <Text size="xs" color="deselect">
                        {getPublicationTitleById(publication)}
                    </Text>
                    <Text size="xs" color="deselect">
                        {`·`}
                    </Text>
                    <Text size="xs" color="deselect">
                        {timeAgo}
                    </Text>
                </FlexBox>
            </List.Col>
        </List.Row>
    );
};
