import { forwardRef, PropsWithChildren } from 'react';

import { safelySpreadDOMProps } from '../../style-system';
import { TVComponentWithElementProps } from '../../style-system/models';

import { textStyles } from './text.styles';

type TextElement = 'p' | 'span' | 'div';

export type TextProps<Tag extends TextElement = TextElement> = PropsWithChildren<
    TVComponentWithElementProps<'p', typeof textStyles> & {
        as?: Tag;
    }
>;

export const Text = forwardRef<HTMLParagraphElement | HTMLSpanElement | HTMLDivElement, TextProps>(function Text(
    { children, as: As = 'p', className: classNameProp, ...rest },
    ref,
) {
    const className = textStyles({ ...rest, className: classNameProp });

    return (
        <As className={className} {...safelySpreadDOMProps(rest)} ref={ref as any}>
            {children}
        </As>
    );
});
