import { forwardRef } from 'react';

import { cn, PropsOf } from '../../style-system';
import { Divider } from '../divider';
import { FlexBox } from '../flex-box';

type ModalFooterProps = PropsOf<'div'>;

const ModalFooter = forwardRef<HTMLDivElement, ModalFooterProps>(function ModalFooter(
    { children, className, ...rest },
    ref,
) {
    return (
        <FlexBox mt="auto" mb={0} col ref={ref}>
            <div className={cn('w-full')}>
                <Divider orientation="horizontal" mt={0} />
            </div>
            <FlexBox
                w="full"
                justifyContent="between"
                alignItems="center"
                px={4}
                pt={2}
                pb={4}
                className={cn(className)}
                {...rest}
            >
                {children}
            </FlexBox>
        </FlexBox>
    );
});

export type { ModalFooterProps };
export { ModalFooter };
