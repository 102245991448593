import { useState } from 'react';
import NextAdapterPages from 'next-query-params';
import { EncodedQuery, objectToSearchString, QueryParamProvider as UQPProvider } from 'use-query-params';

const removeEmptyParams = (encodedParams: EncodedQuery) => {
    const existingParams: EncodedQuery = {};
    for (const param in encodedParams) {
        if (typeof param === 'string') {
            const value = encodedParams[param];
            if (typeof value === 'undefined') continue;
            if (typeof value === 'string' && value.length === 0) continue;
            existingParams[param] = value;
        }
    }

    return objectToSearchString(existingParams);
};

function getNextAdapter(shallow?: boolean) {
    // eslint-disable-next-line prefer-arrow-functions/prefer-arrow-functions
    return function NextAdapter(props: any) {
        return <NextAdapterPages {...props} shallow={shallow} />;
    };
}

type QueryParamProviderProps = React.PropsWithChildren<{
    shallow?: boolean;
}>;

const QueryParamProvider = ({ children, shallow }: QueryParamProviderProps) => {
    const [adapter] = useState(() => getNextAdapter(shallow));

    return (
        <UQPProvider adapter={adapter} options={{ objectToSearchString: removeEmptyParams }}>
            {children}
        </UQPProvider>
    );
};

export { QueryParamProvider };
