import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { useDebounceCallback } from '@blockworks/platform/hooks';

import { mapClassNamesToSlots, safelySpreadDOMProps } from '../../../style-system';
import { TVComponentWithElementProps } from '../../../style-system/models';
import { FlexBox, Tooltip } from '../..';
import { IconComponent, TooltipCircleIcon } from '../../icon';
import { type SimpleFieldOptions, useFieldProps } from '../field/context';

import { inputStyles } from './input.styles';

type InputProps = Omit<
    TVComponentWithElementProps<'input', typeof inputStyles, keyof SimpleFieldOptions>,
    'hasIcon'
> & {
    icon?: IconComponent;
    tooltipText?: string;
    debounce?: number;
} & SimpleFieldOptions;

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
    {
        variant = 'outline-transparent',
        noOutline,
        tooltipText,
        icon: Icon,
        w,
        classNames,
        type = 'text',
        debounce = 0,
        onChange,
        ...rest
    },
    ref,
) {
    const [internalValue, setInternalValue] = useState(rest.defaultValue ?? undefined);

    useEffect(() => {
        setInternalValue(rest.value);
    }, [rest.value]);

    const debouncedOnChange = useDebounceCallback(onChange, debounce);
    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setInternalValue(event.target.value);
            debouncedOnChange(event);
        },
        [debouncedOnChange],
    );

    const { size, fieldProps, rootProps } = useFieldProps(rest);
    const { base, icon, input, tooltip } = mapClassNamesToSlots(inputStyles, {
        hasIcon: !!Icon,
        noOutline,
        variant,
        size,
        disabled: fieldProps.disabled,
        readOnly: fieldProps.readOnly,
        w,
        classNames,
    });

    return (
        <div {...rootProps} className={base}>
            {tooltipText && (
                <FlexBox className={tooltip}>
                    <Tooltip contentText={tooltipText}>
                        <Tooltip.Trigger>
                            <TooltipCircleIcon size={1} color="deselect" />
                        </Tooltip.Trigger>
                    </Tooltip>
                </FlexBox>
            )}
            <input
                ref={ref}
                {...safelySpreadDOMProps(fieldProps)}
                type={type}
                value={internalValue}
                onChange={handleOnChange}
                className={input}
            />
            {Icon && <Icon className={icon} />}
        </div>
    );
});

export type { InputProps };
export { Input };
