import { createApi } from '../../config/create-api';

import {
    GetUserByIdResponse,
    GetUserProfileResponse,
    UpdateUserAccountRequest,
    UpdateUserAccountResponse,
    UpdateUserImageRequest,
    UpdateUserImageResponse,
    UpdateUserProfileRequest,
    UpdateUserProfileResponse,
    USER_ENDPOINTS,
} from './user.model';

const userApi = createApi(build => {
    return {
        get: {
            userAccount: build<any>({
                cachePrefix: 'user-account',
                query: () => {
                    return {
                        uri: USER_ENDPOINTS.GET.USER_ACCOUNT(),
                    };
                },
            }),
            userProfile: build<GetUserProfileResponse, { sessionToken?: string }>({
                cachePrefix: 'user-profile',
                query: ({ sessionToken }) => {
                    return {
                        uri: USER_ENDPOINTS.GET.USER_PROFILE(),
                        options: { sessionToken },
                    };
                },
            }),
            userImage: build<any, void>({
                cachePrefix: 'user-image',
                query: () => {
                    return {
                        uri: USER_ENDPOINTS.GET.USER_IMAGE(),
                    };
                },
            }),
            byUser: build<GetUserByIdResponse, { sessionToken: string }>({
                cachePrefix: 'user',
                query: ({ sessionToken }) => {
                    return {
                        uri: USER_ENDPOINTS.GET.USER(),
                        options: {
                            sessionToken,
                        },
                    };
                },
            }),
            byId: build<GetUserByIdResponse, { id: string; sessionToken?: string }>({
                cachePrefix: 'user-by-id',
                query: ({ id, sessionToken }) => {
                    return {
                        uri: USER_ENDPOINTS.GET.USER_BY_ID({ id }),
                        options: {
                            sessionToken,
                        },
                    };
                },
            }),
            bySession: build<any, { sessionId: string; sessionToken?: string }>({
                cachePrefix: 'user-by-session',
                query: ({ sessionId, sessionToken }) => {
                    return {
                        uri: USER_ENDPOINTS.GET.USER_BY_SESSION({ sessionId }),
                        options: {
                            sessionToken,
                        },
                    };
                },
            }),
        },
        post: {
            updateUserAccount: build<UpdateUserAccountResponse, UpdateUserAccountRequest>({
                cachePrefix: 'update-user-account',
                query: ({ firstname, lastname, email }) => ({
                    uri: USER_ENDPOINTS.POST.UPDATE_ACCOUNT(),
                    options: {
                        method: build.Method.Post,
                        body: {
                            firstname,
                            lastname,
                            email,
                        },
                    },
                }),
            }),
            updateUserProfile: build<UpdateUserProfileResponse, UpdateUserProfileRequest>({
                cachePrefix: 'update-user-profile',
                query: ({ sessionToken, body }) => ({
                    uri: USER_ENDPOINTS.POST.UPDATE_PROFILE(),
                    options: {
                        sessionToken,
                        method: build.Method.Post,
                        body,
                    },
                }),
            }),
            updateUserImage: build<UpdateUserImageResponse, UpdateUserImageRequest>({
                cachePrefix: 'update-user-image',
                query: ({ file }) => {
                    const formData = new FormData();
                    formData.append(`file`, file);

                    return {
                        uri: USER_ENDPOINTS.POST.UPDATE_USER_IMAGE(),
                        options: {
                            method: build.Method.Post,
                            body: formData,
                        },
                    };
                },
            }),
        },
    };
});

export { userApi };
