import { useEffect } from 'react';
import { FilterStatus, useAuthenticatedKnockClient, useNotifications, useNotificationStore } from '@knocklabs/react';

import { useAuth } from '@blockworks/session/context';

export const useKnock = (status?: string | FilterStatus) => {
    const { user } = useAuth();

    if (status && !Object.values(FilterStatus).includes(status as FilterStatus)) {
        throw new Error('Invalid status');
    }

    const knockClient = useAuthenticatedKnockClient(process.env.KNOCK_PUBLIC_API_KEY as string, user?.email as string);

    const knockFeed = useNotifications(knockClient, process.env.KNOCK_FEED_CHANNEL_ID as string);

    const knockFeedStore = useNotificationStore(knockFeed);

    useEffect(() => {
        const options = {
            ...(status ? { status: status as FilterStatus } : {}),
            page_size: 50,
        };
        knockFeed.fetch(options).catch(error => {
            console.error('Failed to fetch notifications:', error);
            // Consider adding error handling/notification here
        });
    }, [knockFeed, status]);

    return {
        knockClient,
        knockFeed,
        knockFeedStore,
    };
};
