import { useCallback, useId, useState } from 'react';

import { mergeRefs } from '../../../../hooks';
import { PropGetter } from '../../../../models';
import { safelySpreadDOMProps } from '../../../../style-system';
import { createSafeContext, dataAttr } from '../../../../utils';

import type { FieldContext } from './field-context.model';

const useFieldState = (props: FieldContext) => {
    const { id: idProp, required, invalid, disabled, readOnly, size = 'md', orientation = {}, ...rootProps } = props;

    // Generate all required ids
    const uuid = useId();
    const id = idProp ?? `field-${uuid}`;
    const labelId = `${id}-label`;
    const feedbackId = `${id}-feedback`;
    const helpTextId = `${id}-helptext`;

    /**
     * Track whether the `FormErrorMessage` has been rendered.
     * We use this to append its id to the input's `aria-describedby`.
     */
    const [hasFeedbackText, setHasFeedbackText] = useState(false);

    /**
     * Track whether the `FormHelperText` has been rendered.
     * We use this to append its id to the input's `aria-describedby`
     */
    const [hasHelpText, setHasHelpText] = useState(false);

    /**
     * Track whether the form element (e.g, `input`) has focus.
     * We use this to append proper data attributes to the label and input/control
     * We don't use these data attrs for anything yet, but its a good standard to
     * track this and set on all field components.
     */
    const [focused, setFocus] = useState(false);

    const getHelpTextProps = useCallback<PropGetter>(
        (props = {}, _ref = null) => ({
            id: helpTextId,
            ...props,
            /**
             * Notify the field context when the help text is rendered on screen,
             * so we can apply the correct `aria-describedby` to the field (e.g. input, textarea).
             */
            ref: mergeRefs(_ref, node => {
                if (!node) return;
                setHasHelpText(true);
            }),
        }),
        [helpTextId],
    );

    const getLabelProps = useCallback<PropGetter>(
        (props = {}, passedRef = null) => ({
            ...props,
            ref: passedRef,
            'data-focus': dataAttr(focused),
            'data-disabled': dataAttr(disabled),
            'data-invalid': dataAttr(invalid),
            'data-readonly': dataAttr(readOnly),
            id: props.id || labelId,
            htmlFor: props.htmlFor || id,
        }),
        [id, disabled, focused, invalid, readOnly, labelId],
    );

    const getErrorMessageProps = useCallback<PropGetter>(
        (props = {}, _ref = null) => ({
            id: feedbackId,
            ...props,
            /**
             * Notify the field context when the error message is rendered on screen,
             * so we can apply the correct `aria-describedby` to the field (e.g. input, textarea).
             */
            ref: mergeRefs(_ref, node => {
                if (!node) return;
                setHasFeedbackText(true);
            }),
            'aria-live': 'polite',
        }),
        [feedbackId],
    );

    const getRootProps = useCallback<PropGetter>(
        (props = {}, _ref = null) => ({
            ...props,
            ...rootProps,
            ref: _ref,
            role: 'group',
            'data-focus': dataAttr(focused),
            'data-disabled': dataAttr(disabled),
            'data-invalid': dataAttr(invalid),
            'data-readonly': dataAttr(readOnly),
        }),
        [rootProps, disabled, focused, invalid, readOnly],
    );

    const getOrientationProps = useCallback<
        PropGetter<
            Omit<NonNullable<FieldContext['orientation']>, 'controlDirection'>,
            {
                orientation?: FieldContext['orientation'];
            }
        >
    >(
        (props = {}, _ref = null) => ({
            ...safelySpreadDOMProps(props),
            orientation: {
                split: props.split ?? orientation?.split ?? false,
                direction: props.direction ?? orientation?.direction ?? 'vertical',
                reverse: props.reverse ?? orientation?.reverse ?? false,
                controlDirection: props.controlDirection ?? orientation?.controlDirection ?? 'vertical',
            },
            ref: _ref,
        }),
        [orientation],
    );

    return {
        required: !!required,
        invalid: !!invalid,
        readOnly: !!readOnly,
        disabled: !!disabled,
        focused: !!focused,

        onFocus: () => setFocus(true),
        onBlur: () => setFocus(false),

        hasFeedbackText,
        setHasFeedbackText,
        hasHelpText,
        setHasHelpText,

        id,
        labelId,
        feedbackId,
        helpTextId,

        getHelpTextProps,
        getErrorMessageProps,
        getRootProps,
        getLabelProps,
        getOrientationProps,

        orientation,
        size,
    };
};

type UseFieldStateReturn = ReturnType<typeof useFieldState>;

const [FieldContextProvider, useFieldContext] = createSafeContext<UseFieldStateReturn>({
    /** allows us to use field context optionally in any input/control (e.g. input, select, textarea) */
    strict: false,
    name: 'FieldContext',
    hookName: 'useFieldContext',
});

export type { UseFieldStateReturn };
export { FieldContextProvider, useFieldContext, useFieldState };
