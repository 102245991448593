export enum ResearchIndexes {
    Research = 'research',
    Assets = 'assets',
    Proposal = 'proposal',
    BWRSearch = 'bwr-search',
    StudioVisualizations = 'studio_visualizations',
    StudioDashboards = 'studio_dashboards',
    StudioQueries = 'studio_queries',
    StudioQueryTables = 'studio_tables',
}
