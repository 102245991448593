import { useEffect, useState } from 'react';
import * as RadixSelect from '@radix-ui/react-select';

import { Avatar } from '../avatar';
import { FlexBox } from '../flex-box';
import type { IconComponent } from '../icon';
import { ChevronSelectorVerticalIcon } from '../icon/icons';
import { ScrollArea } from '../scroll-area/scroll-area';
import { Text } from '../text';

import { MenuGroup } from './menu-group';
import { menuSelectStyles } from './menu-select.styles';

export interface MenuSelectOption {
    value: string;
    image?: string;
    icon?: IconComponent;
    label: string;
    disabled?: boolean;
}
export interface MenuSelectProps extends Omit<RadixSelect.SelectProps, 'value' | 'defaultValue' | 'onValueChange'> {
    options: MenuSelectOption[];
    selected?: string;
    defaultSelected?: MenuSelectOption;
    placeholder?: string;
    icon?: IconComponent;
    inline?: boolean;
    onChange: (selectedId: string, selected?: MenuSelectOption) => void;
    submenu?: (props: { isOpen: boolean; selectedItem?: string }) => React.ReactNode;
}

export const MenuSelect = ({
    placeholder,
    options,
    selected,
    onChange,
    open,
    onOpenChange,
    icon: Icon = ChevronSelectorVerticalIcon,
    submenu: Submenu,
}: MenuSelectProps) => {
    const { base, button, option } = menuSelectStyles();
    const [localSelected, setLocalSelected] = useState(selected);

    useEffect(() => {
        setLocalSelected(selected);
    }, [selected]);

    const getOption = (val?: string) => options.find(opt => val === opt.value);

    const selectedOption = getOption(localSelected);

    return (
        <MenuGroup>
            <RadixSelect.Root
                open={open}
                onOpenChange={onOpenChange}
                value={selected}
                onValueChange={val => {
                    setLocalSelected(prev => {
                        if (prev !== val) {
                            onChange(
                                val,
                                options.find(opt => val === opt.value),
                            );
                        }
                        return val;
                    });
                }}
            >
                <RadixSelect.Trigger asChild>
                    <button className={button()}>
                        <FlexBox w="full" justifyContent="between" alignItems="center">
                            {!selectedOption && <RadixSelect.Value placeholder={placeholder} />}
                            {selectedOption && (
                                <RadixSelect.Value>
                                    <FlexBox gap="sm" alignItems="center">
                                        {selectedOption.image && (
                                            <Avatar src={selectedOption.image} size={16} alt={selectedOption.value} />
                                        )}
                                        <Text as="p" align="left" lines={1}>
                                            {selectedOption.label}
                                        </Text>
                                    </FlexBox>
                                </RadixSelect.Value>
                            )}
                            <RadixSelect.Icon>
                                <Icon className="w-4 h-4 text-content-deselected" />
                            </RadixSelect.Icon>
                        </FlexBox>
                    </button>
                </RadixSelect.Trigger>
                <RadixSelect.Portal>
                    <RadixSelect.Content
                        position="popper"
                        style={{
                            width: 'var(--radix-popper-anchor-width)',
                            top: `calc(var(--radix-popper-anchor-height) * -1)`,
                            position: 'fixed',
                        }}
                    >
                        <div className={base()}>
                            <FlexBox col gap="sm" w="full">
                                <ScrollArea h={220}>
                                    {options.map(opt => (
                                        <RadixSelect.Item
                                            value={String(opt.value)}
                                            className={option()}
                                            key={`opt-value-${opt.value}`}
                                        >
                                            <FlexBox gap="sm" alignItems="center">
                                                {opt.image && <Avatar src={opt.image} size={16} alt={opt.value} />}
                                                <Text as="div" lines={1}>
                                                    {opt.label}
                                                </Text>
                                            </FlexBox>
                                        </RadixSelect.Item>
                                    ))}
                                </ScrollArea>
                            </FlexBox>
                        </div>
                    </RadixSelect.Content>
                </RadixSelect.Portal>
                {Submenu && <Submenu isOpen={!!open} selectedItem={selected} />}
            </RadixSelect.Root>
        </MenuGroup>
    );
};
