import React, { PropsWithChildren, useState } from 'react';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { QueryParamProvider } from '@blockworks/platform/libs/use-query-params';

interface QueryProviderProps extends PropsWithChildren {
    pageProps: any;
    shallow?: boolean;
}

const QueryProvider = ({ children, pageProps, shallow }: QueryProviderProps) => {
    const [queryClient] = useState(() => new QueryClient({}));

    return (
        <QueryParamProvider shallow={shallow}>
            <QueryClientProvider client={queryClient}>
                <HydrationBoundary state={pageProps.dehydratedState}>{children}</HydrationBoundary>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </QueryParamProvider>
    );
};

export { QueryProvider };
