import { tVariants } from '../../style-system';

const avatarStyles = tVariants({
    base: 'avatar inline-flex relative rounded-full bg-current-bg border-[--color-current-bg] overflow-hidden h-max w-max',
    slots: {
        image: 'w-[--avatar-size] h-[--avatar-size] rounded-full',
        placeholder:
            'bg-primary font-bold text-surface w-[--avatar-size] h-[--avatar-size] flex justify-center items-center absolute',
    },
    variants: {
        ghost: {
            true: {
                image: 'opacity-0',
                placeholder: 'opacity-0',
            },
        },
        stacked: { true: '-top-[3px] border-3 [.avatar_~_&]:-ml-[var(--avatar-size)_/_3]' },
        contrast: { true: 'bg-surface-selected' },
        size: {
            96: '[--avatar-size:96px] text-4xl',
            40: '[--avatar-size:40px] text-md',
            32: '[--avatar-size:32px] text-sm',
            24: '[--avatar-size:24px] text-xs',
            16: '[--avatar-size:16px] text-xs',
            12: '[--avatar-size:12px] text-xxs',
        },
    },
});

const avatarGroupStyles = tVariants({
    base: 'group/avatar inline-flex relative z-0 max-h-[--avatar-size] overflow-hidden',
    variants: {
        size: {
            40: '[--avatar-size:40px]',
            32: '[--avatar-size:32px]',
            24: '[--avatar-size:24px]',
            16: '[--avatar-size:16px]',
        },
    },
});

export { avatarGroupStyles, avatarStyles };
