import { type FactoryStyleProps, factoryStyles } from './factory.styles';

type StylePropNames = keyof FactoryStyleProps;
const STYLE_PROPS = new Set([...factoryStyles.variantKeys, 'className']);

const isStyleProp = (key: any): key is StylePropNames => STYLE_PROPS.has(key);

/** @todo refactor to destructure helper when `prop-scales` are removed */
const safelySpreadProps = <T extends Record<string, any>>(props: T) => {
    const styleProps: FactoryStyleProps = {};
    const htmlProps: Record<string, any> = {};

    for (const prop in props) {
        if (isStyleProp(prop)) {
            styleProps[prop] = props[prop];
        } else {
            htmlProps[prop] = props[prop];
        }
    }
    return {
        styleProps,
        htmlProps,
    };
};

export { safelySpreadProps };
