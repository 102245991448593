import { useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash-es';

import { useUnmount } from './use-unmount';

type DebounceOptions = {
    leading?: boolean;
    trailing?: boolean;
    maxWait?: number;
};

type ControlFunctions = {
    cancel: () => void;
    flush: () => void;
    isPending: () => boolean;
};

type DebouncedState<T extends (...args: any) => ReturnType<T>> = ((
    ...args: Parameters<T>
) => ReturnType<T> | undefined) &
    ControlFunctions;

const useDebounceCallback = <T extends (...args: any) => ReturnType<T>>(
    fn: T = (() => {}) as T,
    delay = 500,
    options?: DebounceOptions,
): DebouncedState<T> => {
    const debouncedFunc = useRef<ReturnType<typeof debounce>>();

    useUnmount(() => {
        if (debouncedFunc.current) {
            debouncedFunc.current.cancel();
        }
    });

    const debounced = useMemo(() => {
        const debouncedFuncInstance = debounce(fn, delay, options);

        const wrappedFunc: DebouncedState<T> = (...args: Parameters<T>) => {
            return debouncedFuncInstance(...args);
        };

        wrappedFunc.cancel = () => {
            debouncedFuncInstance.cancel();
        };

        wrappedFunc.isPending = () => {
            return !!debouncedFunc.current;
        };

        wrappedFunc.flush = () => {
            return debouncedFuncInstance.flush();
        };

        return wrappedFunc;
    }, [fn, delay, options]);

    // Update the debounced function ref whenever fn, wait, or options change
    useEffect(() => {
        debouncedFunc.current = debounce(fn, delay, options);
    }, [fn, delay, options]);

    return debounced;
};

export type { DebouncedState };
export { useDebounceCallback };
