import { forwardRef, PropsWithChildren } from 'react';
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';

import { menuGroupStyles } from './menu-group.styles';

export const MenuGroup = forwardRef<HTMLLIElement, PropsWithChildren>(({ children }, ref) => (
    <RadixNavigationMenu.Item ref={ref} className="px-1">
        <div className={menuGroupStyles()}>{children}</div>
    </RadixNavigationMenu.Item>
));

MenuGroup.displayName = 'Menu.Group';
