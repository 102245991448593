import Skeleton from 'react-loading-skeleton';
import { useRouter } from 'next/router';

import { FlexBox, Menu, Text } from '@blockworks/ui/components';
import { Repeater } from '@blockworks/ui/repeater';

import { dashboardQueries } from '@/api/dashboard/queries/dashboard-queries.client';

type AnalyticsMenuProps = {
    orientation?: 'horizontal' | 'vertical';
    size?: 'xs' | 'sm';
};

const AnalyticsMenu = ({ orientation = 'vertical', size = 'sm' }: AnalyticsMenuProps) => {
    const { asPath } = useRouter();
    const datapage = dashboardQueries.getDatapage.use({
        select: ({ bySlug, spotlightBySlug }) =>
            Object.values({
                ...bySlug,
                ...spotlightBySlug,
            }),
    });

    if (datapage.isLoading) {
        return (
            <FlexBox gap="sm" col>
                <Repeater count={15}>
                    <Skeleton width="100%" height={25} />
                </Repeater>
            </FlexBox>
        );
    }

    return (
        <Menu orientation={orientation}>
            <Menu.List>
                {datapage.data?.map(({ id, name, slug }) => (
                    <Menu.Item
                        key={`dashboard-nav-${id}`}
                        id={`${id}`}
                        size={size}
                        href={`/analytics/${slug}`}
                        active={asPath === `/analytics/${slug}` || asPath.startsWith(`/analytics/${slug}?`)}
                    >
                        <Text align="left" truncate>
                            {name}
                        </Text>
                    </Menu.Item>
                ))}
            </Menu.List>
        </Menu>
    );
};
export default AnalyticsMenu;
