import { SessionUser } from 'next-auth';

import { getPermissionLevel, makeAuthRedirectHelper } from '@blockworks/session';
import { AuthRole } from '@blockworks/session/models';

export const getAuthRewrite = (session: SessionUser, path: string) => {
    const userAuthorization = getPermissionLevel(session);
    if (userAuthorization === AuthRole.SUBSCRIBED && path.startsWith('/unlocked/')) {
        return { destination: path.replace('/unlocked/', '/research/') };
    }
    if (userAuthorization !== AuthRole.SUBSCRIBED && path.startsWith('/research/')) {
        return { destination: path.replace('/research/', '/unlocked/') };
    }
    return { destination: null };
};

export const getAuthRedirect = makeAuthRedirectHelper({
    [AuthRole.PUBLIC]: {
        allowedPaths: ['/error', '/404', '/500', '/unlocked/:slug', '/glossary'],
        skipRedirect: true,
    },
    [AuthRole.UNAUTHENTICATED]: {
        destination: '/sign-in',
        allowedPaths: ['/sign-in', '/sign-in/forgot', '/sign-in/forgot/:token', '/sign-up'],
    },
    [AuthRole.UNSUBSCRIBED]: {
        destination: '/sign-up/select-plan',
        allowedPaths: ['/sign-up/select-plan', '/sign-up/contact-sales'],
    },
    [AuthRole.PENDING_SUBSCRIPTION]: {
        destination: '/sign-up/welcome',
        allowedPaths: ['/sign-up/welcome', '/sign-up/welcome/favorites'],
    },
    [AuthRole.SUBSCRIBED]: {
        destination: '/',
        allowedPaths: '*',
    },
});
