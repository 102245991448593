import React from 'react';
import { FeedItem } from '@knocklabs/client';
import { GenericData } from '@knocklabs/types';

import { Bit } from '@blockworks/ui/bit';
import { Avatar, FlexBox, Text } from '@blockworks/ui/components';
import { ShortRelativeTime } from '@blockworks/ui/date-time';

import { KnockWorkflowKeys } from '@/utils/knock/knock-constants';
import { getNotificationTitle } from '@/utils/knock/knock-utils';

const NotificationItemContent = ({ statusText, subTitleText }: { statusText?: string; subTitleText: string }) => (
    <>
        {statusText && <Text size="xs">{statusText}</Text>}
        <Text size="xs" truncate>
            {subTitleText}
        </Text>
    </>
);

const getContentProps = (workflowKey: KnockWorkflowKeys, activityData: GenericData, assetCode: string) => {
    if (workflowKey === KnockWorkflowKeys.PROPOSAL_STATUS) {
        return {
            statusText: `${assetCode?.toUpperCase()} proposal is now ${activityData?.status}`,
            subTitleText: activityData?.title as string,
        };
    } else if (workflowKey === KnockWorkflowKeys.PROPOSAL_CREATED) {
        return {
            statusText: `New ${assetCode?.toUpperCase()} proposal created`,
            subTitleText: activityData?.title as string,
        };
    } else {
        return {
            subTitleText: activityData?.title as string,
        };
    }
};

export const NotificationPopoverItem = ({ notification }: { notification: FeedItem }) => {
    const workflowKey = notification?.source.key as KnockWorkflowKeys;
    const activityData = (notification?.activities[0]?.data?.data || notification?.activities[0]?.data) as GenericData;
    const assetCode = activityData?.asset?.code || notification?.activities[0]?.data?.assetCode;
    const imageUrl = `/images/tokens/${assetCode?.toLowerCase()}.svg`;
    const insertedAt = new Date(notification.inserted_at).getTime() / 1000;
    const title = getNotificationTitle(workflowKey);
    const contentProps = getContentProps(workflowKey, activityData, assetCode);

    return (
        <FlexBox justifyContent="between" p={1} key={notification.id} w="full">
            <FlexBox gap="md" alignItems="center">
                <Avatar size={24} src={imageUrl} />
                <Bit.div>
                    <Text size="xs" weight="semibold" color="deselect">
                        {title}
                    </Text>
                    <Bit.div maxW={200}>
                        <NotificationItemContent {...contentProps} />
                    </Bit.div>
                </Bit.div>
            </FlexBox>
            <Text size="xxs" truncate color="deselect">
                <ShortRelativeTime date={insertedAt} /> ago
            </Text>
        </FlexBox>
    );
};
