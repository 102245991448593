import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const CoinsIcon = forwardRef(
    ({ title, titleId, borderRadius, ...props }: IconProps, ref?: LegacyRef<SVGSVGElement>) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width={props.width}
            height={props.height}
            role="img"
            aria-hidden="true"
            className={styles.icon({
                ...props,
                borderRadius,
            })}
            fill="none"
            ref={ref}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill="currentColor">
                <path d="M4.802 4.802A5.335 5.335 0 0 0 6 15.333 5.335 5.335 0 0 0 11.333 10a5.333 5.333 0 0 0-6.531-5.198" />
                <path d="M5.363 3.363a6.667 6.667 0 0 1 7.274 7.274 5.333 5.333 0 1 0-7.274-7.274" />
            </g>
        </svg>
    ),
);
CoinsIcon.displayName = 'CoinsIcon';
export default CoinsIcon;
