import { LogoProps } from './logo.model';
import { logoStyles } from './styles';

type MiniLogoProps = Omit<LogoProps, 'contrast'>;

const MiniLogo = (props: MiniLogoProps) => {
    return (
        <svg
            className={logoStyles(props)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="457.158 238.237 22.638 23.351"
        >
            <path d="M467.614 250.002h-4.045v4.046h4.045v-4.046Z" opacity=".3" />
            <path d="M467.614 254.043h-4.045v4.046h4.045v-4.046Z" opacity=".2" />
            <path d="M467.614 241.911h-4.045v4.046h4.045v-4.046Z" opacity=".8" />
            <path d="M467.614 245.957h-4.045v4.045h4.045v-4.045Z" opacity=".5" />
            <path
                d="M471.04 241.938a2.298 2.298 0 0 0-.39-.027h-3.058v4.042h4.041v-3.854a3.98 3.98 0 0 0-.594-.161Z"
                opacity=".9"
            />
            <path d="M474.386 245.953a4.068 4.068 0 0 0-2.73-3.836v3.853l2.73-.017Z" />
            <path d="M474.386 245.952h-2.73v3.819a4.068 4.068 0 0 0 2.73-3.819Z" opacity=".9" />
            <path d="M467.614 245.952v4.046h2.709c.446.01.89-.06 1.31-.21v-3.818l-4.019-.018Z" opacity=".8" />
            <path d="M471.144 249.998h-3.539v4.045h4.042v-3.962a3.895 3.895 0 0 0-.503-.083Z" opacity=".5" />
            <path d="M471.656 250.081v3.962h3.18a4.059 4.059 0 0 0-3.18-3.962Z" opacity=".8" />
            <path d="M467.614 254.043v4.046h3.53c.172-.016.343-.044.511-.083v-3.963h-4.04Z" opacity=".3" />
            <path d="M471.656 258.006a4.059 4.059 0 0 0 3.18-3.963h-3.18v3.963Z" opacity=".5" />
        </svg>
    );
};

export type { MiniLogoProps };
export { MiniLogo };
