import { tVariants } from '../../../utils/t-variants';

const tvSize = tVariants(
    {
        variants: {
            w: {
                screen: 'w-screen',
                max: 'w-max',
                '1/4': 'w-1/4',
                '1/5': 'w-1/5',
                '2/5': 'w-2/5',
                '3/4': 'w-3/4',
                '1/3': 'w-1/3',
                '2/3': 'w-2/3',
                '3/5': 'w-3/5',
                '4/5': 'w-4/5',
                '1/2': 'w-1/2',
                full: 'w-full',
                10: 'w-[10px]',
                20: 'w-[20px]',
                40: 'w-[40px]',
                50: 'w-[50px]',
                75: 'w-[75px]',
                100: 'w-[100px]',
                150: 'w-[150px]',
                200: 'w-[200px]',
                300: 'w-[300px]',
                400: 'w-[400px]',
                500: 'w-[500px]',
                600: 'w-[600px]',
                700: 'w-[700px]',
                800: 'w-[800px]',
            },
            maxW: {
                screen: 'max-w-screen',
                max: 'max-w-max',
                '1/3': 'max-w-1/3',
                '1/2': 'max-w-1/2',
                full: 'max-w-full',
                100: 'max-w-[100px]',
                125: 'max-w-[125px]',
                150: 'max-w-[150px]',
                175: 'max-w-[175px]',
                200: 'max-w-[200px]',
                225: 'max-w-[225px]',
                250: 'max-w-[250px]',
                275: 'max-w-[275px]',
                300: 'max-w-[300px]',
                400: 'max-w-[400px]',
                500: 'max-w-[500px]',
                600: 'max-w-[600px]',
                700: 'max-w-[700px]',
                800: 'max-w-[800px]',
            },
            minW: {
                screen: 'min-w-screen',
                max: 'min-w-max',
                '1/3': 'min-w-1/3',
                '1/2': 'min-w-1/2',
                full: 'min-w-full',
                300: 'min-w-[300px]',
                400: 'min-w-[400px]',
                500: 'min-w-[500px]',
                600: 'min-w-[600px]',
                700: 'min-w-[700px]',
                800: 'min-w-[800px]',
            },
            h: {
                screen: 'h-screen',
                max: 'h-max',
                '1/3': 'h-1/3',
                '1/2': 'h-1/2',
                full: 'h-full',
                10: 'h-[10px]',
                20: 'h-[20px]',
                40: 'h-[40px]',
                50: 'h-[50px]',
                75: 'h-[75px]',
                100: 'h-[100px]',
                200: 'h-[200px]',
                300: 'h-[300px]',
                400: 'h-[400px]',
                500: 'h-[500px]',
                600: 'h-[600px]',
                700: 'h-[700px]',
                800: 'h-[800px]',
            },
            maxH: {
                screen: 'max-h-screen',
                max: 'max-h-max',
                '1/3': 'max-h-1/3',
                '1/2': 'max-h-1/2',
                full: 'max-h-full',
                100: 'max-h-[100px]',
                150: 'max-h-[150px]',
                200: 'max-h-[200px]',
                300: 'max-h-[300px]',
                400: 'max-h-[400px]',
                500: 'max-h-[500px]',
                600: 'max-h-[600px]',
                700: 'max-h-[700px]',
                800: 'max-h-[800px]',
            },
            minH: {
                screen: 'min-h-screen',
                max: 'min-h-max',
                '1/3': 'min-h-1/3',
                '1/2': 'min-h-1/2',
                full: 'min-h-full',
                300: 'min-h-[300px]',
                400: 'min-h-[400px]',
                500: 'min-h-[500px]',
                600: 'min-h-[600px]',
                700: 'min-h-[700px]',
                800: 'min-h-[800px]',
            },
        },
    },
    { responsiveVariants: true },
);

const size = tvSize.variants;

export { size };
