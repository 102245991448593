import { LogoProps } from './logo.model';
import { logoStyles } from './styles';

const Logo = (props: LogoProps) => {
    return (
        <svg
            className={logoStyles(props)}
            width="170"
            height="20"
            viewBox="0 0 100 10.26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path d="M97.71,10.26h-27.92c-.61,0-1.19-.24-1.62-.67s-.67-1.01-.67-1.62V3.93c0-.61.24-1.19.67-1.62.43-.43,1.01-.67,1.62-.67h27.92c.61,0,1.19.24,1.62.67.43.43.67,1.01.67,1.62v4.04c0,.3-.06.6-.17.88-.12.28-.28.53-.5.74-.21.21-.47.38-.74.5-.28.11-.58.17-.88.17ZM69.79,1.87c-.27,0-.54.05-.79.16-.25.1-.48.26-.67.45-.19.19-.34.42-.45.67-.1.25-.16.52-.16.79v4.04c0,.27.05.54.16.79.1.25.26.48.45.67.19.19.42.34.67.45.25.1.52.16.79.16h27.92c.55,0,1.07-.22,1.46-.6.39-.39.6-.91.6-1.46V3.93c0-.55-.22-1.07-.6-1.46-.39-.39-.91-.6-1.46-.6h-27.92Z" />
                <path d="M72.73,7.82h-.74V3.65h1.67c.93,0,1.51.43,1.51,1.28.02.29-.07.57-.25.79-.18.22-.43.38-.72.42l1.07,1.67h-.85l-1.02-1.61h-.67v1.61ZM73.61,5.56c.53,0,.82-.23.82-.64s-.28-.64-.82-.64h-.88v1.28h.88Z" />
                <path d="M75.46,6.29c0-.89.6-1.58,1.45-1.58s1.35.67,1.35,1.5v.23h-2.14c.05.53.37.84.8.84.16.01.31-.03.44-.12.13-.09.22-.22.27-.37l.6.23c-.1.26-.28.48-.52.64-.23.15-.51.23-.79.22-.86,0-1.47-.64-1.47-1.58ZM76.16,5.92h1.4c0-.09-.01-.17-.04-.25-.03-.08-.08-.15-.14-.21-.06-.06-.14-.1-.22-.13-.08-.03-.17-.04-.25-.03-.18,0-.35.06-.49.17-.14.11-.23.27-.26.45Z" />
                <path d="M79.02,6.81c.09.15.22.27.38.36.15.09.33.13.5.13.3,0,.57-.1.57-.38s-.27-.29-.74-.39c-.47-.1-1.03-.22-1.03-.87,0-.56.49-.96,1.19-.96.24,0,.47.04.69.14.21.1.4.25.55.44l-.47.42c-.09-.13-.2-.24-.34-.32-.14-.08-.3-.11-.46-.11-.28,0-.47.13-.47.33s.22.27.6.34c.52.11,1.17.22,1.17.92,0,.62-.56,1.01-1.25,1.01-.27.01-.54-.04-.78-.15-.24-.11-.46-.28-.62-.49l.52-.42Z" />
                <path d="M81.46,6.29c0-.89.6-1.58,1.45-1.58s1.35.67,1.35,1.5v.23h-2.14c.05.53.37.84.8.84.16.01.31-.03.44-.12.13-.09.22-.22.27-.37l.6.23c-.1.26-.28.48-.52.64-.23.15-.51.23-.79.22-.86,0-1.47-.64-1.47-1.58ZM82.17,5.92h1.4c0-.09-.01-.17-.04-.25-.03-.08-.08-.15-.14-.21-.06-.06-.14-.1-.22-.13-.08-.03-.17-.04-.25-.03-.18,0-.35.06-.49.17-.14.11-.23.27-.26.45Z" />
                <path d="M84.57,6.97c0-.58.44-.84,1.02-.96l.84-.17v-.05c0-.29-.15-.47-.53-.47-.14-.01-.27.02-.38.11-.11.08-.18.2-.2.34l-.66-.15c.08-.27.25-.51.48-.67.23-.16.51-.24.79-.23.73,0,1.17.35,1.17,1.05v1.32c0,.18.07.23.27.21v.53c-.51.06-.77-.04-.88-.29-.12.12-.26.21-.42.27-.16.06-.32.08-.49.08-.57.01-1.02-.35-1.02-.9ZM86.44,6.37l-.66.14c-.3.06-.52.15-.52.43s.17.37.44.37c.37,0,.73-.19.73-.57v-.37Z" />
                <path d="M89.58,5.46c-.08-.01-.17-.02-.25-.02-.1,0-.21,0-.31.04-.1.04-.19.09-.26.17-.07.07-.13.16-.17.26-.04.1-.05.2-.05.3v1.6h-.7v-3.04h.7v.44c.08-.15.2-.27.35-.36.15-.08.32-.12.49-.11.07,0,.13,0,.2,0v.72Z" />
                <path d="M91.31,7.28c.16,0,.32-.06.45-.16.13-.11.21-.25.23-.42l.6.3c-.09.27-.26.5-.49.65-.23.16-.51.24-.79.23-.87,0-1.5-.64-1.5-1.58s.63-1.58,1.5-1.58c.28-.01.55.06.78.22s.4.38.49.64l-.59.32c-.03-.16-.11-.31-.24-.41-.13-.11-.28-.16-.45-.16-.48,0-.8.37-.8.98s.33.98.8.98Z" />
                <path d="M93,3.65h.7v1.45c.1-.12.23-.22.38-.29.15-.07.3-.1.47-.1.64,0,1.02.44,1.02,1.1v2.01h-.7v-1.8c0-.38-.15-.65-.53-.65-.09,0-.17.02-.25.05-.08.03-.15.08-.21.15-.06.06-.1.14-.13.22-.03.08-.04.17-.04.25v1.79h-.7V3.65Z" />
            </g>
            <g>
                <path d="M3.56,0c1.93,0,3.02,1.13,3.02,2.67,0,1.02-.61,1.84-1.44,2.12.96.24,1.75,1.12,1.75,2.35,0,1.61-1.2,2.76-3.06,2.76H0V0h3.56ZM3.27,4.09c.88,0,1.41-.49,1.41-1.24s-.49-1.23-1.42-1.23h-1.35v2.47h1.37ZM3.46,8.29c.94,0,1.49-.49,1.49-1.28s-.5-1.33-1.47-1.33h-1.59v2.61h1.56Z" />
                <g>
                    <path d="M7.36,9.9V0h1.86v9.9h-1.86Z" />
                    <path d="M16.77,6.47c0,2.11-1.55,3.64-3.6,3.64s-3.6-1.54-3.6-3.64,1.55-3.64,3.6-3.64,3.6,1.52,3.6,3.64ZM14.91,6.47c0-1.3-.84-1.95-1.75-1.95s-1.75.66-1.75,1.95.84,1.95,1.75,1.95,1.75-.66,1.75-1.95Z" />
                    <path d="M18.78,6.47c0,1.24.81,1.94,1.76,1.94s1.42-.63,1.58-1.16l1.63.54c-.31,1.14-1.38,2.32-3.21,2.32-2.02,0-3.62-1.54-3.62-3.64s1.56-3.64,3.56-3.64c1.87,0,2.93,1.16,3.23,2.32l-1.66.56c-.17-.57-.6-1.16-1.52-1.16s-1.75.68-1.75,1.93Z" />
                    <path d="M27.91,5.92l2.81,3.98h-2.28l-1.82-2.62-.77.81v1.82h-1.86V0h1.86v5.59l2.37-2.56h2.43l-2.75,2.89Z" />
                    <path d="M36.26,3.03l1.48,4.43,1.27-4.43h1.84l-2.15,6.87h-1.86l-1.61-4.69-1.58,4.69h-1.88l-2.19-6.87h1.95l1.27,4.41,1.49-4.41h1.95Z" />
                    <path d="M47.66,6.47c0,2.11-1.55,3.64-3.6,3.64s-3.6-1.54-3.6-3.64,1.55-3.64,3.6-3.64,3.6,1.52,3.6,3.64ZM45.8,6.47c0-1.3-.84-1.95-1.75-1.95s-1.75.66-1.75,1.95.84,1.95,1.75,1.95,1.75-.66,1.75-1.95Z" />
                    <path d="M52.2,4.87c-.21-.04-.39-.06-.56-.06-.95,0-1.77.46-1.77,1.94v3.14h-1.86V3.03h1.8v1.02c.42-.91,1.37-1.08,1.95-1.08.15,0,.29.01.43.03v1.87Z" />
                    <path d="M56.4,5.92l2.81,3.98h-2.28l-1.82-2.62-.77.81v1.82h-1.86V0h1.86v5.59l2.37-2.56h2.43l-2.75,2.89Z" />
                    <path d="M60.3,7.64c.04.54.45,1.05,1.26,1.05.61,0,.91-.32.91-.68,0-.31-.21-.56-.74-.67l-.91-.21c-1.33-.29-1.93-1.09-1.93-2.05,0-1.23,1.09-2.25,2.57-2.25,1.95,0,2.61,1.24,2.69,1.98l-1.55.35c-.06-.4-.35-.92-1.13-.92-.49,0-.88.29-.88.68,0,.34.25.54.63.61l.98.21c1.35.28,2.04,1.1,2.04,2.11,0,1.12-.87,2.26-2.67,2.26-2.07,0-2.78-1.34-2.86-2.12l1.59-.35Z" />
                </g>
            </g>
        </svg>
    );
};

export { Logo };
