import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash-es';

export const usePrevious = <T>(value: T) => {
    const prevRef = useRef(value);

    useEffect(() => {
        if (!isEqual(value, prevRef.current)) {
            prevRef.current = value;
        }
    }, [value]);
    return prevRef.current;
};
