import type { Remote } from 'comlink';

import { createSafeContext } from '@blockworks/ui/utils';

import { VisualizationWorkerApi } from './viz-worker';

const [VisualizationWorkerContextProvider, useWorker] = createSafeContext<Remote<VisualizationWorkerApi>>({
    strict: false,
});

export { useWorker, VisualizationWorkerContextProvider };
