import { SessionUser } from 'next-auth';
import { URLPattern } from 'urlpattern-polyfill';

import { AuthRole } from './models';
import { getPermissionLevel } from './utils';

type AccessControlConfig = {
    allowedPaths: '*' | string[];
    skipRedirect?: boolean;
} & (
    | { destination?: never; externalDestination?: `https://${string}` | `http://${string}` }
    | { destination?: string; externalDestination?: never }
);

type AccessByRole = Record<AuthRole, AccessControlConfig>;

const permissionLevels = [
    AuthRole.PUBLIC,
    AuthRole.UNAUTHENTICATED,
    AuthRole.UNSUBSCRIBED,
    AuthRole.PENDING_SUBSCRIPTION,
    AuthRole.SUBSCRIBED,
];

const isMatchingPath = (paths: '*' | string[], pathname: string) => {
    if (paths === '*') return true;
    return paths.some(path => path === pathname || new URLPattern({ pathname: path }).test({ pathname }));
};

/**
 * An initilizer fn that accepts a config and then returns a function that gets the
 * appropriate route redirect config based on the user's role and the requested path
 **/
const makeAuthRedirectHelper = (accessByRole: AccessByRole) => {
    return (session: SessionUser | null | undefined, pathname: string) => {
        const userRole = getPermissionLevel(session);
        const userAccess = accessByRole[userRole];

        for (const level of permissionLevels) {
            const roleAccess = accessByRole[level];
            if (!isMatchingPath(roleAccess.allowedPaths, pathname)) continue;
            if (roleAccess.skipRedirect) break;
            if (userRole === level) break;

            const params = {
                callback: pathname,
            };

            return {
                destination: userAccess.destination,
                externalDestination: userAccess.externalDestination,
                params: userRole === AuthRole.UNAUTHENTICATED ? params : undefined,
            };
        }
        return { destination: null, externalDestination: null };
    };
};

type AuthRedirectConfig = ReturnType<ReturnType<typeof makeAuthRedirectHelper>>;

export type { AccessByRole, AuthRedirectConfig };
export { makeAuthRedirectHelper };
